{
  "accounting.account_balance": "Konto status",
  "accounting.accounting_dashboard": "Regnskaps dashboard",
  "accounting.dashboard_preview_description": "Dette er en forhåndsvisning av et dashbord. Vi importerer data direkte fra Catacloud og viser deg den viktigste informasjonen, direkte i Hoopit!",
  "accounting.expense": "Utgift",
  "accounting.imported_from_catacloud": "Importert fra Catacloud",
  "accounting.income": "Inntekt",
  "accounting.open_invoices": "Utestående fakturaer",
  "accounting.page_description_1": "Visste du at du kan integrere Hoopit med regnskapssystemet Catacloud?",
  "accounting.page_description_2": "Sammen med ECIT og Catacloud tilbyr vi automatisk bokføring av alle inntekter og utlegg, og full oversikt over regnskapsdata direkte i Hoopit.",
  "accounting.request_more_information": "Be om mer informasjon",
  "accounting.result": "Resultat",
  "accounting.results": "Resultater",
  "accounting.this_year": "dette året",
  "accounting.unpaid_bills": "Ubetalte krav",
  "action.accept": "Godta",
  "action.activate": "Aktiver",
  "action.add": "Legg til",
  "action.add_note": "Legg til notat",
  "action.apply": "Bruk",
  "action.apply_changes": "Lagre endringer",
  "action.approve": "Godkjenn",
  "action.back": "Tilbake",
  "action.back_to_login": "Tilbake til innlogging",
  "action.cancel": "Avbryt",
  "action.change": "Endre",
  "action.choose_all": "Velg alle",
  "action.clear": "Fjern",
  "action.click_here": "Klikk her",
  "action.click_to_view": "Trykk for å se",
  "action.close": "Lukk",
  "action.complete": "Fullfør",
  "action.confirm": "Bekreft",
  "action.continue": "Fortsett",
  "action.copy": "Kopier",
  "action.copy_link": "Kopier lenke",
  "action.create": "Opprett",
  "action.delete": "Slett",
  "action.delete_all": "Slette alle",
  "action.download": "Last ned",
  "action.edit": "Rediger",
  "action.embed_with_iframe": "Bygg inn med iframe",
  "action.enroll": "",
  "action.event_cancel": "Avlys",
  "action.export": "Eksporter",
  "action.finalize": "Ferdigstill",
  "action.ical_ics_link": "iCAL/iCS-lenke",
  "action.invite": "Inviter",
  "action.join_waiting_line": "Sett på venteliste",
  "action.load_more": "Last inn flere",
  "action.login": "Logg inn",
  "action.logout": "Logg ut",
  "action.move_to_group": "Flytt brukere til en gruppe",
  "action.next": "Neste",
  "action.no_go_back": "Nei, gå tilbake",
  "action.ok": "Ok",
  "action.publish": "Publiser",
  "action.register": "Registrer",
  "action.reject": "Avvis",
  "action.remind": "",
  "action.remove": "Fjern",
  "action.reopen": "Gjenåpne",
  "action.resend_code": "Send kode på nytt",
  "action.reset_filters": "Nullstill filtre",
  "action.save": "Lagre",
  "action.save_changes": "Lagre endringer",
  "action.search": "Søk",
  "action.see_more": "Se mer",
  "action.send": "Send",
  "action.send_invitation_immediately": "Send invitasjon umiddelbart",
  "action.send_request": "",
  "action.share_with_link": "Del med lenke",
  "action.show_users": "",
  "action.skip": "Hopp over",
  "action.step": "Steg {{ current }} av {{ total }}",
  "action.try_again": "Prøv igjen",
  "action.update": "Oppdater",
  "action.verify": "Verifiser",
  "action.view_profile": "Se profil",
  "action.view_voucher": "Se bilag",
  "action.yes_add": "Ja, legg til",
  "action.yes_apply": "Ja, endre",
  "action.yes_approve": "Ja, godkjenn",
  "action.yes_cancel": "Ja, avlys",
  "action.yes_decline": "Ja, avslå",
  "action.yes_delete": "Ja, slett",
  "action.yes_invite": "Ja, inviter",
  "action.yes_mark_as_paid": "Ja, marker som utbetalt",
  "action.yes_move": "Ja, flytt",
  "action.yes_publish": "Ja, publiser",
  "action.yes_put_on_hold": "Ja, frys betaling",
  "action.yes_reassign": "Ja, endre",
  "action.yes_reject": "Ja, avvis",
  "action.yes_remove": "Ja, fjern",
  "action.yes_republish": "Ja, publiser på nytt",
  "activate.administrate_team_info": "I dashbordet kan du som leder administrere ditt lag.",
  "activate.download_app_info": "Dersom du kun skal følge med på laget, kan du laste ned appen eller bruke webappen vår.",
  "activate.login_info": "Hvis du er leder og skal administrere laget ditt, må du logge inn med en datamaskin, eller iPad.",
  "activate.new_username": "Dette blir ditt brukernavn.",
  "activate.register_info": "For å ta i bruk Hoopit må du registrere deg med mobilnummer og passord.",
  "activate.register_successful": "Takk for at du registrerte deg! \uD83C\uDF89",
  "activate.register_user": "Registrer bruker",
  "add_user.add_admin_confirmation": "Vil du legge til <b>{{ name }}</b> som <b>{{ title }}</b> for <b>{{ group }}</b>?",
  "add_user.add_administrator": "Legg til leder",
  "add_user.add_by_email": "Legg til med e-post",
  "add_user.add_by_name": "Legge til med navn",
  "add_user.add_by_phone": "Legge til med nummer",
  "add_user.add_user_confirmation": "Vil du legge til <b>{{ name }}</b> for <b>{{ group }}</b>?",
  "add_user.back_to_search": "Tilbake til søking",
  "add_user.confirm_admin": "Bekrefte leder",
  "add_user.confirm_player": "Bekreft utøver",
  "add_user.enter_email": "Skriv inn e-post",
  "add_user.enter_name": "Skriv navn",
  "add_user.invite_manually": "Inviter de manuelt",
  "add_user.invite_new_admin": "Inviter ny leder",
  "add_user.invite_new_player": "Inviter utøver",
  "add_user.new_user_form_description": "Inviter bruker med nummer <b>{{ phoneCode }}{{ phoneNumber }}</b> som <b>{{ title }}</b>",
  "add_user.no_players_found": "Vi fant ingen utøvere med dette navnet.",
  "add_user.no_players_found_description": "Vennligst prøv et annet navn eller send en invitasjon manuelt.",
  "add_user.no_user_found_by_name": "Fant ingen bruker ved dette navnet",
  "add_user.no_user_found_by_name_description_1": "Vennligs prøv et annet navn, eller",
  "add_user.no_user_found_by_name_description_2": "legge til med nummer",
  "add_user.no_user_found_by_phone": "Fant ingen bruker ved dette nummeret",
  "add_user.no_user_found_by_phone_description": "Vennligst prøv et annet nummer, eller gå videre for å inviter bruker ved dette nummeret",
  "add_user.register_admin": "Registrer leder",
  "add_user.register_player": "Registrer utøver",
  "add_user.registration_link": "Registreringslink",
  "add_user.search_admin_by_name_description": "Legge til leder med navn eller nummer. Velg \"Legge til med nummer\" hvis de ikke er i systemet enda.",
  "add_user.search_by_name": "Søk med navn",
  "add_user.search_in_organization": "Søk i organisasjon",
  "add_user.send_invitation": "Send invitasjon",
  "add_user.send_registration_link_to_guardians": "Send registreringslenken til foresatte du vil legge til i <b>{{ group }}</b>.",
  "add_user.send_registration_link_to_players": "Send registreringslenken til utøvere du vil legge til i <b>{{ group }}</b> eller inviter de manuelt.",
  "app_badges.google_play": "Tilgjengelig på Google Play",
  "app_badges.go_to_webapp": "Gå til webapp",
  "attendance_statistics.activities": "aktiviteter",
  "attendance_statistics.end": "Slutt",
  "attendance_statistics.export_statistics": "Eksporter oppmøtestatistikk",
  "attendance_statistics.no_activities": "Ingen aktiviteter for valgt periode",
  "attendance_statistics.no_activities_selected": "Ingen aktiviteter er valgt",
  "attendance_statistics.specify_period": "Velg periode",
  "attendance_statistics.start": "Start",
  "bank_account.account_name": "Kontonavn",
  "bank_account.account_number": "Kontonummer",
  "bank_account.account_number_validation_message": "Kontonummer må være 11 siffer",
  "bank_account.add_new_account": "Legg til ny konto",
  "chat.add_members": "Legg til medlemmer",
  "chat.add_moderator_role": "Legg til som chat admin",
  "chat.already_in_the_chat": "Allerede i chatten",
  "chat.chat_members": "Chatmedlemmer",
  "chat.create_new_chat_with": "Opprett en chat med",
  "chat.divider_today": "I dag",
  "chat.divider_yesterday": "I går",
  "chat.image": "Bilde",
  "chat.member": "Deltaker",
  "chat.member_added_snackbar": "{{name}} er lagt til i chatten",
  "chat.member_removed_snackbar": "{{name}} er fjernet fra chatten",
  "chat.members": "Deltakere",
  "chat.members_added_snackbar": "{{names}} er lagt til i chatten",
  "chat.moderator": "Moderator",
  "chat.new_chat": "Ny chat ",
  "chat.no_chats": "Ingen samtaler ennå",
  "chat.not_available_for_chat": "Ikke tilgjengelig for chat",
  "chat.no_users_found": "Ingen bruker med dette navnet funnet",
  "chat.no_users_found_hint": "Vennligst prøv et annet navn",
  "chat.online": "Aktiv",
  "chat.owner": "Eier",
  "chat.remove_member": "Fjern medlem",
  "chat.remove_member_description": "Er du sikker på at du vil fjerne <b>{{ name }}</b> fra chatten?",
  "chat.remove_moderator_role": "Fjern som chat admin",
  "chat.search_by_member_name": "Søk på medlemsnavn",
  "chat.selected_users": "Valgte brukere",
  "chat.select_people_you_want_in_your_chat": "Velg de du vil starte en chat med",
  "chat.start_chat": "Start chat",
  "chat.start_conversations": "Start samtaler via Hoopit-appen.",
  "chat.unpin": "Løsne",
  "chat.who_do_you_want_to_add": "Hvem vil du legge til?",
  "chat.who_do_you_want_to_chat_with": "Hvem vil du chatte med?",
  "confirmation.add_child_to_group": "Ønsker du å legge {{ childName }} til {{ groupName }}?",
  "confirmation.add_child_to_waiting_list": "Gruppen er full. Vil du at {{ childName }} skal bli satt på venteliste?",
  "confirmation.checking_requirements": "Verifiserer informasjon",
  "confirmation.delete_user_payment": "Er du sikker på at du ønsker a slette denne betalingen?",
  "confirmation.done": "Ferdig",
  "confirmation.finished": "Ferdig",
  "confirmation.no": "Nei",
  "confirmation.save": "Lagre",
  "confirmation.save_and_notify": "Lagre og varsle",
  "confirmation.sent": "Sendt",
  "confirmation.skip_adding_child": "Ikke legge til noen barn?",
  "confirmation.yes": "Ja",
  "dashboard.accounting_label": "Regnskap",
  "dashboard.bank_accounts": "Bankkontoer",
  "dashboard.calendar_label": "Kalender",
  "dashboard.communication_label": "Kommunikasjon",
  "dashboard.conflicting_events": "Bookingkonflikter",
  "dashboard.contact_support": "Kontakt kundestøtte",
  "dashboard.dashboard": "Oversikt",
  "dashboard.dugnads": "Dugnader",
  "dashboard.economy_label": "Økonomi",
  "dashboard.events_label": "Aktiviteter",
  "dashboard.expense_claims": "Utlegg",
  "dashboard.export_members": "Eksporter medlemmer",
  "dashboard.fotballdata_label": "Fotballdata",
  "dashboard.group_settings_label": "Innstillinger",
  "dashboard.groups_label": "Grupper",
  "dashboard.help_center": "Hjelpesenter",
  "dashboard.help_label": "Hjelp",
  "dashboard.individual_payments": "Betalingsforespørsler",
  "dashboard.invoices": "Fakturaer",
  "dashboard.language": "Språk",
  "dashboard.locations_label": "Områder",
  "dashboard.logout_label": "Logg ut",
  "dashboard.membership_dashboard": "Medlemsdashbord",
  "dashboard.membership_label": "Medlemskap",
  "dashboard.my_profile": "Min profil",
  "dashboard.new": "Ny",
  "dashboard.nif_connection": "NIF kobling",
  "dashboard.nif_venues": "Områder fra NIF",
  "dashboard.organization": "Organisasjon",
  "dashboard.organization_label": "Organisasjon",
  "dashboard.payment_provider": "Betalingsleverandør",
  "dashboard.payments": "Betalinger",
  "dashboard.payouts": "Utbetalinger",
  "dashboard.productboard_label": "Produktportal",
  "dashboard.sales_dugnads": "Salgsdugnader",
  "dashboard.sms_configuration": "SMS innstillinger",
  "dashboard.stripe_not_connected_warning": "Det ser ut som {{ organization }} ikke har aktiverer integrasjonen med betalingsleverandøren Stripe.",
  "dashboard.switch_to_paid_mf_view": "Se betalende medlemmer",
  "dashboard.team_account": "Lagskasse",
  "dashboard.users_label": "Brukere",
  "dashboard.waiting_list_label": "Venteliste",
  "delete_member_requests_list_decline_description": "Utøvere vil ikke bli lagt til i gruppen, men du vil kunne se avslåtte medlemmer i \"Medlemsforepsørsler\".",
  "denied.delete_paid_user_payment": "Du kan ikke slette en betaling som er betalt",
  "denied.edit_paid_user_payment": "Du kan ikke endre en betaling som er betalt",
  "dugnad.payment_end_date": "Betalingens sluttdato",
  "dugnad.payment_end_date_field_hint": "Spillere som blir medlem etter dette vil ikke bli inkludert i dugnaden.",
  "dugnad.payment_end_date_min_error": "Sluttdato for betalingen kan ikke være tidligere enn publiseringsdato",
  "economy.above_age": "Fra {{ age }} år",
  "economy.account": "Konto",
  "economy.account_deleted": "Bankkonto er slettet.",
  "economy.account_length_hint": "Kontonummer må være {{ number }} siffer",
  "economy.add_1_product": "Legg til 1 produkt",
  "economy.add_age_group": "Legg til aldersgruppe",
  "economy.add_age_restriction": "Legg til aldersbegrensning",
  "economy.add_at_least_one_manager": "Legg til minst 1 dugnadsansvarlig",
  "economy.add_at_least_one_product": "Legg til minst 1 produkt for å fortsette",
  "economy.add_buy_out": "Legg til muligheten for frikjøp",
  "economy.add_buy_out_description": "La medlemmene få mulighet til å kjøpe seg fri fra å delta i dugnaden.",
  "economy.add_buy_out_price": "Legg til pris for frikjøp",
  "economy.add_dugnad_manager": "Legg til en dugnadsansvarlig",
  "economy.added": "Lagt til",
  "economy.add_family_discount": "Legg til familierabatt",
  "economy.add_family_discount_description": "Sett en pris per familie for å gjøre det mulig for familier å få rabatt. Sett en minimumspris per medlem for å begrense rabatten.",
  "economy.add_family_discount_description_2": "Legg til en rabatt for familier basert på antall familiemedlemmer.",
  "economy.add_group_restriction": "Legg til gruppebegrensning",
  "economy.add_n_products": "Legg til {{number}} produkter",
  "economy.add_price_category": "Legg til priskategori",
  "economy.add_product": "Legg til produkt",
  "economy.add_role_restriction": "Legg til rollebegrensing",
  "economy.add_sibling_discount": "Legg til søskenmoderasjon",
  "economy.add_sibling_discount_description": "Sett rabatt basert på antall søsken eller sett en øvre kostnadsgrense per familie ved å sette rabatten til 100 % etter et visst antall søsken.",
  "economy.age_coverage_modal_text_1": "Du har ikke oppgitt en avgift for aldersgruppene:",
  "economy.age_coverage_modal_text_2": "Sjekk at kategoriene dekker alle brukere.",
  "economy.age_coverage_modal_text_3": "De som ikke tilhører noen kategorier vil ikke motta betaling.",
  "economy.age_coverage_modal_text_4": "Vil du gå tilbake og endre?",
  "economy.age_group": "Aldersgruppe",
  "economy.age_groups": "Aldersgrupper",
  "economy.age_restriction": "Aldersgrense",
  "economy.age_restriction_description": "Utøvere som har fylt {{ age }} vil ikke motta rabatt og heller ikke telle på antall søsken i sin familie.",
  "economy.age_restriction_description_2": "Utøvere som har fylt denne alderen vil ikke motta rabatt og heller ikke telle på antall søsken i sin familie.",
  "economy.age_restriction_description_3": "Utøvere som er {{ age }} år og eldre vil ikke få rabatt og telles ikke i det totale antallet familiemedlemmer.",
  "economy.age_restriction_description_4": "Utøvere som har nådd denne alderen vil ikke få rabatt og telles ikke i det totale antallet familiemedlemmer.",
  "economy.all_ages": "Alle aldre",
  "economy.all_fees": "Alle treningsavgifter",
  "economy.allow_users_to_order_more_items": "La spillere få velge å bestille flere enheter",
  "economy.allow_users_to_order_more_items_description": "Gi deltagere mulighet til å øke antall enheter de ønsker å bestille.",
  "economy.apply_changes_to_sub_groups": "Gjør også endringene for undergrupper",
  "economy.apply_changes_to_sub_groups_description": "Når aktivert, vil vi oppdatere prisen for alle undergrupper under denne gruppen.",
  "economy.at_least_n_items_in_total": "Minimum {{ number }} enheter",
  "economy.auto_buy_out_description": "Velg hva som skal skje dersom utøvere ikke bestiller innen fristen. Du kan velge at de skal forbli “ubekreftet”, eller automatisk endre bestillingen til “frikjøp”.",
  "economy.auto_buy_out_summary_1": "Utøvere som ikke bestiller innen fristen vil forbli “ubekreftet”.",
  "economy.auto_buy_out_summary_2": "Utøvere som ikke bestiller innen fristen vil automatisk endres til “frikjøp”.",
  "economy.bank_account": "Bankkonto",
  "economy.bank_account_account_number": "Bankkontonummer",
  "economy.bank_account_account_status": "Kontostatus",
  "economy.bank_account_account_status_description": "Når en konto er aktiv kan du velge den når du oppretter nye betalinger eller for tilbakebetaling av krav.",
  "economy.bank_account_add": "Legg til bankkonto",
  "economy.bank_account_additional_info_required_short": "Mer informasjon påkrevd",
  "economy.bank_account_bank": "Bank",
  "economy.bank_account_bank_account_number": "Bankkontonummer",
  "economy.bank_account_cannot_be_deleted": "Bankkontoen kan ikke slettes.",
  "economy.bank_account_cannot_create_tooltip": "Du kan ikke legge til en bankkonto i {{ groupName }}. Velg en annen gruppe.",
  "economy.bank_account_cannot_deactivate": "Bankkontoen kan ikke slettes fordi det er den siste kontoen tilgjengelig for tilbakebetaling av krav og grupper knyttet til kontoen har ubehandlede krav.",
  "economy.bank_account_cannot_delete_description": "Bankkontoen kan ikke slettet på grunn av:",
  "economy.bank_account_cannot_delete_reason_1": "en betaling ble opprettet med denne kontoen;",
  "economy.bank_account_cannot_delete_reason_2": "kontoen er brukt for tilbakebetaling av krav;",
  "economy.bank_account_cannot_delete_reason_3": "det er den siste kontoen tilgjengelig for tilbakebetaling av krav og grupper knyttet til kontoen har krav sendt mot den;",
  "economy.bank_account_complete_setup": "Fullfør oppsett",
  "economy.bank_account_complete_setup_description_1": "Skriv inn hele kontonummeret for {{ accountName }} fra {{ bankName }}, der de 4 siste sifferene er \"{{ accountLast4 }}\".",
  "economy.bank_account_complete_setup_description_1.5": "Skriv inn hele kontonummeret for {{ accountName }}, der de 4 siste sifferene er \"{{ accountLast4 }}\".",
  "economy.bank_account_complete_setup_description_2": "Dersom det er andre kontoer med det samme kontonummeret vil vi fullføre oppsettet for de automatisk.",
  "economy.bank_account_complete_setup_validation_error": "De 4 siste sifferne stenner ikke. Prøv igjen.",
  "economy.bank_account_delete": "Slett bankkonto?",
  "economy.bank_account_delete_description": "En bankkonto kan kun slettes dersom det ikke er gjort noen innbetalinger til den.",
  "economy.bank_account_description": "Det som blir innbetalt etter endringen av bankkonto vil komme til den nye kontoen. Det som allerede er betalt vil gå til den nåværende kontoen.",
  "economy.bank_account_edit_name": "Endre kontonavn ",
  "economy.bank_account_edit_name_description": "Navnet for denne bankkontoen vil kun endres for {{ groupName }}.",
  "economy.bank_account_empty_state": "Ingen bankkontoer lagt til",
  "economy.bank_account_feedback_success": "Ditt forslag er registrert! Vi tar kontakt dersom vi har noen spørsmål.",
  "economy.bank_account_full_number_required": "Fullfør oppsettet av kontonummer",
  "economy.bank_account_full_number_required_description_1": "Systemet forberedes til å håndtere utlegg og tilbakebetalinger av krav til medlemmer.",
  "economy.bank_account_full_number_required_description_2": "Vennligst oppgi fullt kontonummer for kontoen knyttet til {{ groupName }}",
  "economy.bank_account_group": "Gruppe",
  "economy.bank_account_group_type": "Gruppetype",
  "economy.bank_account_information": "Kontoinformasjon",
  "economy.bank_account_name": "Kontonavn",
  "economy.bank_account_name_was_updated": "Kontonavnet ble oppdatert.",
  "economy.bank_account_new": "Ny bankkonto",
  "economy.bank_account_no_accounts_found": "Ingen kontoer funnet",
  "economy.bank_account_no_accounts_found_description": "Prøv å søk etter et annet navn",
  "economy.bank_account_number": "Kontonummer",
  "economy.bank_account_number_validation_message": "Må være 11 siffer",
  "economy.bank_account_search_by_account_name": "Søk etter kontonavn",
  "economy.bank_account_status_hint": "Om kontoen er inaktiv og den har aktive eksisterende betalinger koblet mot seg vil pengene fortsatt utbetales til kontoen. Du vil kunne velge den for nye betalinger.",
  "economy.bank_account_try_searching_by_another_name": "",
  "economy.bank_account_was_added_to_group": "Ny bankkonto lagt til for {{ groupName }}.",
  "economy.bank_account_was_added_to_group_and_updated": "Ny bankkonto lagt til for {{ groupName }} og oppdatert for {{ amountOfGroups }} andre grupper.",
  "economy.bank_account_was_deleted": "Kontoen ble slettet.",
  "economy.bank_account_was_set_up_to_active": "Kontoen ble satt til aktiv.",
  "economy.bank_account_was_set_up_to_inactive": "Kontoen ble satt til inaktiv.",
  "economy.bank_account_was_updated_for_group": "Kontoen ble oppdatert for {{ groupName }}.",
  "economy.bank_account_was_updated_for_multiple_groups": "Kontoen ble oppdatert for {{ groupName }} og {{ amountOfGroups }} andre grupper.",
  "economy.based_on_the_number_of_items_per_player": "basert på antall enheter per utøver",
  "economy.basic_price": "Grunnpris",
  "economy.bought_out": "Frikjøpt",
  "economy.bought_out_items": "Antall enheter frikjøpt",
  "economy.buy_out": "Frikjøp",
  "economy.buy_out_net_amount_per_item": "Nettobeløp for frikjøp per enhet",
  "economy.buy_out_option": "Frikjøp",
  "economy.buy_out_option_description": "Kalkulasjonen av prisen for frikjøp er basert på antall enheter per utøver. Hvis utøveren har rett på familierabatt vil de fortsatt kunne velge frikjøp, med rabattert pris.",
  "economy.buy_out_price": "Pris for frikjøp",
  "economy.buy_out_price_per_item": "Pris for frikjøp per enhet",
  "economy.by_account": "Etter konto",
  "economy.by_confirmation_due_date": "Etter bestillingsfrist",
  "economy.by_confirmation_status": "Etter bekreftelsestatus",
  "economy.by_due_date": "Forfallsdato",
  "economy.by_generation_date": "Etter fakturadato",
  "economy.by_group": "Gruppe",
  "economy.by_last_reminder_date": "Siste purringsdato",
  "economy.by_net_amount": "Etter nettobeløp",
  "economy.by_order_status": "Etter bestillingsstatus",
  "economy.by_payment_date": "Betalingsdato",
  "economy.by_payment_due_date": "Etter betalingsfrist",
  "economy.by_payment_status": "Betalingsstatus",
  "economy.by_payment_type": "Betalingstype",
  "economy.by_publish_date": "Publiseringsdato",
  "economy.cannot_delete_bank_account": "Bankkonto kan ikke slettes, kan bare slette bankkonto som ikke er betalt inn til",
  "economy.change": "Endre",
  "economy.cheapest_fee": "Den billigste treningsavgiften",
  "economy.coming_soon": "Kommer snart!",
  "economy.confirmation_due_date": "Bestillingsfrist",
  "economy.confirmation_reminder": "Påminnelse om bestilling",
  "economy.cost": "Innkjøpspris",
  "economy.create_age_group": "Lag aldersgruppe",
  "economy.created_for": "Opprettet for",
  "economy.created_for_1_group": "Opprettet for 1 gruppe",
  "economy.created_for_n_groups": "Opprettet for {{ number }} grupper",
  "economy.create_dugnad": "Opprett dugnad",
  "economy.create_dugnad_for": "Opprett dugnad for",
  "economy.create_membership_fee_description": "Opprett en betaling for medlemskontingenten. Dette blir en betaling for alle utøvere, trenere, lagledere, styrerepresentanter og eventuelle støttemedlemmer i organisasjonen.",
  "economy.create_new_product": "Legg til nytt produkt",
  "economy.create_order_for_1_group": "Opprett spørreundersøkelse for 1 gruppe?",
  "economy.create_order_for_n_groups": "Opprett spørreundersøkelse for {{ number }} grupper?",
  "economy.create_payment": "Opprett betaling",
  "economy.create_payment_for_1_group": "Opprett betaling for 1 gruppe?",
  "economy.create_payment_for_n_groups": "Opprett betaling for {{ number }} grupper?",
  "economy.create_price_category": "Opprett priskategori",
  "economy.create_product": "Opprett produkt",
  "economy.create_survey_for_1_group": "Opprett spørreundersøkelse for 1 gruppe?",
  "economy.create_survey_for_n_groups": "Opprett spørreundersøkelse for {{ number }} grupper?",
  "economy.create_training_fee": "Opprett treningsavgift",
  "economy.create_training_fee_description": "Opprett en betaling for trenings-/aktivitetsavgiften for en idrett. Her kan du sette en pris for hvert enkelt lag i idretten. Denne blir sendt ut til alle utøvere i idretten.",
  "economy.custom_pick_up_location": "Egendefinert hentested",
  "economy.declined": "Avslått",
  "economy.delete_bank_account": "Slett {{ account_name }}",
  "economy.delete_bank_account_description": "Bankkonto kan kun slettes dersom det ikke er gjort noen betaling til den.",
  "economy.department_add_bank_account": "Legg til en bankkonto i avdelingens innstillinger før du oppretter treningsavgiften.",
  "economy.department_add_team": "Legg til minst et lag i avdelingen før du oppretter treningsavgiftenavgiften.",
  "economy.description": "Beskrivelse",
  "economy.details_of_this_dugnad": "Detaljer om denne dugnaden",
  "economy.different_delivery_warning": "Leveringsdatoer kan være ulike",
  "economy.different_delivery_warning_description": "Du har valgt produkter fra ulike leverandører. Vær oppmerksom på at dette vil resultere i flere forsendelser som kan ha ulik leveringsdato.",
  "economy.discount": "Rabatt",
  "economy.discount_example_description": "Eksemplet er basert på {{ items }} enheter per utøver med en pris på kr {{ price }} per enhet",
  "economy.discount_example_family": "Familien Askeladden Felgen",
  "economy.discount_for_other_siblings": "Rabatt for øvrige søsken",
  "economy.discount_for_other_siblings_description": "Etter søsken nr. {{ sibling }} vil alle ytterligere søsken få denne rabatten. Sett den til null dersom du ønsker at de skal betale fullpris.",
  "economy.discount_for_sibling_#": "Rabatt for søsken nr. {{ sibling }}",
  "economy.discount_level": "{{ level }} rabattnivå",
  "economy.discount_levels": "Rabattnivåer",
  "economy.discount_value_100_warning_message": "Brukere med 100% rabatt vil motta et betalingskrav på NOK 0.",
  "economy.discount_value_range_error_message": "Rabatten må være mellom 0% og 100%.",
  "economy.division_permission_denied": "Du har ikke tillatelse til å opprette treningsavgift for denne divisjonen",
  "economy.download_invoice": "Last ned faktura (PDF)",
  "economy.download_orders": "Last ned bestillinger",
  "economy.dugnad": "Dugnad",
  "economy.dugnad_created": "Dugnaden ble opprettet",
  "economy.dugnad_no_access_success": "Din forespørsel er registrert. Vi vil kontakte deg snart.",
  "economy.dugnad_no_access_text_1": "Ønsker du tidlig tilgang?",
  "economy.dugnad_no_access_text_2": "Kontakt oss! Pilotklubber er allerede i gang.",
  "economy.dugnad_no_access_textarea_placeholder": "Fortell oss hva du ønsker",
  "economy.dugnad_no_access_title": "Løsninger for Dugnader kommer snart!",
  "economy.dugnad_order_description": "Alle deltagere vil kunne bekrefte bestillingen sin frem til bestillingsfristen.",
  "economy.dugnad_participants": "Deltagende i dugnaden",
  "economy.dugnad_payment": "Dugnadsbetaling",
  "economy.dugnad_person_in_multiple_groups": "Personer som er utøvere i to eller flere grupper vil kun motta én dugnad.",
  "economy.dugnad_reminder_description": "Påminnelsen er sendt som e-post og push-varsel i appen. Meldingen inneholder nødvendig informasjon om dugnaden. Her kan du legge til en personlig melding for <b>{{ name }}</b>.",
  "economy.dugnad_reminder_description_1": "Meldingen inneholder følgende informasjon: <i>velg type påminning for å se detaljer</i>.",
  "economy.dugnad_reminder_description_2": "Meldingen inneholder følgende informasjon: dugnadstittel, beskrivelse og bestillingsfrist.",
  "economy.dugnad_reminder_description_3": "Meldingen inneholder følgende informasjon: dugnadstittel.",
  "economy.dugnad_reminder_description_4": "Meldingen inneholder følgende informasjon: dugnadstittel, beskrivelse og frist for betaling.",
  "economy.dugnad_reminder_description_hint": "Her kan du legge til en personlig melding for <b>{{ name }}</b>.",
  "economy.dugnad_select_groups_description": "Velg gruppen som mottar innbetalingene fra dugnaden.",
  "economy.dugnad_select_groups_description_2": "Velg gruppene for utøverne som skal delta på dugnaden.",
  "economy.dugnad_settings_description": "Lag en <b>spørreundersøkelse</b> for å samle inn informasjon om antallet enheter medlemmene ønsker å bestille. Du kan opprette en betaling for de senere.",
  "economy.dugnad_settings_description_2": "Eller lag en <b>betaling</b> med en gang.",
  "economy.dugnad_update_success": "Dugnad for {{ name }} ble oppdatert",
  "economy.dugnad_was_updated": "Dugnaden ble oppdatert",
  "economy.dugnad_will_be_created_for": "Dugnad vil bli opprettet for",
  "economy.dugnad_without_orders": "Dugnad uten bestillinger",
  "economy.economy_empty_state_description": "Hvis du skal ha visning - og redigeringstilgang, må du bli lagt til som økonomiansvarlig.",
  "economy.economy_empty_state_header": "Her ligger økonomien til klubben",
  "economy.edit_dugnad_for": "Endre dugnad for",
  "economy.edit_order_for": "Endre bestilling for",
  "economy.edit_payment_due_date": "Endre betalingsfrist",
  "economy.edit_payment_due_date_description": "Betalingsfristen vil bli oppdatert for alle utøvere. Publiseringsdatoen vil kun oppdateres for de som ikke bestilte innen fristen ({{ date }}).",
  "economy.edit_payment_due_date_description_2": "For medlemmer som har bekreftet bestillingen ble betalingen publisert med en gang.",
  "economy.edit_pick_up": "Endre utlevering",
  "economy.edit_tf_description": "Endringer vil kun påvirke nye medlemmer",
  "economy.edit_tf_description_2": "Eksisterende betalinger vil ikke bli endret",
  "economy.enter_age_from_to": "Angi alder fra {{ ageFrom }} til {{ ageTo }} år",
  "economy.estimated_income": "Estimert inntekt",
  "economy.estimated_income_description_1": "Estimatet inkluderer ikke tilfeller der utøvere velger frikjøp eller får familierabatt.",
  "economy.estimated_income_description_2": "Estimatet inkluderer ikke tilfeller der utøvere velger frikjøp.",
  "economy.estimated_income_description_3": "Estimatet inkluderer ikke tilfeller der utøvere får familerabatt.",
  "economy.estimated_net_amount": "Estimert netto beløp",
  "economy.estimated_transaction_fee": "Estimert transaksjonsavgift",
  "economy.event_payment": "Aktivitetsbetaling",
  "economy.event_with_payment": "Aktivitet med betaling",
  "economy.example_based_on_price": "Grunnprisen i eksempelet er satt til NOK {{ price }}.",
  "economy.example_description": "Eksemplet er basert på et minimum antall enheter per utøver - {{ items }} og pris per enhet på {{ price }} kr.",
  "economy.example_for_adults": "Eksempel: For voksne",
  "economy.example_of_what_user_will_see": "Eksempel på hva brukere vil se",
  "economy.excel_payment_description": "Genrerer en Excel-fil for intern bruk. Filen inneholder alle betalinger opprettet per medlem for valgt år/måned.",
  "economy.excel_payment_notification": "Kun tilgjengelig på klubb- og særidrettsnivå.",
  "economy.excel_payment_overview": "(Excel) Betalingsoversikt",
  "economy.exempted": "Fritatt",
  "economy.exempted_items": "Antall enheter fritatt",
  "economy.exempt_payment": "Fritatt for betaling",
  "economy.expected": "Forventet",
  "economy.expected_income": "Forventet inntekt",
  "economy.family_discount": "Familierabatt",
  "economy.family_discount_description": "Vennligst fyll inn minimumspris per medlem og familiepris",
  "economy.family_discount_description_2": "Minimumspris per medlem kan ikke være mindre enn 50 kroner på grunn av NIFs reglement for medlemsavgift.",
  "economy.family_discount_description_3": "Familieprisen er prisen man betaler for et antall familiemedlemmer om totalsummen av å betale hvert enkelt medlemskap overstiger det du setter her.",
  "economy.family_discount_description_4": "Om summen til sammen for hele familien er mindre enn \"familieprisen\" betaler de opprinnelige beløp. Om Antall familiemedlemmer * Minimumspris per medlem overstiger Familieprisen, vil familieprisen økes til Antall familiemedlemmer * Minimumspris per medlem. ",
  "economy.family_discount_info": "Rabatt vil bli gitt til alle familiemedlemmer som er spillere i de valgte",
  "economy.family_discount_info_2": "Rabatten blir satt når brukeren bekrefter ordren eller betalingen.",
  "economy.family_discount_option_description": "Rabatt vil bli gitt til alle familiemedlemmer som er spillere i de valgte gruppene. Rabatten blir satt når brukeren bekrefter ordren eller betalingen.",
  "economy.family_discounts_example": "Eksempel familierabatt",
  "economy.family_discounts_example_description": "Velg folk å betale for:",
  "economy.family_discounts_example_description_2": "Velg familiemedlemmer nå for å få familierabatt",
  "economy.family_discounts_example_header": "Eksempel på hva brukeren vil se",
  "economy.family_discounts_example_member_1": "Brukerens navn",
  "economy.family_discounts_example_member_2": "Barn 1",
  "economy.family_discounts_example_member_3": "Barn 2",
  "economy.family_discounts_example_member_4": "Brukerens partner sitt navn",
  "economy.family_discounts_example_optional_payment_hint": "Støtt idrettslaget ditt ved å bli støttemedlem",
  "economy.family_discounts_example_table_age": "Alder",
  "economy.family_discounts_example_table_discount": "Rabatt",
  "economy.family_discounts_example_table_items": "Enheter",
  "economy.family_discounts_example_table_name": "Navn",
  "economy.family_discounts_example_table_price": "Pris",
  "economy.family_member_#": "Familiemedlem #",
  "economy.family_price": "Familiepris",
  "economy.family_price_note": "Familieprisen inkluderer transaksjonsavgift",
  "economy.family_price_validation": "Kan ikke være lavere enn minimumspris per medlem",
  "economy.fee_value_helper_text": "For å unngå feil må alle felt fylles ut. Dersom en gruppe ikke skal betale treningsavgift kan du sette prisen til null.",
  "economy.fill_at_least_one_age_field": "Fyll ut minst ett aldersfelt",
  "economy.find_goods": "Finn vare",
  "economy.fixed_number": "Bestemt antall",
  "economy.fixed_#_of_items": "Bestemt # enheter",
  "economy.for_age": "For {{ age }}",
  "economy.forgot_anyone": "Glemt noen?",
  "economy.for_group_type": "For",
  "economy.from_age_including": "Fra og med alder",
  "economy.from_age_to_age": "Fra {{ ageFrom }} år til {{ ageTo }} år",
  "economy.full_catalogue_button": "vår produktkatalog",
  "economy.full_overview": "Totaloversikt for",
  "economy.full_price": "Fullpris",
  "economy.generate_export": "Generer eksport",
  "economy.generate_invoice_for": "Opprett faktura for",
  "economy.generate_new_invoice": "Opprett en ny faktura",
  "economy.generation_date": "Fakturadato",
  "economy.give_your_dugnad_a_title": "Gi dugnaden en tittel",
  "economy.go_back_and_fix": "Gå tilbake og endre",
  "economy.group": "Lag",
  "economy.groups_have_no_accounts": "{{ groupName }} med undergrupper mangler bankkonto",
  "economy.include_transaction_fee": "Transaksjonsavgift inkludert",
  "economy.including_transaction_fee": "Inkludert <span>{{ fee }}</span> i transaksjonsavgift",
  "economy.income": "Inntekt",
  "economy.invalid_range": "Ugyldig alder",
  "economy.invoice": "faktura",
  "economy.invoice_history": "Fakturahistorikk",
  "economy.invoice_history_for": "Fakturahistorikk for",
  "economy.invoices": "fakturaer",
  "economy.invoices_i_created": "Fakturaer opprettet av meg",
  "economy.item": "enhet",
  "economy.items": "enheter",
  "economy.items_for_family_member": "Antall enheter for familiemedlem nummer {{ number }}",
  "economy.items_for_other_family_members": "Antall enheter for øvrige familiemedlemmer",
  "economy.items_per_player": "Antall per utøver",
  "economy.items_per_player_description": "Angi antall enheter for alle spillere, eller still in spesifikt for aldersgrupper.",
  "economy.items_per_player_description_2": "Minimum antall enheter tilater utøvere å øke antallet enheter i appen. Bestemt antall fjerner denne muligheten.",
  "economy.jump_to_member_payments": "Gå til betalinger",
  "economy.latest_publish_date_description": "Vi sender ut betalingsforespørsler til alle på betalingens publiseringsdato.",
  "economy.latest_publish_date_description_2": "Medlemmer vil ikke kunne endre antall enheter dersom de ikke bestiller innen fristen.",
  "economy.manager_is_already_on_the_list": "{{ name }} er allerede på lagt til",
  "economy.mandatory_for_new_players_joining": "Obligatorisk for nye spillere som blir med",
  "economy.mandatory_tf_to_join_description": "Når denne er aktivert får nye medlemmer 15 minutter på seg til å betale. De som ikke betaler innen fristen mister medlemskapet i gruppen automatisk.",
  "economy.mandatory_tf_to_join_existing_payment_description_1": "Dette påvirker ikke gruppen som allerede har forhåndsbetaling for å delta.",
  "economy.mandatory_tf_to_join_existing_payment_description_2": "Dette påvirker ikke de {{ number }} gruppene som allerede har forhåndsbetaling for å delta.",
  "economy.mandatory_tf_to_join_existing_payment_description_3": "Men du kan endre det ved å gjøre justeringer for individuelle grupper.",
  "economy.mandatory_tf_to_join_label": "Krev at nye spillere skal betale denne avgiften ved innmelding i gruppen",
  "economy.mandatory_tf_to_join_label_2": "Gjelder <b>{{ group }}</b> og alle undergrupper.",
  "economy.maximum_age_is": "Øvre aldersgrense er {{ age }} år",
  "economy.member_price": "Medlemspris",
  "economy.members": "Medl.",
  "economy.membership_fee": "Medlemskontingent",
  "economy.membership_fee_no_permission_description": "Bare <b>ledere på klubbnivå</b> har tilgang til denne siden",
  "economy.membership_fee_no_permission_header": "Klubbens økonomi",
  "economy.membership_payments": "Medlemskontigenter",
  "economy.members_under_age": "Gjelder familiemedlemmer under {{ age }} år.",
  "economy.minimum_age_is": "Nedre aldersgrense er {{ age }} år",
  "economy.minimum_number_of_items": "Minimum antall enheter",
  "economy.minimum_#_of_items": "Minimum # enheter",
  "economy.minimum_price_per_member": "Minimumspris per medlem",
  "economy.minimum_price_per_member_validation": "Kan ikke være lavere enn NOK 50",
  "economy.min_number_of_items": "Min antall enheter",
  "economy.most_expensive_fee": "Den dyreste treningsavgiften",
  "economy.multiple_price_categories": "Flere priskategorier",
  "economy.multiple_price_categories_description": "Velg en egendefinert pris for å opprette en faktura",
  "economy.must_publish_for_show_progress": "Publiser betalingen for å se fremgang",
  "economy.net_amount": "Netto beløp",
  "economy.net_amount_for_team": "Netto beløp for {{ team }}",
  "economy.net_amount_per_item": "Nettobeløp per enhet",
  "economy.net_payout": "Netto utbetaling",
  "economy.new": "Ny",
  "economy.new_dugnad": "Ny dugnad",
  "economy.new_dugnad_payment": "Ny dugnadsbetaling",
  "economy.new_invoice_description": "Du må markere betalingen som innbetalt etter at betalingen er mottatt på konto.",
  "economy.new_invoice_description_2": "Vi sender ikke automatiske påminnelser etter at faktura er opprettet. Du kan fortsatt sende manuelle påminnelser som varsler i appen og på e-post.",
  "economy.new_membership_fee": "Ny medlemskontingent",
  "economy.new_payment": "Ny betaling",
  "economy.new_pick_up_reminder_description": "Påminnelser er sendt som e-post og notifikasjon i appen. Den automatiske meldingen inneholder nødvendig informasjon om det de har bestilt. Nedenfor kan du legge til en personlig melding til <b>{{ firstName }} {{ lastName }}</b>.",
  "economy.new_pick_up_reminder_for": "Ny påminnelse om henting for",
  "economy.new_reminder_description": "En purring blir sendt som e-post og push-notifikasjon i appen. Den automatiske meldingen inneholder nødvending betalingsinformasjon. Nedenfor kan du legge til en personlig melding til <b>{{ firstName }} {{ lastName }}</b>.",
  "economy.new_reminder_for": "Ny purring til",
  "economy.new_reminder_success": "Ny purring sendt til {{ name }}",
  "economy.new_sales_dugnad": "Ny salgsdugnad",
  "economy.new_training_fee": "Ny treningsavgift",
  "economy.next": "Neste",
  "economy.nif_minimum_price_description": "Minimumspris per medlem kan ikke være mindre enn 50 kroner på grunn av NIFs reglement for medlemsavgift.",
  "economy.no_bank_accounts": "Ingen bankkontoer funnet",
  "economy.no_bank_accounts_description": "Gruppen i gruppevelgeren må ha en bankkonto lagt til ",
  "economy.no_divisions": "Ingen særidretter opprettet enda",
  "economy.no_divisions_instruction": "Gå til dine grupper og legg til minst en avdeling før du oppretter treningsavgift.",
  "economy.no_order_created": "Ingen spørreundersøkelse opprettet",
  "economy.no_payments": "Ingen tidligere betalinger",
  "economy.no_survey_created": "Ingen spørreundersøkelse opprettet",
  "economy.no_teams_in_division": "This division does not have any teams",
  "economy.not_paid": "Ikke betalt",
  "economy.not_paid_estimation_description": "Estimat basert på alle betalinger unntatt avslått, fritatt og betalte. Summen inkluderer ikke transaksjonsavgift.",
  "economy.not_paid_in_total": "Ubetalt (totalt)",
  "economy.not_paid_payments": "Utestående betalingsforespørsler:",
  "economy.not_picked_up": "Ikke hentet",
  "economy.number": "Antall",
  "economy.number_of_items": "Antall enheter",
  "economy.number_of_items_per_person": "Antall enheter pr person",
  "economy.number_of_items_per_player": "Antall enheter per utøver",
  "economy.#_of_items": "# enheter",
  "economy.#_of_items_for_family_member": "Antall enheter for familiemedlem #{{ number }}",
  "economy.#_of_items_for_other_family_members": "Antall enheter for øvrige familiemedlemmer",
  "economy._of_items_for_other_family_members_hint": "Dette er antallet enheter alle familiemedlemmer etter #{{ number }} må kjøpe.",
  "economy.#_of_players": "# utøvere",
  "economy.one_fee": "Bare en treningsavgift",
  "economy.ongoing": "Pågående",
  "economy.optional": "Valgfri",
  "economy.order": "Spørreundersøkelse",
  "economy.order_created": "Spørreundersøkelse opprettet",
  "economy.order_date_description": "Bestillingen vil bli publisert 12:00.",
  "economy.order_publish_date": "Publiseringsdato for bestilling",
  "economy.order_reminder": "Bestillingspåminnelse",
  "economy.orders": "Bestillinger",
  "economy.order_update_success": "Bestilling for {{ name }} har blitt oppdatert",
  "economy.other_options": "Annet",
  "economy.other_options_dugnad_description": "Alle idrettslag er forskjellige! Her kan du tilpasse dugnaden til å passe deres idrettsalg.",
  "economy.other_options_mf_description": "Alle idrettslag er forskjellige! Her kan du tilpasse medlemsavgiften til å passe deres idrettslag.",
  "economy.other_options_tf_description": "Alle idrettslag er forskjellige! Her kan du tilpasse treningsavgiften til å passe deres idrettslag.",
  "economy.other_payment": "Annen betaling",
  "economy.other_payment_description": "Opprett en betaling for hele organisasjonen, en særidrett, et lag eller enkeltpersoner.",
  "economy.other_payments": "Andre betalinger",
  "economy.other_payment_warning": "Legg til bankkonto i gruppeinnstilling før man kan lage betaling",
  "economy.outstanding": "Utestående",
  "economy.overdue": "Forfalt",
  "economy.paid": "Innbetalt",
  "economy.paid_in_total": "Betalt (totalt)",
  "economy.paid_payments": "Fullførte betalinger",
  "economy.participants": "deltagere",
  "economy.past": "Tidligere",
  "economy.past_payment": "Tidligere betaling",
  "economy.payment_date": "Betalingsdato",
  "economy.payment_deadline": "Betalingsfrist",
  "economy.payment_deadline_validation_message_max": "?",
  "economy.payment_deadline_validation_message_min": "Betalingsfrist kan ikke være i fortid.",
  "economy.payment_details_price_categories": "Priskategorier",
  "economy.payment_details_tab_details": "Detaljer",
  "economy.payment_details_tab_payments": "Betalinger",
  "economy.payment_export": "Eksporter betalinger",
  "economy.payment_exports_label": "Betalingseksport for ",
  "economy.payment_exports_label_2": " og undergrupper",
  "economy.payment_for": "Betaling for",
  "economy.payment_for_description": "Betalingen sendes ut 01.01.{{ year }}.",
  "economy.payment_for_description_2": "Utøvere som blir med i løpet av året får betalingen med 14 dagers frist.",
  "economy.payment_for_validation_message_max": "Du mente kanskje ikke {{ years }} år frem i tid? :)",
  "economy.payment_for_validation_message_min": "Betalingen må være for dette året eller et år i fremtiden.",
  "economy.payment_information": "Betalingsinformasjon",
  "economy.payment_is_mandatory_for_joining_the_group": "Betaling er påkrevd for å bli medlem av gruppen",
  "economy.payment_is_mandatory_for_joining_the_group_2": "Påkrevd betaling ble ikke betalt i tide",
  "economy.payment_is_mandatory_for_joining_the_group_description": "{{ name }} må betale denne betalingen før betalingsfristen for å bekrefte medlemskapet i {{ group }}, ellers vil de bli fjernet fra gruppen.",
  "economy.payment_is_mandatory_for_joining_the_group_description_2": "{{ name }} betalte ikke denne betalingen før betalingsfristen, derfor ble de automatisk fjernet fra {{ group }}.",
  "economy.payment_is_mandatory_for_joining_the_group_description_3": "For å gi brukeren mer tid til å betale, kan du utsette betalingsfristen.",
  "economy.payment_is_mandatory_for_joining_the_group_description_4": "Hvis du ønsker å beholde brukeren i gruppen, kan du endre betalingsstatusen til \"Fritatt\".",
  "economy.payment_is_mandatory_for_new_players_joining": "\"{{ payment }}\" er påkrevd for nye spillere som blir med i disse gruppene",
  "economy.payment_is_mandatory_for_new_players_joining_description": "Nye spillere og de som automatisk legges til fra gruppenes venteliste må betale innen 15 minutter og 24 timer. Dersom betalingen ikke utføres innen gitt tid, vil slike spillere bli fjernet fra gruppen.",
  "economy.payment_note": "Kommentar",
  "economy.payment_note_invoice_hint": "Kommentarer vil være synlig på fakturaen",
  "economy.payment_paid": "Innbetalt",
  "economy.payment_publish_action_description": "Betalingen blir publisert klokken 12:00.",
  "economy.payment_publish_date_tooltip": "Utøvere som ikke bekreftet innen fristen vil motta betalingen denne dagen.",
  "economy.payment_reminder": "Betalingspåminnelse",
  "economy.payments": "Betalinger",
  "economy.payments_i_created": "Betalinger opprettet av meg",
  "economy.payment_will_be_on_hold": "[translate]",
  "economy.payout": "Utbetaling",
  "economy.payouts_arrival_date_hint": "For pågående utbetalinger er dette et estimat",
  "economy.pdf_payment_description": "Genererer et formelt PDF dokument for regnskap. Denne rapporten inneholder alle betalinger og avgifter for den valgte utbetalingsdatoen.",
  "economy.pdf_payment_report": "(PDF) Betalingsrapport",
  "economy.period": "Periode",
  "economy.period_end": "Periodeslutt",
  "economy.period_invalid": "Ugyldig periode",
  "economy.period_start": "Periodestart",
  "economy.picked_up": "Hentet",
  "economy.pick_up": "Utlevering",
  "economy.pick-up": "Hent",
  "economy.pick_up_description": "Vi vil informere dugnadens {{ number }} deltagere som har bestillingsstatus “ikke hentet”, om tid og sted for hvor de kan hente sine produkter.",
  "economy.pick_up_description_2": "{{ number }} dugnad participants with “not picked up” order status received information about the date and place where they can come to pick up the products.",
  "economy.pick_up_end": "Slutt",
  "economy.pick_up_location": "Utleveringssted",
  "economy.pick_up_managers_description_1": "Velg hvem som skal vær ansvarlig for utlevering av produktene, og hvem som kan registrere hentingen.",
  "economy.pick_up_managers_description_2": "For å registrere henting må de taste inn bestillingskoden i appen. Koden vil bli vist frem av personen som henter produktene.",
  "economy.pick_up_manager_warning": "Produktene kan ikke levere seg selv. Venligst legg til minst 1 person som kan være ansvarlig for utleveringen.",
  "economy.pick_up_reminder": "Påminnelse om henting",
  "economy.pick_up_reminder_history_description": "Purringer sendes som e-post og push-notifikasjon i appen. Du kan også sende en purring manuelt.",
  "economy.pick_up_start": "Start",
  "economy.pick_up_time": "Utleveringstidspunkt",
  "economy.pick_up_time_passed": "Pick up time passed",
  "economy.pick_up_time_passed_description": "You can’t edit pick up after pick up time has passed.",
  "economy.pick_up_was_successfully_set": "Utlevering er satt opp",
  "economy.pick_up_was_successfully_updated": "Utlevering har blitt oppdatert",
  "economy.player_fee_description": "Velg en regel for hvilken treningsavgift medlemmer som er med i flere grupper skal betale (alle andre treningsavgifter vil bli markert som fritatt):",
  "economy.player_fee_label": "Kostnad for spillere som er i flere grupper",
  "economy.player_fee_placeholder": "Treningsavgift som spillere skal betale",
  "economy.previous_payment": "Forrige utsendte betaling {{ year }}",
  "economy.price": "Pris",
  "economy.price_categories_description_1": "Legg til priskategorier for medlemskontingenten for {{ organization }}.",
  "economy.price_categories_description_2": "Forsikre deg om at priskategoriene inkluderer alle brukere.",
  "economy.price_categories_description_3": "Brukere som ikke er inkludert, vil ikke motta medlemsavgiften.",
  "economy.price_for_team": "Pris for {{ team }}",
  "economy.price_option": "Priskategori",
  "economy.price_options": "Priskategorier",
  "economy.price_per_item": "Prisen per enhet",
  "economy.price_per_item_description": "Skriv inn prisen per enhet som skal selges.",
  "economy.price_per_item_description_2": "Du kan også legge til en pris for frikjøp dersom noen ikke ønsker å delta på dugnaden.",
  "economy.price_per_player": "Pris per utøver",
  "economy.price_range": "Prisklasse",
  "economy.prices_for_teams": "Priser for lagene i {{ division_name }}",
  "economy.prices_for_teams_description_1": "Medlemmer i flere grupper må betale den billigste treningsavgiften.",
  "economy.prices_for_teams_description_2": "Medlemmer i flere grupper må betale den dyreste treningsavgiften.",
  "economy.prices_for_teams_description_3": "Medlemmer i flere grupper må betale alle treningsavgifter.",
  "economy.prices_for_teams_required_to_join_description": "Nye spillere som blir med i grupper der denne betalingen ble merket som obligatorisk, vil få 15 minutter til å betale denne treningsavgiften for å bekrefte medlemskapet sitt. Ellers blir de automatisk fjernet.",
  "economy.price_user_will_see_and_pay": "Prisen mottakeren vil se og betale",
  "economy.product_name_label": "Navn",
  "economy.products": "Produkter",
  "economy.products_description": "Legg til navnet et produkt (for eksempel, toalettpapir) og prisen per enhet.",
  "economy.products_description_1": "Leter du etter en leverandør av produkter med problemfri levering? Velg produkter fra en av våre partnere!",
  "economy.products_description_2": "Ved å legge til flere produkter vil utøvere kunne velge den produktkombinasjonen de selv ønsker basert på antallet enheter per utøver satt over.",
  "economy.profit": "Fortjeneste",
  "economy.publish_date_description": "Betalingen blir publisert klokken 12:00.",
  "economy.publish_date_mf_description": "Medlemskontingenten blir publisert klokken 12:00.",
  "economy.publish_date_tf_description": "Treningsavgiften blir publisert klokken 12:00.",
  "economy.publish_date_validation_message_min": "Publiseringsdato må være i fremtiden.",
  "economy.received": "Mottatt",
  "economy.remain_not_confirmed": "Forbli ubekreftet",
  "economy.reminder_explanatory_message": "En påminnelse vil bli sendt ut dagen før betalingsfristen, deretter blir det sendt ut en purring 3, 5, 10 og 15 dager etter betalingsfristen har utløpt. Nye utøvere vil motta også motta treningsavgiften med 14 dagers forfall.",
  "economy.reminder_history_description_1": "Påminnelser for å bekrefte bestillingen vil bli sendt ut 2, 5, og 7 dager før, i tillegg til utløpsdatoen.",
  "economy.reminder_history_description_2": "Påminnelser om betaling vil automatisk bli sendt ut 2, 5 og 7 dager etter at betalingsfristen utløpte, deretter en gang i uken.",
  "economy.reminder_history_description_3": "Du kan også sende en påminnelse om utlevering manuelt.",
  "economy.reminder_history_description_4": "Alle påminnelser blir sendt som e-post og som pushvarsler i appen.",
  "economy.reminder_text": "Purretekst",
  "economy.reminder_type": "Påminnelsestype",
  "economy.remove_dugnad_manager": "Fjern dugnadsansvarlig?",
  "economy.remove_dugnad_manager_description": "Etter lagret endring, kan ikke <b>{{ manager }}</b> lenger lede utlevering i “{{ dugnad }}” dugnaden. Vi vil informere om dette via epost.",
  "economy.reports_unavailable": "Rapport er ikke tilgjengelig enda",
  "economy.republish_order_description": "Datoen å bekrefte ({{ date }}) har ikke passert. Ved å endre status til “Ikke bekreftet”, tillater du at {{ name }} kan angi sin bestilling.",
  "economy.republish_order_for": "Publiser bestillingen for {{ name }} på nytt",
  "economy.required": "Påkrevd",
  "economy.require_players_to_preorder_items": "Tilltat at deltagerene betaler senere",
  "economy.require_players_to_preorder_items_description": "Deltagere kan bestille produkter når dugnaden publiseres, og vil motta en betalingsforespørsel på et senere tidspunkt.",
  "economy.require_players_to_preorder_items_hint": "Du kan sette en bestillingsfrist, og når betalingsforespørselen skal sendes. Deltagere som ikke svarer innen svarfristen vil automatisk bli tildelt minimum antall enheter. Spillere som blir medlem etter bestillingsfristen vil ikke bli inkludert i dugnaden.",
  "economy.role": "Rolle",
  "economy.same_price_all_teams": "Skal alle lag ha samme pris?",
  "economy.search_by_name_or_payment": "Søk etter navn eller betaling",
  "economy.search_by_name_payment_invoice": "Søk etter navn, betaling eller fakturanummer",
  "economy.search_by_payment_name": "Søk etter betaling",
  "economy.select_export_type": "Velg type eksport",
  "economy.select_from_catalogue": "Legg til fra katalog",
  "economy.select_from_catalogue_description_1": "For å gjøre leveringen smidigst mulig anbefaler vi å velge produkter fra kun én leverandør per dugnad. ",
  "economy.select_from_catalogue_description_2": "Du kan endre produktets navn og pris i neste steg.",
  "economy.select_from_catalogue_description_3": "For detaljert produktinformasjon, se",
  "economy.select_period": "Velg periode",
  "economy.select_time_period": "Velg tidsperiode",
  "economy.select_users": "Velg mottakere",
  "economy.select_users_label": "Velg hvem som skal få betalingen ved å klikke på \"Velg mottakere.\"",
  "economy.select_year": "Velg år",
  "economy.set_age_groups": "Sett aldersgruppe",
  "economy.set_pick_up": "Sett utlevering",
  "economy.set_price": "Legg til pris",
  "economy.set_price_user_will_receive": "Sett prisen brukeren skal betale",
  "economy.set_to_buy_out": "Endre til frikjøp",
  "economy.sibling_#": "Søsken nr.",
  "economy.sibling_discount": "Søskenmoderasjon",
  "economy.sibling_discounts_example": "Eksempel på søskenmoderasjon",
  "economy.specify_month": "Spesifiser måned",
  "economy.status_overview": "Detaljert statusoversikt",
  "economy.submit_categories": "Send inn priskategorier",
  "economy.suggested_price": "Foreslått salgspris",
  "economy.sum": "Sum",
  "economy.sum_does_not_include_transaction_fee": "Beløpet inkluderer ikke transaksjonsavgiften.",
  "economy.summary": "Oppsummering",
  "economy.survey": "Spørreundersøkelse",
  "economy.survey_created": "Spørreundersøkelse opprettet",
  "economy.team": "lag",
  "economy.teams": "lag",
  "economy.tell_players_what_this_dugnad_is_about": "Fortell medlemmene hva dugnaden går ut på",
  "economy.tf_adjust_for_individual_groups": "Tilpass for individuelle grupper",
  "economy.tf_default_net_amount": "Standard nettobeløp",
  "economy.tf_default_price": "Standardpris",
  "economy.tf_details_excluded_group_hint": "Medlemmer i denne gruppen mottok ikke treningsavgift. Undergrupper kan fortsatt være inkludert.",
  "economy.tf_exclude": "Utelukk",
  "economy.tf_excluded_groups": "Ekskluderte grupper",
  "economy.tf_excluded_groups_description": "Disse gruppene ble merket som ekskluderte, derfor trenger ikke spillerne deres å betale denne avgiften.",
  "economy.tf_mandatory": "Påkrevd",
  "economy.tf_override_mandatory_payment": "Overstyr påkrevde betalinger",
  "economy.tf_override_mandatory_payment_description": "Nye spillere som blir med i denne gruppen eller dens undergrupper må allerede betale annen obligatorisk betaling <b>{{ payment }}</b> for å bekrefte medlemskapet sitt. Aktivering av dette alternativet vil erstatte den betalingen med denne treningsavgiften.",
  "economy.tf_summary_excluded_group_hint": "Medlemmer i denne gruppen vil ikke motta treningsavgift. Undergrupper kan likevel inkluderes.",
  "economy.this_payment_is_mandatory_tooltip": "Betaling er påkrevd for å bli medlem av gruppen.",
  "economy.title": "Tittel",
  "economy.to_age_including": "Til og med alder",
  "economy.toilet_paper_pack": "Toalettpapir sekk",
  "economy.total": "Totalt",
  "economy.total_description_tooltip": "Totalen er filtrert basert på valgt gruppe og alle undergrupper.",
  "economy.total_number_of_items": "Totalt antall enheter",
  "economy.total_price_including_fees": "Totalpris inkludert transaksjonsavgift",
  "economy.total_price_to_buy_out": "Total pris for frikjøp",
  "economy.training_fee": "Treningsavgift",
  "economy.training_fee_no_permission_description": "Bare <b>ledere med økonomirettigheter</b> har tilgang til denne siden",
  "economy.training_fee_no_permission_header": "Særidrettens økonomi",
  "economy.training_payments": "Treningsavgifter",
  "economy.transaction_fee": "Transaksjonsavgift",
  "economy.transferred": "Overført",
  "economy.under_age": "Til {{ age }} år",
  "economy.upcoming": "Kommende",
  "economy.update_age_group": "Oppdater aldersgruppe",
  "economy.update_price_category": "Oppdater priskategori",
  "economy.update_product": "Oppdater produkt",
  "economy.who_is_the_price_for": "Hvem er prisen for?",
  "economy.will_be_published_description": "Blir publisert når brukere bekrefter bestillingen eller den {{ publishUserPaymentsDate }} for de som ikke bestilte innen fristen ({{ confirmationDueDate }}).",
  "economy.will_be_published_on": "Vil bli publisert den",
  "economy.you_can_add_any_person_from": "Du kan legge til hvilken som helst person fra",
  "event_details.account": "Konto",
  "event_details.activity_for": "aktivitet for ",
  "event_details.activity_type_description": "Opprett en enkel aktivitet for en kamp eller stort arrangement, eller en repeterende aktivitet for en fast, ukentlig trening.",
  "event_details.activity_type_description_2": " Enkle aktiviteter har flere avanserte funksjoner, som betalinger og påmeldingsfrister.",
  "event_details.add_meetup": "Legg til oppmøte",
  "event_details.add_participants_limit": "Legg til deltagergrense",
  "event_details.add_payment": "Legg til betaling",
  "event_details.add_reminder": "Legg til påminnelse",
  "event_details.add_reminders": "Legg til påminnelser",
  "event_details.add_response_deadline": "Legg til påmeldingsfrist",
  "event_details.all_activities": "Alle repeterende aktivitet",
  "event_details.automatically_register": "Automatisk registrering",
  "event_details.automatically_register_description": "Alle inviterte er automatisk påmeldt. Vi minner dem på å gi beskjed dersom de ikke kan komme!",
  "event_details.bi_weekly": "annenhver uke",
  "event_details.cancel_activity": "Avlys aktivitet",
  "event_details.cancel_choice": "Hva vil du gjøre?",
  "event_details.cancel_description": "Avlys arrangementet hvis det ikke lenger skjer. Deltakerne vil bli varslet.",
  "event_details.cancelled_reason": "Årsak til avlysning ",
  "event_details.cancelled_reason_placeholder": "Hvorfor er aktiviteten avlyst?",
  "event_details.cancel_payment_description": "OBS! Det er ingen automatisk refusjon ved avlysning av betalte aktiviteter.",
  "event_details.change": "Endre",
  "event_details.change_activity_for_group": "Endre aktivitet for {{ groupName }}",
  "event_details.change_stop_repetition": "Dersom du ønsker å avslutte aktiviteten tidligere kan du endre på datoen for <strong>Stopp gjentagelse</strong>",
  "event_details.choose_activity_type": "2. Velg aktivitetstype",
  "event_details.choose_a_group": "1. Velg en grupppe",
  "event_details.choose_a_group_description": "Velg gruppen du vil lage aktivitet for.",
  "event_details.clear_for_this_occurrence": "Rens for denne aktiviteten",
  "event_details.clear_location_or_reset_to_default": "Fjern området eller tilbakestill til standard",
  "event_details.clear_location_or_reset_to_default_description": "Vil du slette området helt for denne spesifikke aktiviteten av {{ eventTitle }}, eller tilbakestille den til aktivitetens standardområde {{ location }}?\nIkke glem å klikke \"Lagre\" når du er ferdig med å redigere.",
  "event_details.contact_persons_required": "Aktiviteten må ha minst én og kan ha maks tre kontaktpersoner.",
  "event_details.copy_event_button": "Kopier",
  "event_details.custom_location": "Egendefinert område",
  "event_details.deadline_subtext_2": "Vi vil varsle alle inviterte med en melding 24 timer før påmeldingsfristen du setter.",
  "event_details.delete_activity": "Slett aktivitet",
  "event_details.delete_description": "Når du sletter en hendelse, vil den forsvinne permanent. Ingen vil bli varslet.",
  "event_details.delete_recurring": "Slette repeterende aktivitet",
  "event_details.delete_recurring_all": "Tidligere og fremtidige aktiviteter blir slettet.",
  "event_details.delete_recurring_description": "Sletter du alle aktiviteter slettes alle aktiviteter både i fortiden og fremtiden.",
  "event_details.delete_recurring_future": "Denne og fremtidige aktiviteter vil også bli slettet.",
  "event_details.delete_recurring_single": "Tidligere og fremtidige aktiviteter blir ikke påvirket.",
  "event_details.delete_reminders": "Slett påminnelser",
  "event_details.description": "Beskrivelse",
  "event_details.end": "Slutter",
  "event_details.event_cannot_be_deleted_tooltip": "Aktiviteter med betaling kan ikke slettes",
  "event_details.event_details": "Aktivitetsdetaljer",
  "event_details.event_is_imported_from_excel": "Aktiviteten er importert fra Excel",
  "event_details.event_is_imported_from_fotballdata": "Aktiviteten er importert fra Fotballdata",
  "event_details.event_is_imported_from_nif": "Aktiviteten er importert fra NIF",
  "event_details.footballdata_button": "Se mer hos NFF",
  "event_details.future_activities": "Denne og fremtidige",
  "event_details.invitations": "invitasjoner",
  "event_details.last_manual_reminder": "",
  "event_details.last_manual_reminder_counter": "",
  "event_details.limit": "Grense",
  "event_details.limit_members_invalid": "Angi et tall større enn eller lik 0",
  "event_details.location": "Sted",
  "event_details.manual_register": "Manuell registrering",
  "event_details.manual_register_description": "Alle inviterte må registrere deltagelse hver gang. Vi tar oss av påminnelsene!",
  "event_details.match": "Kamp",
  "event_details.match_description": "Når satt, viser et kampsymbol på aktiviteten i kalenderen og i oppmøtestatistikk.",
  "event_details.meetup_location": "Oppmøtested",
  "event_details.meetup_location_subtext": "Hvis du ønsker et annet oppmøtested",
  "event_details.meetup_time": "Oppmøtetid",
  "event_details.meetup_time_subtext": "Før aktiviteten starter",
  "event_details.monthly": "hver måned",
  "event_details.new": "Ny",
  "event_details.no_posts_description": "",
  "event_details.no_posts_title": "",
  "event_details.only_this": "Bare denne",
  "event_details.participants": "",
  "event_details.participants_limit": "Utøvergrense",
  "event_details.participants_limit_helper_text": "Når grensen er nådd kan ikke inviterte godta invitasjonen.",
  "event_details.participants_limit_hint": "Antallet inviterte som har akseptert invitasjonen har overskredet deltakergrensen. Du kan endre svar til deltakere i oversikten over inviterte.",
  "event_details.payment": "Betaling",
  "event_details.payment_future_warning": "Betaling kan ikke bli lagt til etter aktiviteten er opprettet.",
  "event_details.payment_subtext": "Ta kontakt med Hoopit-ansvarlig i klubben din for å legge til en bankkonto",
  "event_details.payment_warning": "Betalinger kan ikke bli endret etter aktiviteten er opprettet.",
  "event_details.price": "Pris",
  "event_details.recurrence": "Gjentagelse",
  "event_details.recurring": "Repeterende",
  "event_details.recurring_event": "Dette er en repeterende aktivitet",
  "event_details.remind_unanswered_description": "",
  "event_details.remind_unanswered_title": "",
  "event_details.repeating_activity": "repeterende",
  "event_details.reset_location": "Tilbakestill området",
  "event_details.save_and_notify_members_about_changes": "Lagre og varsle deltagere om endringer?",
  "event_details.select_contact_persons": "Velg kontaktperson",
  "event_details.select_location": "Velg område",
  "event_details.set_custom_location": "Bestem egendefinert område",
  "event_details.single": "Enkel",
  "event_details.single_activity": "enkel",
  "event_details.start": "Starter",
  "event_details.stop_recurrence": "Stopp gjentakelse",
  "event_details.this_and_future_occurrences": "Denne og alle fremtidige",
  "event_details.this_is_a_recurring_activity": "Dette er en repeterende aktivitet",
  "event_details.this_is_a_recurring_event": "Dette er en repeterende aktivitet",
  "event_details.this_is_match": "Dette er en kamp",
  "event_details.title": "Tittel",
  "event_details.update_only_this_or_all": "Endre kun denne aktivitet, eller denne og alle fremtidige aktiviteter?",
  "event_details.update_stop_repetition_date": "Vil du oppdatere datoen gjentagelse skal stoppe for {{ eventName }}?",
  "event_details.user_notified": "Deltakere vil bli varslet.",
  "event_details.user_not_notified": "Ingen vil bli varslet.",
  "event_details.weekly": "hver uke",
  "event_details.we_will_notify_members_about_cancellation": "Vi varsler de inviterte om kanselleringen.",
  "event_details.you_can_notify_members_about_changes": "Du kan varsle deltagere om endringer.",
  "events.10_minutes": "10 minutter",
  "events.15_minutes": "15 minutter",
  "events.1_hour": "1 time",
  "events.1_hour_30_minutes": "1 time 30 minutter",
  "events.1_venue_with_conflicts": "{{ number }} område med konflikter ",
  "events.2_hours": "2 timer",
  "events.30_minutes": "30 minutter",
  "events.45_minutes": "45 minutter",
  "events.5_minutes": "5 minutter",
  "events.activity_cancelled": "Aktiviteten er avlyst",
  "events.add_attendance_note": "Legg til oppmøtenotat",
  "events.add_bank_account": "Ta kontakt med en administrator i organisasjonen for å legge til bankkonto",
  "events.add_event": "Legg til aktivitet",
  "events.all": "Alle",
  "events.all_venues": "Alle områder",
  "events.attendance": "Oppmøte",
  "events.attendance_locks_responses_description": "Inviterte vil ikke kunne endre sitt svar etter at oppmøte er registrert.",
  "events.attendance_locks_responses_header": "Registrering av oppmøte vil låse alle svar",
  "events.attendance_registered": "Oppmøte regsitrert",
  "events.attendance_updated": "Oppmøte oppdatert",
  "events.attended": "Deltatt",
  "events.attending": "Deltar",
  "events.automatic_registration": "Automatisk registrering",
  "events.auto_reg_on": "Kategori er ikke mulig for aktiviteter med automatisk registrering",
  "events.before": "før",
  "events.before_start": "før start",
  "events.booked": "Reservert",
  "events.book_venue": "Reserver område",
  "events.calendar_is_not_public": "Kalender er ikke offentlig",
  "events.calendar_sharing_permission_message": "Klubbens ledelse må tillate deling av offentlig kalender.",
  "events.cancel_event": "Kanseller eller slett aktivitet",
  "events.cancel_event_button": "Avlys aktivitet",
  "events.cancel_event_description_1": "Avlys<b>{{event}}</b> hvis det ikke skal gjennomføres. Du kan gjenåpne den igjen senere.",
  "events.cancel_event_description_2": "Alle deltakere vil motta varsel om avlysningen.",
  "events.cancel_event_title": "Kanseller aktivitet?",
  "events.change_attendance_description": "Nåværende oppmøtestatus for {{ userName }} er",
  "events.change_event": "Endre aktivitet for {{ groupName }}",
  "events.change_occurrence": "Endre 1 aktivitet for {{ groupName }}",
  "events.change_response": "Endre svar",
  "events.change_response_description": "Nåværende svar for {{ userName }} er",
  "events.change_response_description_2": "Sett riktig svar.",
  "events.change_responses": "Endre svar",
  "events.changes_apply_text": "Endringer er ikke alltid synlige med en gang",
  "events.change_to": "Endre til {{ availableStatus }}",
  "events.change_venue": "Endre område",
  "events.checking_for_venue_conflicts": "Sjekker for bookingkonflikter på området",
  "events.conflicting_events_empty_state_description": "For å se om det er noen bookingkonflikter må du velge et område i nedtrekksmenyen over.",
  "events.conflicting_events_sub_header": "{{ number }} aktiviteter med konflikt på {{ venue }}",
  "events.create_event": "Opprett aktivitet",
  "events.create_event_description": "Lag enkeltaktiviteter for kamper eller andre engangsaktiviteter og repeterende aktiviteter for treninger eller andre gjentakende aktiviteter.",
  "events.create_events": "Opprett {{ createCount }} aktiviteter ",
  "events.create_events_success": "{{ createCount }} aktiviteter ble opprettet.",
  "events.create_event_without_participants": "Opprett aktivitet uten inviterte",
  "events.create_post_text": "Vær den første! Opprett et nytt innlegg ved å trykke på “Nytt innlegg” knappen",
  "events.current_occurrence": "Kun denne aktiviteten",
  "events.days_before": "Dager før",
  "events.days_in_advance": "Dager før hver hendelse",
  "events.deadline": "Svarfrist",
  "events.declined": "Avslått",
  "events.delete_event_button": "Slett ativitet",
  "events.delete_event_confirmation": "Vil du slette arrangementet?",
  "events.delete_event_confirmation_message": "<b>{{event}}</b> og alle relaterte data vil bli slettet permanent, og dette kan ikke angres. Deltakere vil <em>ikke</em> bli varslet om sletting.",
  "events.delete_event_confirmation_message_1": "Denne handlingen kan ikke angres.",
  "events.did_not_attend": "Ikke deltatt",
  "events.edit_activity": "Endre aktivitet",
  "events.edit_attendance": "Rediger oppmøte",
  "events.event": "Aktivitet",
  "events.event_cancelled": "Aktiviteten “{{ eventName }}” har blitt avlyst",
  "events.event_cancelled_text": "Aktivitet avlyst",
  "events.event_contact_persons": "Kontaktperson",
  "events.event_created": "Aktiviteten “{{ eventName }}” ble opprettet",
  "events.event_creator": "Organisator",
  "events.event_deleted": "Aktiviteten “{{ eventName }}” er slettet",
  "events.event_end": "Slutt",
  "events.event_information": "Aktivitetsinformasjon",
  "events.event_moved_from_all_day": "Aktiviteten “{{ eventName }}” har blitt flyttet og har som standard 1 time varighet",
  "events.event_moved_to_all_day": "Aktiviteten “{{ eventName }}” har blitt flyttet og vil som standard ha start klokken 00:00 og slutt 23:59",
  "events.event_move_from_future_to_past_error": "Du kan ikke flytte aktiviteter til fortiden.",
  "events.event_name": "Navn",
  "events.event_start": "Start",
  "events.event_started": "Aktiviteten har startet",
  "events.event_started_description": "Du kan ikke endre svar etter aktiviteten har startet.",
  "events.event_started_or_ended_error": "Du kan ikke redigere en aktivitet som allerede har startet eller er avsluttet",
  "events.event_tags_list": "En liste med valgte etiketter for denne aktiviteten",
  "events.event_updated": "Aktiviteten “{{ eventName }}” ble oppdatert",
  "events.event_without_participants": "Aktivitet uten inviterte",
  "events.every": "Hver",
  "events.every_month": "Gjenta hver måned",
  "events.every_other": "Annenhver",
  "events.every_other_week": "Gjenta annenhver uke",
  "events.every_week": "Gjenta hver uke",
  "events.excel_example_description": "Her kan du se et eksempel på en Excel-fil med korrekt informasjon.",
  "events.excel_example_description_2": "Legg merke til alle felt som er påkrevd <b>Dato</b>, <b>Tid</b>, <b>Kampnr</b>, <b>Hjemmelag</b> og <b>Bortelag</b> må være utfylte for å opprette aktivitetene.",
  "events.excel_example_header": "Eksempel på en Excel-fil",
  "events.excel_import": "Excel-import",
  "events.external_event": "Ekstern aktivitet",
  "events.external_group": "Ekstern gruppe",
  "events.for_group": "for {{ group }}?",
  "events.fotballdata": "Fotballdata",
  "events.fotballdata_description": "Hold lagets kalender oppdatert ved å importere og synkronisere alle kamper med FIKS.",
  "events.going": "Skal",
  "events.group_activities_description": "Her ser du alle aktivitetene til gruppen du er medlem av og alle undergruppene som tilhører. Hvis du lurer på hvordan du lager eller endrer en aktivitet, gå inn ",
  "events.groups": "Grupper",
  "events.groups_in": "Grupper i {{ organization_name }}",
  "events.here": "her",
  "events.hide_calendar": "Skjul kalender",
  "events.hours_before": "Timer før",
  "events.illness": "Sykdom",
  "events.import": "Importer",
  "events.imported_from": "Importert fra",
  "events.import_events": "Importer aktiviteter",
  "events.import_events_confirmation_description": "Du kan invitere deltakere senere fra aktiviteten. Alle importerte aktiviteter vil bli oppdatert basert på <b>Kampnr</b>.",
  "events.import_events_description": "Importer din Excel-fil og sjekk de importerte dataene etterpå. Du vil kunne endre informasjon om aktivitetene og invitere utøvere etter at de er opprettet.",
  "events.import_events_description2": "Importer fra <a href=\"http://handball.no/\" target=\"_blank\">handball.no</a><br/><a href=\"https://www.handball.no/system/sok/?search=&reg=all&content=klubb\" target=\"_blank\">Her</a> kan du søke etter laget ditt. Velg riktig lag og så \"Terminliste\" for å laste ned en Excel-fil med alle kampene.",
  "events.import_events_is_match": "Importerte aktiviteter vil ha indikatoren <b>“kamp”</b> synlig i kalender og oppmøtestatistikk.",
  "events.import_excel": "Importer Excel-fil",
  "events.import_matches": "Importer kamper",
  "events.import_matches_description": "Importer kamper fra Excel for å spare tid. Alle importerte kamper vil bli opprettet som enkeltaktiviteter.",
  "events.injury": "Skade",
  "events.invitation_schedule": "Invitasjonsplan",
  "events.invite_current_occurrence": "Inviter til kun denne aktiviteten eller denne og alle fremtidige?",
  "events.invited_description_text": "Alle inviterte er automatisk påmeldt. Vi minner de på å gi beskjed dersom de ikke kan komme!",
  "events.invited_to_occurrence_text": "Alle inviterte må registrere deltagelse hver gang. Vi tar oss av påminnelsene!",
  "events.invite_more": "Inviter flere",
  "events.invite_participants": "Inviter deltagere",
  "events.invite_to_recurring": "Inviter kun til denne aktiviteten, eller denne og alle i den repeterende aktiviteten?",
  "events.location_section_name": "Del {{ sectionName }} ({{ numberOfTakenSections }}/{{ numberOfSiblingSections }} av banen)",
  "events.luxsave_sync_up_duration": "LuxSave synkronisering kan ta opp til 30 minutter",
  "events.manage_attendance": "Administrere oppmøte",
  "events.manual_registration": "Manuell registrering ",
  "events.many_days_in_advance": "{{ count }} dager i forveien",
  "events.match": "Kamp",
  "events.meet_up": "Oppmøte",
  "events.minutes_before": "Minutter før",
  "events.more_options": "Flere valg",
  "events.must_be_before_start": "Må være før start",
  "events.must_be_between_days": "Må være mellom 1 og 28 dager",
  "events.must_be_between_hours": "Må være mellom 1 og 672 timer",
  "events.must_be_between_minutes": "Må være mellom 1 og 40.320 minutter",
  "events.must_be_between_weeks": "Må være mellom 1 og 4 uker",
  "events.must_be_in_future": "Må være frem i tid",
  "events.never_repeat": "Aldri",
  "events.new_activity": "Ny aktivitet",
  "events.new_event": "Ny aktivitet",
  "events.new_event_description": "Vennligst velg hvilken gruppe aktiviteten skal bli opprettet for. Du kan også endre type aktivitet: bruk <b>enkel</b> aktivitet for kamper eller andre engangs aktiviteter eller <b>repeterende</b> for treninger eller andre gjentagende aktiviteter.",
  "events.new_event_description_2": "Husk å fyll inn tittel og beskrivelse så alle forstår hvilken type aktivitet dette er.",
  "events.no_activity": "Ingen Aktivitet for valgt periode\n",
  "events.no_attendance_status": "Ingen status",
  "events.no_conflicting_events_found": "Digg! Ingen konflikter funnet",
  "events.no_locations_registered": "Du har ingen områder registrert.",
  "events.no_meetup_is_added": "Det er ikke lagt til noen oppmøtetid",
  "events.noone_invited_text": "Du har ikke invitert noen til aktiviteten “{{ eventName }}”. Du kan gjøre dette nå eller etter aktiviteten er opprettet",
  "events.no_payments": "Ingen betalinger å vise",
  "events.no_posts": "Ingen innlegg opprettet",
  "events.no_response_deadline_is_added": "Det er ikke lagt til en svarfrist",
  "events.no_results": "Ingen resultater",
  "events.not_answered": "Ikke svart",
  "events.not_going": "Skal ikke",
  "events.nothing_found": "Ingenting funnet",
  "events.not_registered": "ikke registrert",
  "events.number_venues_with_conflicts": "{{ number }} områder med konflikter ",
  "events.one_day_in_advance": "{{ count }} dag i forveien",
  "events.only_this": "Bare denne",
  "events.organization_venues_description": "Her finner du en oversikt over alle områdene din organisasjon disponerer. Du vil her kunne se når det eventuelt er ledig på et område, og når det ikke er det.",
  "events.other_groups": "Andre grupper",
  "events.other_reason": "Annen årsak",
  "events.other_training": "Annen trening",
  "events.participant_info": "Deltager",
  "events.participants": "Deltagere",
  "events.payment_cant_be_changed": "Betaling kan ikke endres etter at aktiviteten har startet.",
  "events.place": "Sted",
  "events.posts_in_activity": "Innlegg knyttet til aktiviteten",
  "events.press_enter_to_set_custom_location": "Trykk Enter for å bruke «{{ venueSearchValue }}» som egendefinert område",
  "events.reason": "Årsak",
  "events.recurrence": "Gjentagelse",
  "events.recurring_activity": "Dette er en repeterende aktivitet",
  "events.register_attendance": "Registrer oppmøte",
  "events.reminder": "Påminnelse",
  "events.reminder_cannot_be_in_past": "Påminnelse kan ikke være i fortiden",
  "events.remove_from_event": "Slett invitasjon",
  "events.remove_from_event_description": "{{ userName }} vil bli fjernet fra aktiviteten. Du kan invitere på nytt når som helst.",
  "events.remove_from_event_question": "Er du sikker på at du vil fjerne {{ first_name }} {{ last_name }} fra aktiviteten?",
  "events.remove_from_past_event": "Er du sikker på at du vil fjerne {{ userName }} fra aktiviteten?",
  "events.reopen_event": "Gjenåpne akviteitet?",
  "events.reopen_event_description": "<b>{{event}}</b> vil bli tilgjengelig for påmelding igjen, med samme invitasjoner som før. Deltakerene vil <em>ikke</em> bli informert.",
  "events.response": "Svar",
  "events.response_deadline": "Svarfrist",
  "events.response_deadline_helper_text": "Deltakere kan ikke endre svaret etter fristen.",
  "events.response_updated": "Svaret ble oppdatert.",
  "events.roles": "Roller",
  "events.scheduled_invitation": "Legg til planlagt invitasjon til arrangementet",
  "events.schedule_immediately": "Omgående",
  "events.schedule_immediately_description": "Invitasjonen til hendelsen vil bli sendt omgående",
  "events.schedule_on_date": "På dato",
  "events.schedule_on_date_description": "Invitasjonen til arrangementet vil bli sendt på en bestemt dato som du kan sette opp",
  "events.season_planner": "Sesongplanlegger",
  "events.season_planner_description": "Sørg for at lagets kalender forblir oppdatert og organisert ved å utnytte automatiske importer og synkronisering.",
  "events.section": "Del",
  "events.section_part": "Baneandel",
  "events.sections_overlap_error": "Flere aktiviteter planlagt på denne delen av banen i samme tidsrom",
  "events.sections_overlap_warning": "Flere aktiviteter planlagt på denne delen av banen i samme tidsrom",
  "events.see_paid_payments": "Se fullførte betalinger",
  "events.see_payments": "Se betalinger",
  "events.select_date": "Velg dato",
  "events.select_group_description": "Velg gruppen du vil aktivitetene skal bli opprettet for. Husk at alle importerte aktiviteter bli opprettet som enkeltaktiviteter.",
  "events.select_time": "Velg tid",
  "events.select_venue": "Velg område",
  "events.select_venues": "Velg områder",
  "events.select_venue_to_load_events": "Velg et område for å laste inn aktiviteter",
  "events.share_calendar": "Del kalender",
  "events.share_public_calendar": "Del offentlig kalender",
  "events.share_specific_venues": "Del spesifikke områder",
  "events.sharing_calendar_description": "Ved å dele kalenderen kan personer utenfor idrettslaget få en oversikt over aktivitetene deres.",
  "events.sharing_calendar_description_2": "Hvis du kun ønsker utvalgte områder skal vises, kan du velge hvilke i listen under.",
  "events.sharing_calendar_embed": "Bygg inn",
  "events.sharing_calendar_embed_description": "Bruk iframe-lenken for å integrere lokalets kalender på din organisasjons nettside.",
  "events.sharing_calendar_ical_ics": "iCAL/iCS",
  "events.sharing_calendar_ical_ics_description": "Bruk iCAL/iCS-lenken for å få tilgang til kalenderen for det valgte lokalet fra andre applikasjoner. Denne kalenderen oppdateres hver 8-24 time.",
  "events.sharing_calendar_public_link": "Offentlig lenke",
  "events.sharing_calendar_public_link_description": "Bruk offentlig lenke for å gi personer utenfor idrettslaget en oversikt over aktivitetene.",
  "events.show_calendar": "Vis kalender",
  "events.specify_reason": "Spesifiser årsak",
  "events.start": "Start",
  "events.subgroups": "Undergrupper",
  "events.team": "Gruppe",
  "events.this_future_occurrences": "Denne og alle fremtidige aktiviteter",
  "events.th_of_every_month": ". hver måned",
  "events.time": "Tid",
  "events.toggle_color": "Farge sorter etter område.",
  "events.toggle_group": "Filtrer etter grupper",
  "events.turnerings_admin": "TurneringsAdmin fra NIF",
  "events.turnerings_admin_description": "Importer og synkroniser enkelt NIF-arrangementene dine med Hoopit, slik at du sparer tid og krefter.",
  "events.update_events": "og oppdater {{ updateCount }} aktiviteter ",
  "events.update_events_success": "{{ updateCount }} aktiviteter ble oppdatert.",
  "events.updating": "Oppdaterer...",
  "events.user_was_removed": "{{ userName }} ble fjernet fra aktiviteten.",
  "events.venue": "Område",
  "events.venue_is_booked": "Dette området er allerede reservert",
  "events.venue_is_booked_description": "{{ location }} er allerede reservert på valgt tidspunkt. Vil du reservere området likevel?",
  "events.venue_is_booked_description_recurring": "{{ location }} er allerede reservert for en annen gruppe i dette tidsrommet for {{ conflictsNumber }} av {{ totalOccurrences }} aktiviteter. Vil du reservere området likevel?",
  "events.venues": "Områder",
  "events.waiting_list": "Venteliste",
  "events.weeks_before": "Uker før",
  "events.with_football_data": "Aktiviteten er importert fra Fotballdata",
  "events.your_groups": "Dine grupper",
  "expense_claims.account_number": "Nummer",
  "expense_claims.all": "Alle",
  "expense_claims.approve_claim_description_1": "Vennligst kontrollere at kvittering, sum, dato, kategori, gruppe og beskrivelse er tilfredstillende.",
  "expense_claims.approve_claim_description_2": "Kravet kan ikke endres eller avslått etter at det er godkjent, siden vi genererer et bilag som blir sendt til regnskapssystemet.",
  "expense_claims.approve_claim_description_3": "Velg hvilken konto for tilbakebetaling. Hvis ikke, vil bilaget bare vise gruppe.",
  "expense_claims.approve_claim_description_4": "Ettersom kontonummer ikke er satt opp for gruppen i systemet, vil bilaget ikke vise hvilken bankkonto tilbakebetalingen skal gjøres fra.",
  "expense_claims.approve_claim_description_5": "Kravet kan ikke endres eller avslått etter at det er godkjent, siden vi genererer et bilag som blir sendt til regnskapssystemet.",
  "expense_claims.approve_claim_header": "Godkjenn krav?",
  "expense_claims.approved": "Godkjent",
  "expense_claims.approved_by": "Godkjent av",
  "expense_claims.cannot_approve_claims_description": "Du kan ikke godkjenne egne krav. Vennligst kontakt klubbens kasserer.",
  "expense_claims.category": "Kategori",
  "expense_claims.change_group_description_1": "Kravet er nå rettet mot <b>{{ group }}</b>.",
  "expense_claims.change_group_description_2": "Dersom du endrer gruppen til en gruppe du ikke har tilgang til, vil kravet ikke lengre være synlig for deg.",
  "expense_claims.change_group_header": "Endre gruppe for kravet?",
  "expense_claims.change_group_success": "Kravet ble flyttet til {{ group }}",
  "expense_claims.change_project_header": "Oppdater utlegg?",
  "expense_claims.claim_approved_success": "Kravet ble godkjent. Kravdokumentet genereres.",
  "expense_claims.claim_details": "Kravdetaljer",
  "expense_claims.claim_marked_as_paid_success": "Kravet ble markert som utbetalt",
  "expense_claims.claim_name": "Navn",
  "expense_claims.claim_overdue_warning": "Kravet ble levert for mer enn 2 dager siden",
  "expense_claims.claim_overdue_warning_description": "Vennligst sjekk og enten godkjenn eller avvis.",
  "expense_claims.claim_rejected_success": "Kravet ble avvist",
  "expense_claims.details": "Detaljer",
  "expense_claims.doesnt_support_expenses": "Støtter ikke utlegg",
  "expense_claims.empty_state_description": "Bare <b>ledere med økonomirettigheter</b> har tilgang til denne siden",
  "expense_claims.empty_state_header": "Krav levert til klubben",
  "expense_claims.expense_description": "Beskrivelse",
  "expense_claims.expense_description_hint_1": "Påkrevd for kategorien \"{{ category }}\"",
  "expense_claims.expense_description_hint_2": "Valgfri",
  "expense_claims.expenses": "Utlegg",
  "expense_claims.expense_updated_success": "Utlegget ble oppdatert",
  "expense_claims.group_doesnt_support_expenses": "Gruppen støtter ikke utlegg",
  "expense_claims.group_doesnt_support_expenses_description": "Informer økonomiansvarlig om at bankkontoen for kravets gruppe ikke er aktivert for utlegg enda.",
  "expense_claims.mark_as_paid": "Marker som utbetalt",
  "expense_claims.mark_claim_as_paid_description_1": "Ved å sette denne statusen bekrefter du at utbetalingen til kravstiller har blitt utført.",
  "expense_claims.mark_claim_as_paid_description_2": "Kravstiller vil bli varslet.",
  "expense_claims.mark_claim_as_paid_header": "Marker som utbetalt?",
  "expense_claims.marked_as_paid": "Markert som betalt",
  "expense_claims.marked_as_paid_by": "Markert som utbetalt av",
  "expense_claims.no_account_connected": "Ingen konto valgt",
  "expense_claims.no_account_connected_description": "Gruppen har ingen bankkonto tilgjengelig for å refundere krav.",
  "expense_claims.note_from_receiver": "Notat fra kravstiller",
  "expense_claims.no_voucher": "Ingen dokument",
  "expense_claims.pay_from": "Betal fra",
  "expense_claims.pay_to": "Betal til",
  "expense_claims.previously_rejected_by": "Tidligere avvist av",
  "expense_claims.project_is_not_added": "Prosjekt er ikke lagt til",
  "expense_claims.project_updated_success": "Prosjekt ble oppdatert",
  "expense_claims.reason": "Grunn for avvisning",
  "expense_claims.receiver": "Mottaker",
  "expense_claims.reject_claim_description_1": "Oppgi en grunn for hvorfor kravet avvises slik at brukeren kan rette opp problemene.",
  "expense_claims.reject_claim_description_2": "Du vil ikke se avviste krav før de blir innsendt på nytt.",
  "expense_claims.reject_claim_header": "Avvis krav?",
  "expense_claims.rejected": "Avvist",
  "expense_claims.search_by_description": "Søk etter mottaker, betalt til eller krav id",
  "expense_claims.should_not_be_reimbursed": "Skal ikke tilbakebetales",
  "expense_claims.submitted": "Levert",
  "expense_claims.submitted_by": "Levert av",
  "expense_claims.the_voucher_is_being_generated": "Kravdokumentet genereres.",
  "expense_claims.update_expense_description_1": "Velg kategorien som stemmer for utlegget.",
  "expense_claims.update_expense_description_2": "Kategoriene “Sosial”, “Reise” og “Annet” krever beskrivelse.",
  "expense_claims.update_expense_header": "Oppdater utlegg?",
  "football_data.add": "Legg til",
  "football_data.add_more_fix_id": "Legg til flere fiksID her",
  "football_data.all": "Hele",
  "football_data.enter_path_to_register": "Gå inn på banen du vil registrere og kopier fiksID fra adressefeltet",
  "football_data.find_team": "Finn ditt lag på",
  "football_data.fix_id": "fiksID",
  "football_data.fix_id_blank_error": "FiksId kan ikke være blank",
  "football_data.fix_id_ellipsis": "FiksID...",
  "football_data.football_data": "Fotballdata",
  "football_data.get_fix_id": "og hent din fiksID i adresselinjen.",
  "football_data.groups": "Grupper",
  "football_data.hired": "(Leid)",
  "football_data.hoopit": "Hoopit",
  "football_data.import_campaign_data": "Importer kampdata",
  "football_data.managed_by": "(administrert av {{ group_name }})*",
  "football_data.matched_data": "Matchet data",
  "football_data.no_venues_registered": "Du har ingen områder registrert i Hoopit som kan kobles opp mot Fotballdata. Gå til",
  "football_data.oops": "Ops...",
  "football_data.organization": "organisasjon",
  "football_data.search_club": "Søk opp din klubb på fotball.no",
  "football_data.share": "Del",
  "football_data.to_add_venues": "for å legge til områder.",
  "football_data.total_registered": "{{ total }} registrert",
  "football_data.venue": "Baneandel",
  "football_data.venues": "Områder",
  "football_data.your_venues": "Dine områder",
  "form.confirmation_due_date_min_error": "Datoen må være etter at bestillingen er publisert",
  "form.description": "Beskrivelse",
  "form.field_future_date": "Betalingsdato må være i fortiden",
  "form.field_invalid_date": "Vennligst oppgi en gyldig dato",
  "form.field_required": "Dette feltet er påkrevd",
  "form.from": "Fra",
  "form.invalid_email": "Ugyldig e-post",
  "form.to": "Til",
  "gender.female": "Kvinne",
  "gender.male": "Mann",
  "gender.unknown": "Ukjent",
  "group.": "Gruppe",
  "group.add_subgroup": "Legg til undergruppe",
  "group.associate_members": "Støttemedlemmer",
  "group.guardians": "Foresatte",
  "group.include_subgroups": "Inkluder undergrupper",
  "group.leaders": "Ledere",
  "group.members": "Medlemmer",
  "group.name": "Navn",
  "group.others": "Andre",
  "group.parents": "Foresatte",
  "group.players": "Utøvere",
  "group_settings.account_active": "Aktiv",
  "group_settings.account_change_warning_message": "Kun økonomiansvarlig har tilgang til å endre på kontoen.",
  "group_settings.account_inactive": "Inaktiv",
  "group_settings.account_information": "Kontoinformasjon",
  "group_settings.accounting_email": "Regnskapssystemets e-post",
  "group_settings.accounting_email_description": "Legg til ditt regnskapssystem sin e-post for bilagsmottak. Vi sender automatisk utbetalingsrapporter og bilag ved tilbakebetaling til denne e-posten.",
  "group_settings.accounting_email_hint": "Generelt skal denne e-posten kun settes for organisasjonen og være tom for alle særidretter og lag. Dersom du ønsker at dokumentasjonen for denne gruppen skal sendes til en annen e-post kan du overstyre med en annen e-post her. Husk å påse at denne gruppens bankkonto også er ekstern fra denne organisasjonen for å unngå feil ved bankavstemming.",
  "group_settings.accounting_email_hint_1": "Dersom det ikke er en e-post spesifisert for denne gruppen vil vi automatisk bruke e-posten oppgitt for organisasjonen ({{ default }}).",
  "group_settings.accounting_email_hint_2": "Dersom det ikke er en e-post spesifisert for denne gruppen vil vi automatisk bruke e-posten oppgitt for organisasjonen (for øyeblikket er det ikke satt der).",
  "group_settings.account_name": "Kontonavn",
  "group_settings.account_number": "Kontonummer",
  "group_settings.account_number_validation_message": "Kontonummer må være 11 siffer",
  "group_settings.account_status": "Kontostatus",
  "group_settings.activate_waiting_list": "Aktiver venteliste",
  "group_settings.activate_waiting_list_description": "Når venteliste er aktivert vil spillere som melder seg inn etter at gruppen er full bli satt på venteliste.",
  "group_settings.add_new_account": "Legg til ny konto",
  "group_settings.admins_can_approve_own_claims": "Ledere kan godkjenne sine egne krav",
  "group_settings.admins_can_approve_own_claims_description": "Når dette er aktivert vil økonomiansvarlige i gruppen kunne godkjenne krav sendt mot denne gruppen.",
  "group_settings.allow_requests_when_group_is_full": "Tillat forespørsler når gruppen er full",
  "group_settings.allow_requests_when_group_is_full_description": "Når den er aktivert vil nye spillere kunne be om å bli med selv etter at utøverbegrensningen er nådd.",
  "group_settings.allow_requests_when_group_is_full_hint": "Dette alternativet er tilgjengelig når gruppen har en utøverbegrensning for utøvere.",
  "group_settings.apply_to_subgroups_title": "Bruk for undergrupper",
  "group_settings.cannot_delete_organization": "Klubb kan ikke slettes",
  "group_settings.club_website": "Klubbens hjemmeside",
  "group_settings.compatible_with_fotballdata": "kompatibelt med Fotballdata",
  "group_settings.contact_details": "Kontaktinformasjon",
  "group_settings.contact_details_description": "Gjør gruppen mer tilgjengelig ved å oppgi relevant kontaktinformasjon.",
  "group_settings.contact_email": "Kontakt-epost",
  "group_settings.contact_phone": "Kontakt-telefon",
  "group_settings.custom_fields": "Egendefinerte felter",
  "group_settings.custom_member_fields": "Egendefinerte medlemsfelter",
  "group_settings.custom_member_fields_description": "Opprett egendefinerte medlemsfelter (for eks. skole, kommune eller draktnummer) for å sikre tilstrekkelig informasjon om medlemmene i gruppen.",
  "group_settings.delete_group": "Slett gruppe",
  "group_settings.delete_group_description": "Er du sikker på at du vil slette denne gruppen? Reaktivere gruppen vil kreve hjelp av support-teamet vårt. Alle betalinger fra en slettet gruppe kan fortsatt redigeres i Økonomi.",
  "group_settings.delete_group_success": "Gruppen er blitt slettet.",
  "group_settings.delete_logo": "Slett logo",
  "group_settings.delete_logo_description": "Er du sikker på at du vil slette logoen?",
  "group_settings.delete_member_requests_list": "Brukere venter på godkjennelse",
  "group_settings.delete_member_requests_list_approve_description": "Brukere vil bli lagt til i gruppen som utøvere.",
  "group_settings.delete_member_requests_list_approve_title": "Godkjenn",
  "group_settings.delete_member_requests_list_decline_description": "Utøvere vil ikke bli lagt til i gruppen, men du vil kunne se avslåtte medlemmer i \"Medlemsforepsørsler\".",
  "group_settings.delete_member_requests_list_decline_title": "Avslå",
  "group_settings.delete_member_requests_list_description": "Det er for øyeblikket 1 bruker som har forespurt å bli medlem av <b>{{ group }}</b> som utøver.",
  "group_settings.delete_member_requests_list_description_1": "Det er for øyeblikket {{ count }} brukere som har forespurt medlemskap i  <b>{{ group }}</b> som utøvere.",
  "group_settings.delete_waiting_list": "Brukere på venteliste",
  "group_settings.delete_waiting_list_add_description": "Brukere vil bli lagt til i gruppen som spillere.",
  "group_settings.delete_waiting_list_add_title": "Legg til i gruppe",
  "group_settings.delete_waiting_list_clear_description": "Brukere vil ikke bli lagt til i gruppen, og ventelisten vil bli fjernet.",
  "group_settings.delete_waiting_list_clear_title": "Fjern ventelisten.",
  "group_settings.delete_waiting_list_description": "Du kan slette brukere sammen med ventelisten eller velge å flytte dem til gruppen.",
  "group_settings.delete_waiting_list_description_1": "Det er for øyeblikket {{ count }} brukere på ventelisten til <b>{{ group }}</b>.",
  "group_settings.description": "Beskrivelse",
  "group_settings.economy": "Økonomi",
  "group_settings.external_group": "Ekstern gruppe",
  "group_settings.external_group_description": "Brukere i eksterne grupper blir ikke regnet som medlemmer i av organsiasjonen og vil ikke få tilsendt medlemskontingent. De vil heller ikke bli synkronisert mot NIF eller telles som aktive medlemmer.",
  "group_settings.general": "Generelt",
  "group_settings.go_to_group_settings": "Gå til gruppeinnstillinger",
  "group_settings.group_info": "Gruppeinformasjon",
  "group_settings.group_is_not_empty": "Gruppen er ikke tom",
  "group_settings.group_is_not_empty_description": "Gruppen kan ikke slettes om de inneholder medlemmer eller undergrupper.",
  "group_settings.group_not_empty_conditions": "Din gruppe har ",
  "group_settings.group_visibility": "Gruppens synlighet",
  "group_settings.group_visibility_description": "Tillat at utøvere og foresatte kan bli med i denne gruppen via organisasjonens registreringsside. Utøvere kan fortsatt melde seg inn dersom de får direktelenken  for gruppen.",
  "group_settings.has_member": "medlemmer.",
  "group_settings.has_sub_group": "undergrupper.",
  "group_settings.hidden_from_onboarding": "Gruppen er skjult for alle på",
  "group_settings.inactive_account_description": "Når kontoen blir satt som inaktiv vil du fortsatt motta betalinger som er utestående, men det vil ikke være mulig å velge den når du lager ny betaling.",
  "group_settings.invitation_link": "Invitasjonslenke",
  "group_settings.joining": "Innmelding",
  "group_settings.joining_description_1": "Velg hvordan nye spillere kan registrere seg i denne gruppen",
  "group_settings.joining_description_2": "Merk at denne innstillingen ikke vil påvirke eksisterende medlemmer av gruppen, eller dem som er lagt til manuelt.",
  "group_settings.limit_members": "Begrens antall medlemmer",
  "group_settings.limit_members_invalid": "Angi et tall større enn eller lik 0",
  "group_settings.logo": "Logo",
  "group_settings.logo_description": "Last opp en logo for denne gruppen. Den vil være synlig blant annet under registrering, i e-poster og fakturaer.",
  "group_settings.main_details": "Gruppeinformasjon",
  "group_settings.main_details_description": "Hold gruppens informasjon oppdatert.",
  "group_settings.make_this_group_visible": "Gjør denne gruppen synlig for alle brukere",
  "group_settings.make_this_group_visible_description": "Når gruppen er satt som synlig vil alle kunne se den på organisasjonens registreringsside.",
  "group_settings.mandatory_payment": "Påkrevd betaling",
  "group_settings.mandatory_payment_description_1": "Sett opp en betaling som er påkrevd før man får tilgang til ei gruppe.",
  "group_settings.mandatory_payment_description_2": "Nye spillere som blir med i gruppen direkte vil få 15 minutter på seg til å betale. De som automatisk blir lagt inn fra ventelisten eller godkjent fra medlemsforespørsler vil ha 24 timer på seg til å betale.",
  "group_settings.mandatory_payment_description_3": "Merk at denne innstillingen ikke vil påvirke eksisterende medlemmer av gruppen, eller dem som er lagt til manuelt.",
  "group_settings.mandatory_payment_description_4": "Dersom betalingen ikke er publisert, er fryst, eller perioden for den ikke har startet eller er avsluttet, vil ikke utøvere kunne melde seg inn i gruppen.",
  "group_settings.mandatory_payment_description_5": "Dersom betalingen ikke utføres innen gitt tid, vil slike spillere bli fjernet fra gruppen.",
  "group_settings.mandatory_payment_hint": "Det er ingen treningsavgiften tilgjengelig for denne gruppen",
  "group_settings.mandatory_payment_warning": "Nye spillere kan ikke melde seg inn i denne gruppen",
  "group_settings.mandatory_payment_warning_1": "Denne treningsavgiften er ikke publisert, som forhindrer oss i å sende betalinger til nye spillere som ønsker å delta. Vennligst velg en annen treningsavgift, eller publiser denne, for å løse dette.",
  "group_settings.mandatory_payment_warning_2": "Perioden for denne treningsavgiften er avsluttet, som forhindrer oss i å sende betalinger til nye spillere som ønsker å delta. Vennligst velg en treningsavgift med en gyldig periode, for å løse dette.",
  "group_settings.manual_approval": "Manuell godkjenning",
  "group_settings.manual_approval_description": "Nye utøvere vil bli plassert i listen over medlemsforespørsler (“Medlemskap” > “Medlemsforespørsler”) og trenger manuell godkjennelse for å bli medlemmer.",
  "group_settings.mark_this_group_as_external": "Merk denne gruppen som ekstern",
  "group_settings.mark_this_group_as_external_description": "Når denne er skrudd på vil medlemmer i denne gruppen og undergrupper ikke regnes som medlemmer i organisasjonen din. De vil derfor ikke motta medlemskontingent og ikke bli synkronisert til NIF.",
  "group_settings.member_and_subgroup": "medlemer and undergrupper.",
  "group_settings.members_limit": "Angi medlemsgrensen",
  "group_settings.move_users_to_member_requests_list": "Brukere vil kreve manuell godkjennelse",
  "group_settings.move_users_to_member_requests_list_description": "Ved å bruke disse endringene vil {{ count }} brukere som for øyeblikket står på ventelisten til <b>{{ group }}</b> bli flyttet til \"Medlemsforespørsler\", hvor de vil vente på godkjennelse.",
  "group_settings.move_users_to_member_requests_list_description_1": "Ved å bruke disse endringene vil alle brukere som ber om å bli med i <b>{{ group }}</b> bli lagt til «Medlemsforespørsler», hvor de vil vente på godkjenning for å bli med.",
  "group_settings.move_users_to_waiting_list": "Brukere vil bli flyttet til ventelisten.",
  "group_settings.move_users_to_waiting_list_description": "Ved å bruke disse endringene vil {{ count }} brukere forespurt medlemskap i <b>{{ group }}</b> som utøvere bli flyttet fra \"Medlemsforespørsler\" til \"Venteliste. Derfra, når ledige plasser blir tilgjengelige, blir de automatisk flyttet i gruppen i samme rekkefølge som de registrerte seg.",
  "group_settings.move_users_to_waiting_list_x_success": "{{ count }} brukere blir flyttet, dette kan ta litt tid.",
  "group_settings.name": "Navn",
  "group_settings.no_accounts_message": "Ingen konto knyttet til gruppen.",
  "group_settings.no_accounts_warning_message": "Kun økonomiansvarlig kan legge til konto.",
  "group_settings.open": "Åpen",
  "group_settings.open_description_1": "Nye spillere kan bli medlem av gruppen umiddelbart etter registrering, så lenge utøverbegrensningen ikke er nådd. Ellers vil ingen kunne registrere seg.",
  "group_settings.organization_number": "Organisasjonsnummer",
  "group_settings.payout_period": "Utbetalingsfrekvens",
  "group_settings.payout_period_description": "Sett frekvensen du ønsker for overføring av innbetalinger til gruppens bankkonto.",
  "group_settings.permission_to_approve_own_claims": "Tillatelse for å godkjenne egne krav",
  "group_settings.permission_to_approve_own_claims_description": "Tillat ledere i denne gruppen å godkjenne sine egne utlegg og krav gjort mot denne gruppen.",
  "group_settings.players_limit": "Utøverbegrensning",
  "group_settings.players_limit_description": "Sett en grense for hvor mange utøvere som kan bli med i denne gruppen.",
  "group_settings.players_limit_exceeded_warning_description": "Å legge til disse brukerne vil overskride den maksimale utøverbegrensningen ({{ limit }} spillere). ",
  "group_settings.players_limit_exceeded_warning_title": "Utøverbegrensningen vil bli overskredet.",
  "group_settings.please_remove_members": "Vennligst slett alle i gruppen og prøv igjen.",
  "group_settings.please_remove_subgroups": "Vennligst slett undergrupper og prøv igjen.",
  "group_settings.redirecting_to": "Omdirigerer til",
  "group_settings.registration_page": "registreringsside",
  "group_settings.registration_page_note": "organisasjonens registreringsside",
  "group_settings.request_more_fields": "Be om flere felt",
  "group_settings.send_payment_as_mandatory_description": "Når den er aktivert må nye medlemmer betale denne avgiften innen 24 timer for å forbli i gruppen. Ellers vil den bli sendt som en vanlig betaling.",
  "group_settings.send_payment_as_mandatory_title": "Send “{{ payment }}” som påkrevd betaling.",
  "group_settings.settings": "innstillinger",
  "group_settings.sign_up": "Registrering",
  "group_settings.stripe": "Stripe",
  "group_settings.stripe_information": "Stripeinformasjon",
  "group_settings.this_group_type_does_not_support_signup": "Denne gruppetypen støtter ikke registrering.",
  "group_settings.update_stripe_information": "Oppdater informasjonen Stripe trenger",
  "group_settings.visible_from_onboarding": "Gruppen er synlig for alle på organisasjonens",
  "group_settings.waiting_list": "Venteliste",
  "group_settings.waiting_list_description": "Tillat utøvere å sette seg på venteliste hvor de kan vente på en ledig plass i denne gruppen.",
  "group_settings.waiting_list_description_1": "Når utøverbegrensningen er nådd, vil nye spillere bli plassert på ventelisten (“Grupper” > “Venteliste”) og vil automatisk bli lagt til i gruppen i den samme rekkefølgen de registrerte seg.",
  "group_settings.waiting_list_description_2": "Når utøverbegrensningen er nådd, vil nye spillere bli plassert på denne listen og automatisk bli lagt til i den samme rekkefølgen som de registrerte seg.",
  "group_settings.waiting_list_enable_description": "Når utøvergrensen er nådd vil alle nye brukere som melder seg inn bli satt på venteliste",
  "group_settings.website": "Hjemmeside",
  "group.type": "Gruppetype",
  "group_type.associate_members": "Støttemedlemmer",
  "group_type.communication_group": "Kommunikasjonsgruppe",
  "group_type.division": "Særidrett",
  "group_type.organization": "Organisasjon",
  "group_type.other": "Annet",
  "group_type.team": "Lag",
  "img_alt.empty_mailbox": "Tom postkasse",
  "img_alt.hoopit_logo": "Hoopit logo",
  "individual_payments.all_payments": "Alle",
  "individual_payments.ongoing_payments": "Aktive betalinger",
  "individual_payments.past_payments": "Tidligere betalinger",
  "invite_user.create_event": "Opprett aktivitet",
  "invite_user.create_payment": "Opprett betaling",
  "invite_user.empty": "Ingen {{ tab_name }}",
  "invite_user.group_activity_invite": "lagt til i gruppen i fremtiden vil bli invitert til denne aktiviteten.",
  "invite_user.invitation_description": "Alle du har valgt får tilsendt en invitasjon, og varsler om fremtidige endringer.",
  "invite_user.invitations": "Invitasjoner",
  "invite_user.invitation_sent": "Invitasjon sendt",
  "invite_user.invitations_tab": "invitasjoner",
  "invite_user.invite_future": "Inviter fremtidige {{ tab_name }}",
  "invite_user.invite_to_activity": "Her kan du invitere andre i idrettslaget til aktiviteten",
  "invite_user.number": "Antall",
  "invite_user.search": "Søk",
  "invite_user.search_by_name": "Søk med navn",
  "invite_user.search_result": "Fant {{ x }}",
  "invite_user.send_invitations": "Send invitasjoner",
  "invite_user.users_invited_this_may_take_some_time": "Brukere blir nå invitert, prosessen kan ta noe tid",
  "languages.en": "Engelsk",
  "languages.nb": "Norsk",
  "languages.uk": "Ukrainsk",
  "locations.add_default_behavior_tag": "Legg til standardoppførsel etikett for",
  "locations.add_default_tag": "Legg til standard etikett",
  "locations.add_location": "Legg til område",
  "locations.add_locations_text": "Legg til baner på",
  "locations.add_locations_text_2": "siden for å integrere med LuxSave.",
  "locations.add_more_tags": "Legg til flere etiketter",
  "locations.add_tag": "Legg til etikett for",
  "locations.add_tag_description": "Legg til en beskrivelse for denne etiketten, så de som oppretter aktiviteter forstår hva den gjør.",
  "locations.cannot_be_changed": "Kan ikke endres i etterkant.",
  "locations.confirm_default_tag": "Bekreft standard etikett",
  "locations.connect_location": "Knytt {{ locationName }} til LuxSave",
  "locations.connect_location_link": "på LuxSave sin integrasjonsnettside",
  "locations.connect_location_text": "For å knytte denne banen til LuxSave, skriv denne ID-en i skjema for å registrere kalender her",
  "locations.create_first_tag_text": "Vennligst opprett en etikett for standardoppførsel for LuxSave. Etter dette vil du kunne legge til vanlige etiketter.",
  "locations.custom_name": "Selvdefinert navn",
  "locations.default_behavior": "Standardoppførsel",
  "locations.default_behavior_keyword": "Standardoppførselen har ikke et nøkkelord i LuxSave.",
  "locations.default_behavior_text": "Dette er standardoppførselen",
  "locations.delete_location": "Slett bane",
  "locations.delete_location_confirmation": "Er du sikker på at du vil slette denne banen? Aktiviteter på denne banen vil ikke bli slettet.",
  "locations.delete_selected_tag": "Slett {{ tagName }} etikett",
  "locations.delete_tag": "Slett etikett",
  "locations.delete_tag_message": "Er du sikker på at du ønsker å slette denne etiketten? ",
  "locations.edit": "Rediger {{ locationName }}",
  "locations.edit_default_tag": "Endre standardoppførsel etikett for",
  "locations.edit_location": "Rediger bane",
  "locations.edit_tag": "Endre etikett",
  "locations.edit_tag_for": "Endre etikett for",
  "locations.field": "Område",
  "locations.finish": "Avslutt",
  "locations.go_to_location_list": "Gå til baneoversikt",
  "locations.how_many_sections": "Hvor mange mulige baneflater (maksimalt antall flater) har denne arenaen? Dette kan <b>ikke</b> endres i etterkant.",
  "locations.information_luxsave": "Informasjon om kobling til LuxSave",
  "locations.integrations": "Integrasjoner",
  "locations.last_syncronized": "Sist synkronisert",
  "locations.locaiton_list_text": "Liste over baner som har blitt integrert med LuxSave.",
  "locations.locaiton_list_text_2": "Det kan ta opptil 30 minutter å synkronisere oppdateringer.",
  "locations.location_list": "Områdeoversikt",
  "locations.location_set_up": "Banen ble satt opp",
  "locations.location_type": "Områdetype",
  "locations.luxsave": "LuxSave",
  "locations.luxsave_keyword": "LuxSave-nøkkelord",
  "locations.luxsave_keyword_text": "Fyll inn LuxSave-nøkkelordet. Vennligst forsikre deg om at det stemmer med LuxSave-nøkkelordet. Om det ikke gjør det vil det ikke fungere.",
  "locations.luxsave_tags": "LuxSave etiketter",
  "locations.name": "Områdenavn",
  "locations.never": "aldri",
  "locations.no_locations": "Ingen baner har blitt lagt til enda",
  "locations.no_locations_added": "Ingen baner har blitt lagt til enda",
  "locations.no_locations_available": "Ingen baner tilgjengelig til LuxSave-integrasjon",
  "locations.other": "Annet",
  "locations.owner": "Eier",
  "locations.page_access_text": "Kun <b>Banekoordinatorer</b> har tilgang til denne siden",
  "locations.sections": "Seksjoner",
  "locations.select_location": "Velg bane å knytte opp mot LuxSave",
  "locations.set_tag_name": "Vennligst legg til et navn for etiketten. Det vil være synlig for alle som oppretter aktiviteter.",
  "locations.set_tags": "Bestem etiketter for",
  "locations.settings": "{{ locationName }} innstillinger",
  "locations.shared": "delt",
  "locations.sync_up_duration_text": "Synkronisering kan ta opp til 30 minutter",
  "locations.tag_description_text": "Denne etiketten vil representere standarfoppførselen i LuxSave. Dette vil gjøre det enklere for andre å forstå hva standardoppførselen gjør",
  "locations.tags": "Nøkkelord",
  "member_list.action_add": "Attesten med utstedelsesdato {{ date }} ble lagt til",
  "member_list.action_extend": "Attesten med utstedelsesdato {{ issueDate }} ble forlenget med {{ extendedByYears }} år (til {{ endDate }})",
  "member_list.action_history": "Arkiv",
  "member_list.action_history_empty": "Ingen historie",
  "member_list.action_history_for": "Arkiv for {{ name }}",
  "member_list.action_remove": "Attesten med utstedelsesdato {{ date }} ble slettet",
  "member_list.action_update": "Attesten ble oppdatert: utstedelsesdato {{ issueDate }}, sluttdato {{ endDate }}, verifiseringsdato {{ verificationDate }}",
  "member_list.add_certificate": "Legg til attest",
  "member_list.add_certificate_confirmation": "Jeg bekrefter at jeg har sett attesten og at den er gyldig",
  "member_list.add_certificate_description": "Vennligst legg til start- og sluttdato for attesten",
  "member_list.add_certificate_description_2": "Vennligst sett verifiseringsdato for attesten",
  "member_list.add_certificate_for": "Legg til attest for {{ name }}",
  "member_list.add_certificate_success_toast": "Attesten ble lagt til",
  "member_list.certificate": "Attest",
  "member_list.certificate_details": "Detaljer",
  "member_list.certificate_expired": "Attest utløpt",
  "member_list.certificate_info": "Attestinformasjon",
  "member_list.certificate_is_valid": "Attesten er gyldig",
  "member_list.certificate_required": "Attest påkrevd",
  "member_list.certificates": "Attester",
  "member_list.criminal_record_certificates": "Politiattester",
  "member_list.criminal_record_certificates_no_access": "Kun <strong>de med tittel Politiattestansvarlig</strong> har tilgang til denne siden.",
  "member_list.does_not_have_certificate": "Mangler attest",
  "member_list.does_not_require_certificate": "Trenger ikke attest",
  "member_list.empty_sub_group": " har ingen undergrupper",
  "member_list.empty_sub_group_info": "Trykk knappen nedenfor for å lage en undergruppe til",
  "member_list.end_date": "Utløpsdato",
  "member_list.end_date_validation_message": "Dato må være i fremtiden og etter utstedelsesdato",
  "member_list.expires": "Sluttdato",
  "member_list.expires_in_1_day": "Utløper om 1 dag",
  "member_list.expires_in_days": "Går ut om {{ number }} dager",
  "member_list.expires_soon": "Utløper snart",
  "member_list.expires_today": "Utløper i dag",
  "member_list.extend_certificate": "Forny attest",
  "member_list.extend_certificate_confirmation": "Jeg bekrefter at jeg vil forlenge attesten",
  "member_list.extend_certificate_description": "Er du sikker på at du vil forlenge attesten til {{ name }} med {{ number }} år?",
  "member_list.extend_certificate_description_2": "Sluttdato vil bli endret fra {{ endDateBefore }} til {{ endDateAfter }}.",
  "member_list.extend_certificate_success_toast": "Attesten ble forlenget",
  "member_list.extended_certificate": "Forlenget attest",
  "member_list.extend_for_years": "Forlengelse med {{ number }} år",
  "member_list.filter": "Filter",
  "member_list.has_been_extended": "Har blitt forlenget",
  "member_list.has_certificate": "Har attest",
  "member_list.has_valid_certificate": "Har gyldig attest",
  "member_list.in": "i",
  "member_list.invite_more_people": "Du kan invitere flere til {{ organization_name }} med linken i gruppens innstillinger",
  "member_list.issue_date": "Utstedelsesdato",
  "member_list.issue_date_validation_message": "Dato må være før sluttdato og ikke i fremtiden",
  "member_list.members": "Medlemmer",
  "member_list.members_certificate_expires_soon": "{{ number }} attester går snart ut på dato",
  "member_list.members_missing_certificate": "{{ number }} personer mangler attest",
  "member_list.members_require_certificate": "{{ number }} personer er pålagt å ha attest",
  "member_list.member_was_exempted": "Medlemmet ble fritatt",
  "member_list.mf_not_published": "Medlemskontingenten har ikke blitt publisert",
  "member_list.min_2_characters": "Minimum 2 bokstaver",
  "member_list.missing_certificate": "Mangler attest",
  "member_list.navigate_to_members": "Se medlemmer",
  "member_list.no_certificate_required": "Attest ikke påkrevd",
  "member_list.no_members_found": "Vi fant ingen medlemmer",
  "member_list.nothing_found": "Vi fant ingen",
  "member_list.overview": "Oversikt",
  "member_list.remove_certificate": "Fjern attest",
  "member_list.remove_certificate_confirmation": "Jeg bekrefter at jeg vil fjerne attesten permanent",
  "member_list.remove_certificate_description": "Er du sikker på at du vil fjerne attesten for {{ name }} gyldig til {{ date }}?",
  "member_list.remove_certificate_success_toast": "Attesten ble slettet",
  "member_list.requests": "Forespørsler",
  "member_list.requirement_settings": "Innstillinger",
  "member_list.requirement_settings_for": "Innstillinger for {{ name }}",
  "member_list.requires_certificate": "Krever attest",
  "member_list.search": "Søk",
  "member_list.search_by_name": "Navnesøk",
  "member_list.tf_not_published": "Treningsavgiften har ikke blitt publisert",
  "member_list.update_certificate": "Oppdater attest",
  "member_list.valid_from": "Gyldig fra",
  "member_list.verification_date": "Verifiseringsdato",
  "member_list.verification_date_validation_message": "Dato må være mellom utstedelses- og sluttdato, og i fremtiden",
  "member_list.verified": "Verifisert",
  "member_list.view_members_info": "Trykk \"medlemmer\" i menyen ovenfor for å se personinfo.",
  "member_list.with_number": "med mobilnummer",
  "member_requests.decline_x_success": "{{ count }} forespørsler blir avslått, dette kan ta litt tid.",
  "membership.add_note_for": "Legg til notat for",
  "membership.age": "Alder",
  "membership.age_gender_statistics": "Alder-kjønn statistikk",
  "membership.age_gender_statistics_hint": "Den grafiske statistikken inneholder kun {{ membersType }} som har satt kjønn i profilen sin. For å se riktig og komplett statistikk må alle aktive sette kjønn i profilen sin.",
  "membership.all_time": "Total",
  "membership.any_ideas": "Har du forslag til denne siden så del gjerne med oss!",
  "membership.awaiting_confirmation": "Venter på bekreftelse",
  "membership.back_to_overview": "Tilbake til oversikten",
  "membership.by_joined_date": "Etter innmeldt dato",
  "membership.by_last_nif_confirmation_reminder": "Etter siste påminnelse om registrering i NIF",
  "membership.cancel_membership": "Avslutt medlemsskap",
  "membership.cancel_membership_bulk": "Avslutt medlemskap for {{ usersCount }} medlemmer?",
  "membership.cancel_membership_description_1": "Du er i ferd med å avslutte medlemsskapet til <b>{{ firstName }} {{ lastName }}</b> i <b>{{ group }}</b>.",
  "membership.cancel_membership_description_2": "De vil bli slettet fra følgende grupper i Hoopit",
  "membership.cancel_membership_description_3": "Følgende medlemsskap i NIF vil bli avsluttet:",
  "membership.cancel_membership_description_bulk": "Du er i ferd med å avslutte medlemskapet for {{ usersCount }} medlemmer i <b>{{ group }}</b>:",
  "membership.cancel_membership_description_exempt": "Følgende betalingsforespørsler vil bli fritatt:",
  "membership.cancel_membership_description_unpaid_count": "<b>{{ firstName }} {{ lastName }}</b> has <b>{{ paymentsCount }}</b> utestående betalingsforespørsler.",
  "membership.cancel_membership_success": "Medlemskap for {{ name }} blir avsluttet, det kan ta litt tid.",
  "membership.cancel_membership_success_2": "{{ name }} sitt medlemskap ble kansellert",
  "membership.cancel_membership_success_bulk": "Medlemskap for {{ usersCount }} personer blir avsluttet, det kan ta litt tid.",
  "membership.cancel_membership_success_exempting": "{{ paymentsCount }} betalingsforespørsler blir fritatt, dette kan ta litt tid",
  "membership.confirmation_in_progress": "Bekreftelse pågår",
  "membership.confirmation_sent": "En ny forespørsel om bekreftelse har blitt sendt til {{ name }}.",
  "membership.confirmation_sent_bulk": "En ny forespørsel om bekreftelse har blitt sendt til {{ usersCount }} medlemmer.",
  "membership.confirmed": "Bekreftet",
  "membership.confirmed_description": "Her finner du alle registrerte medlemmer i <b>{{ group }}</b>.",
  "membership.confirm_membership": "Bekreft medlemsskap",
  "membership.confirm_membership_success": "{{ name }} ble opprettet som medlem i {{ group }} og lagt til i gruppen for støttemedlemmer. ",
  "membership.confirm_without_hoopit": "Opprett i Hoopit",
  "membership.confirm_without_hoopit_description_1": "Du er i ferd med å opprette en bruker til <b>{{ firstName }} {{ lastName }}</b> i Hoopit som støttemedlem. De vil automatisk bli lagt til i gruppen for støttemedlemmer og få en medlemskontingent generert. ",
  "membership.confirm_without_hoopit_description_2": "Dette bør bare brukes for medlemmer som ikke forventes å bruke Hoopit på noe tidspunkt, f.eks. veteranmedlemmer o.l.",
  "membership.details": "Detaljer",
  "membership.edit_note_for": "Endre notat for",
  "membership.emailed_on": "",
  "membership.email_mismatch_warning_description_1": "",
  "membership.email_mismatch_warning_description_2": "",
  "membership.email_mismatch_warning_title": "",
  "membership.enroll_in_nif": "",
  "membership.enroll_in_nif_description_1": "",
  "membership.enroll_in_nif_description_2": "",
  "membership.expires_in_hours": "",
  "membership.export_statistics": "Eksporter statistikk",
  "membership.external_users": "",
  "membership.hoopit_account": "I Hoopit",
  "membership.imported": "Importert fra NIF",
  "membership.imported_description_1": "Her finner du alle medlemmer hos NIF, som vi ikke finner i Hoopit.",
  "membership.imported_description_2": "Disse medlemmene er enda ikke bekreftet som medlemmer, og du kan gjøre en av følgende:",
  "membership.imported_description_2_list_item_1": "invitere til Hoopit (de vil bli flyttet til “Innkommende” når de har godtatt invitasjonen)",
  "membership.imported_description_2_list_item_2": "avslutte medlemsskapet",
  "membership.imported_description_2_list_item_3": "opprette en bruker for de i Hoopit. Medlemmet vil bli lagt til i gruppen for støttemedlemmer og det vil genereres en medlemskontingent automatisk. ",
  "membership.imported_description_3": "All medlemsdata og medlemmer er importert direkte fra NIF.",
  "membership.imported_from_nif": "Importert fra NIF",
  "membership.invitation_sent": "Invitasjon sendt",
  "membership.invite_to_hoopit": "Invitere til Hoopit",
  "membership.invite_to_hoopit_bulk": "Inviter {{ usersCount }} medlemmer til Hoopit",
  "membership.invite_to_hoopit_description_1": "Du er i ferd med å sende <b>{{ firstName }} {{ lastName }}</b> en invitasjon til å bli med i Hoopit og <b>{{ group }}</b>.",
  "membership.invite_to_hoopit_description_2": "Dersom de godtar invitasjonen, vil de dukke opp i listen over inkommende medlemmer.",
  "membership.invite_to_hoopit_description_bulk": "Du er i ferd med å sende {{ usersCount }} personer en invitasjon til å bli med i Hoopit og <b>{{ group }}</b>. Hvis de godtar invitasjonen vil de bli et medlem.",
  "membership.joined_date": "Innmeldt dato",
  "membership.member_joined": "Ny medlem",
  "membership.member_left": "Utmeldt medlem",
  "membership.member_requests": "Medlemsforespørsler",
  "membership.member_requests_1_user_waiting": "1 bruker venter på",
  "membership.member_requests_all": "Alle",
  "membership.member_requests_all_description": "{{ count }} forespørlser totalt",
  "membership.member_requests_approve": "Godkjenn",
  "membership.member_requests_approved": "Godkjent",
  "membership.member_requests_approved_description": "{{ count }} godkjente forespørsler",
  "membership.member_requests_approve_single_description": "<b>{{ name }}</b> vil bli lagt til i <b>{{ group }}</b> som {{ role }}.",
  "membership.member_requests_approve_single_title": "Godkjenn forespørsel?",
  "membership.member_requests_approve_x_description": "{{ count }} forespørsler vil bli godkjent.",
  "membership.member_requests_approve_x_list_of_users": "Brukere som skal godkjennes for medlemskap i <b>{{ group }}</b>:",
  "membership.member_requests_approve_x_title": "Godkjenn {{ count }} forespørsler?",
  "membership.member_requests_decline": "Avslå",
  "membership.member_requests_declined": "Avslått",
  "membership.member_requests_declined_description": "{{ count }} avslåtte forespørsler",
  "membership.member_requests_decline_single_description": "<b>{{ name }}</b> sin forespørsel om å bli medlem av <b>{{ group }}</b> som{{ role }} vil bli avslått.",
  "membership.member_requests_decline_single_success": "{{ name }} sin forespørsel ble avslått.",
  "membership.member_requests_decline_single_title": "Avslå forespørsel?",
  "membership.member_requests_decline_x_description": "{{ count }} forespørsler vil bli avslått.",
  "membership.member_requests_decline_x_list_of_users": "Brukere som skal avvises for medlemskap i <b>{{ group }}</b>: ",
  "membership.member_requests_decline_x_title": "Avslå {{ count }} forespørsler?",
  "membership.member_requests_disabled_button": "Gå til innstillinger",
  "membership.member_requests_disabled_description": "Hvis du vil godkjenne hver spiller som blir medlem i {{ group }}, velg \"Manuell godkjennelse\" i gruppeinnstillinger.",
  "membership.member_requests_disabled_title": "Medlemforespørsel ikke aktivert",
  "membership.member_requests_message_to_user": "Melding til bruker",
  "membership.member_requests_message_to_user_approve": "Vi er veldig glade for å ha deg som en del av laget vårt.",
  "membership.member_requests_message_to_user_decline": "Dessverre ble din forespørsel avslått denne gange. Vi setter pris på din forståelse.",
  "membership.member_requests_note_add": "Legg til notat for {{ user }}",
  "membership.member_requests_note_edit": "Endre notat for {{ user }}",
  "membership.member_requests_note_hint": "Synlig kun for ledere",
  "membership.member_requests_pending": "Avventer behandling",
  "membership.member_requests_pending_description": "{{ count }} ventende forespørsler",
  "membership.member_requests_players_limit_exceeded_warning_description": "Å legge til denne brukeren vil overskride maks antall spillere i {{ group }}.",
  "membership.member_requests_players_limit_exceeded_warning_description_1": "Å legge til denne brukeren vil overskride maks antall spillere i {{ group }}.",
  "membership.member_requests_send_payment_as_mandatory_description": "Når denne funksjonen er aktivert, må brukere som legges til i {{ group }} betale en obligatorisk avgift innen 24 timer for å forbli i gruppen. Ellers vil det bli sendt som en vanlig betaling.",
  "membership.member_requests_send_payment_as_mandatory_title": "Send påkrevd betaling",
  "membership.member_requests_x_users_waiting": "{{ count }} brukere venter på",
  "membership.members": "Medlemmer",
  "membership.members_all_users": "",
  "membership.members_description": "med medlemskap i {{ group }}.",
  "membership.members_joined": "Nye medlemmer",
  "membership.members_joined_table_hint": "Tabellen inneholder kun medlemmer som ble registrert i {{ group }} eller eventuelle undergrupper i den perioden du har valgt og som fremdeles er medlemmer",
  "membership.members_joined_tooltip": "",
  "membership.members_left": "Utmeldte medlemmer",
  "membership.members_left_table_hint": "Tabellen inneholder kun medlemmer som har forlatt {{ group }} eller eventuelle undergrupper i den perioden du har valgt",
  "membership.members_missing_email_filter": "",
  "membership.members_movement": "Netto medlemmer ut/inn",
  "membership.members_paid_mf_external_tooltip": "",
  "membership.members_requires_attention": "",
  "membership.members_statistics": "Medlemsstatistikk",
  "membership.members_users_counter": "",
  "membership.missing_email": "",
  "membership.missing_membership_fee": "Mangler medlemskontingent",
  "membership.missing_payment": "Mangler medlemsavgift",
  "membership.new_reminder_description": "Påminnelse sendes som en e-post og en push-varsling i appen.  Meldingen er basert på sertifikatets tilstedeværelse eller utløpsdato.  Nedenfor kan du legge til en personlig melding for <b>{{ fullName }}</b>.",
  "membership.new_reminder_description_for_x": "Påminnelse sendes som en e-post og en push-varsling i appen.  Meldingen er basert på sertifikatets tilstedeværelse eller utløpsdato.  Nedenfor kan du legge til en personlig melding for <b>{{ count }}</b> medlemmer.",
  "membership.new_reminder_for": "Ny påminnelse for",
  "membership.new_reminder_for_x": "Ny påminnelse for {{ count }} medlemmer",
  "membership.nif_email_expiration_warning_title": "",
  "membership.nif_membership": "NIF medlemskap",
  "membership.no_email_provided": "Ingen e-post oppgitt",
  "membership.no_hoopit_account": "Ikke i Hoopit",
  "membership.no_nif_membership": "Ingen NIF medlemskap",
  "membership.no_notes_added": "Ingen notater lagt til",
  "membership.not_connected": "",
  "membership.not_connected_tooltip": "",
  "membership.not_enrolled": "",
  "membership.not_enrolled_cancelled": "",
  "membership.not_enrolled_cancelled_tooltip": "",
  "membership.not_enrolled_tooltip": "",
  "membership.page_under_construction": "Denne siden er ikke ferdig utviklet",
  "membership.paid_mf_period": "Betalt i {{year}}",
  "membership.paid_mf_statistics_title": "Medlemmer som har betalt medlemskontingent",
  "membership.pending": "Innkommende",
  "membership.pending_confirmation": "",
  "membership.pending_confirmation_tooltip": "",
  "membership.pending_description_1": "Her finner du en oversikt over alle som bruker Hoopit, som ønsker å bli medlem av <b>{{ group }}</b>.",
  "membership.pending_description_2": "Du kan følge med på hvor de er i prosessen, og ta kontakt dersom de sitter fast eller trenger en påminnelse.",
  "membership.pending_description_3": "Når alle stegene er gjennomført, vil de automatisk flyttes til listen over godkjente medlemmer.",
  "membership.recently_joined": "Nye medlemskap",
  "membership.reminder_history": "Purringshistorikk",
  "membership.reminder_history_description": "Påminnelse sendes som en e-post og en push-varsling i appen.  Meldingen er basert på sertifikatets tilstedeværelse eller utløpsdato.",
  "membership.request": "forespørsel",
  "membership.request_expired": "",
  "membership.request_nif_connection": "",
  "membership.request_nif_connection_bulk_description_1": "",
  "membership.request_nif_connection_bulk_description_2": "",
  "membership.request_nif_connection_description": "",
  "membership.request_nif_connection_single_description_1": "",
  "membership.request_nif_connection_single_description_2": "",
  "membership.requests": "forespørsler",
  "membership.requires_approval": "Krever godkjenning",
  "membership.requires_confirmation": "Mangler bekreftelse",
  "membership.search_by_name_phone_email_or_relative": "Søk med navn, telefonnummer, epost eller relasjoner",
  "membership.select_date_placeholder": "Velg år",
  "membership.send_certificate_reminders": "Send sertifikatpåminnelser",
  "membership.send_confirmation_reminder": "Send skjema for innmelding i NIF",
  "membership.send_invitation": "Send invitasjon",
  "membership.send_invitation_succes_bulk": "En invitasjon til Hoopit har blitt sendt til {{ usersCount }} personer.",
  "membership.send_invitation_success": "En invitasjon til Hoopit har blitt sendt til {{ name }}.",
  "membership.send_message": "Send epost",
  "membership.send_message_success": "Din melding er blitt sendt.",
  "membership.send_message_to": "Sende epost til",
  "membership.send_new_reminder": "Send ny påminnelse",
  "membership.sent_to_email": "",
  "membership.set_specific_date": "Sett dato",
  "membership.still_members": "Fremdeles medlemmer",
  "membership.suggestion": "Forslaget ditt",
  "membership.suggestion_received": "Vi har mottatt forslaget ditt. Takk for hjelpen!",
  "membership.this_month": "Denne måneden",
  "membership.this_week": "Denne uken",
  "membership.this_year": "Dette året",
  "membership.total_joins": "Antall nye",
  "membership.total_members": "Antall medlemmer",
  "membership.total_paid_mf_statistic": "Totalt antall betalende medlemmer",
  "membership.unknown_age": "Ukjent",
  "membership.user": "Bruker",
  "membership.users": "Brukere",
  "membership.verified_by_nif_description": "Brukerinformasjon er bekreftet av NIF mot folkeregisteret",
  "membership.who_to_invite": "Hvem vil du invitere?",
  "membership.who_to_invite_description": "Brukeren vil bli invitert til <b>{{ group }}</b>. Bruk gruppevelgeren om du vil endre gruppe.",
  "membership.your_message": "Din melding",
  "miscellaneous.1_day_ago": "1 dag siden",
  "miscellaneous.1_like": "1 liker",
  "miscellaneous.1_year": "1 år",
  "miscellaneous.all": "Alle",
  "miscellaneous.and": "og",
  "miscellaneous.and_sub_groups": "og undergrupper",
  "miscellaneous.april": "April",
  "miscellaneous.august": "August",
  "miscellaneous.beta": "Beta",
  "miscellaneous.change_to": "Endre til",
  "miscellaneous.d": "d",
  "miscellaneous.december": "Desember",
  "miscellaneous.deselect_all": "Fjern alle",
  "miscellaneous.division": "særidrett",
  "miscellaneous.divisions": "særidretter",
  "miscellaneous.dont_show_message_again": "Ikke vis denne meldingen igjen.",
  "miscellaneous.february": "Februar",
  "miscellaneous.filter": "Filter",
  "miscellaneous.full_description": "Full beskrivelse",
  "miscellaneous.group": "gruppe",
  "miscellaneous.groups": "grupper",
  "miscellaneous.h": "t",
  "miscellaneous.help_us_improve_page_description": "Har du forslag til forbedringer eller føler noe mangler? Kontakt oss her!",
  "miscellaneous.help_us_improve_page_title": "Hjelp oss med å forbedre denne siden",
  "miscellaneous.in": "i",
  "miscellaneous.include_transaction_fee": "Inkluder transaksjonsavgift",
  "miscellaneous.in_total": "Totalt",
  "miscellaneous.invalid_format": "Ugyldig format",
  "miscellaneous.january": "Januar",
  "miscellaneous.july": "Juli",
  "miscellaneous.june": "Juni",
  "miscellaneous.last_month": "Forrige måned",
  "miscellaneous.last_week": "Forrige uke",
  "miscellaneous.last_year": "Forrige år",
  "miscellaneous.learn_more": "Mer informasjon",
  "miscellaneous.like": "Lik",
  "miscellaneous.likes": "Liker",
  "miscellaneous.m": "m",
  "miscellaneous.march": "Mars",
  "miscellaneous.max_number_characters": "Maks {{ number }} tegn",
  "miscellaneous.may": "Mai",
  "miscellaneous.message": "Melding",
  "miscellaneous.min": "Min",
  "miscellaneous.min_2_characters": "Minimum 2 bokstaver",
  "miscellaneous.minimum": "Minimum",
  "miscellaneous.more": "Mer",
  "miscellaneous.n_days_ago": "{{ number }} dager siden",
  "miscellaneous.never": "Aldri",
  "miscellaneous.n_likes": "{{ number }} liker",
  "miscellaneous.none": "Ikke satt",
  "miscellaneous.no_sub_groups_found": "Ingen undergruppe funnet",
  "miscellaneous.note": "Notat",
  "miscellaneous.nothing_found": "Ingenting funnet",
  "miscellaneous.november": "November",
  "miscellaneous.n_years": "{{ number }} år",
  "miscellaneous.october": "Oktober",
  "miscellaneous.of": "av",
  "miscellaneous.oops_something_went_wrong": "Oops! Noe gikk galt",
  "miscellaneous.or": "eller",
  "miscellaneous.please_try_again": "Vær så snill, prøv igjen.",
  "miscellaneous.please_try_another_name_or_phone_number": "Venligst prøv et annet navn eller mobilnummer",
  "miscellaneous.redirecting": "Omdirigerer...",
  "miscellaneous.search": "Søk",
  "miscellaneous.search_by_name": "Søk etter navn",
  "miscellaneous.select_all": "Velg alle",
  "miscellaneous.selected_group": "Valgt gruppe",
  "miscellaneous.september": "September",
  "miscellaneous.set_specific_period": "Velg periode",
  "miscellaneous.share_feedback": "Del tilbakemeldinger",
  "miscellaneous.share_your_suggestion": "Del dine forslag",
  "miscellaneous.show_all": "Vis alle",
  "miscellaneous.status": "Status",
  "miscellaneous.sub_groups": "Undergrupper",
  "miscellaneous.this_month": "Denne måneden",
  "miscellaneous.this_week": "Denne uken",
  "miscellaneous.this_year": "Dette året",
  "miscellaneous.today": "I dag",
  "miscellaneous.total": "Totalt",
  "miscellaneous.total_net_amount": "Totalt nettobeløp",
  "miscellaneous.w": "u",
  "miscellaneous.we_couldnt_find_anyone": "Vi kunne ikke finne noen",
  "miscellaneous.year": "år",
  "miscellaneous.years": "år",
  "new_group.add": "Legg til gruppe",
  "new_group.add_in_group": "Legg til ny gruppe under",
  "new_group.communication_group_info": "Du trenger ikke lage egne grupper for trenere, lagledere og foreldrekontakter. Vi lager disse automatisk når noen får tildelt en av disse rollene.",
  "nif.active": "Aktiv",
  "nif.back_to_club_selection": "Tilbake til klubbvalg",
  "nif.cancel_club_membership": "Avslutt klubbmedlemskap",
  "nif.cancel_club_membership_description": "{{ first_name }} {{ last_name }} vil bli fjernet fra {{ group }} og alle undergrupper i Hoopit.",
  "nif.cancel_nif_club_membership": "Avslutt klubbmedlesskap i NIF",
  "nif.cancel_nif_sport_membership": "Avslutt grenmedlemskap i NIF",
  "nif.cancel_sport_membership": "Avslutt grenmedlemskap",
  "nif.cancel_sport_membership_description": "{{ first_name }} {{ last_name }} vil bli fjernet fra {{ group }} og alle undergrupper i Hoopit.",
  "nif.confirm_club_description": "Vennligst bekreft hvilke organisasjoner og grupper du er medlem i. Du vil motta betaling for medlemskontingent og treningsavgift for disse gruppene.",
  "nif.confirm_club_membership": "Opprett bruker ",
  "nif.delete_from_nif": "Slett fra NIF",
  "nif.delete_from_nif_and_hoopit": "Slett fra NIF og Hoopit",
  "nif.delete_from_nif_only": "Bare slett fra NIF",
  "nif.delete_options": "Du kan fjerne {{ name }} fra Hoopit i {{ organization }}, eller bare slette NIF medlemskapet.",
  "nif.delete_user_from_nif": "Slett {{ name }} fra NIF",
  "nif.delete_user_from_nif_info": "Du er i ferd med å slette NIF medlemskapet for",
  "nif_email_expiration_warning_description": "",
  "nif.i_didnt_get_the_email": "Jeg fikk ingen epost",
  "nif.i_have_verified": "Jeg har verifisert meg",
  "nif.initiate_description_one": "Ved å synkronisere direkte med NIF er vi trygge på at vi har oversikt over idrettslaget. Dette er grunnlaget for statlig støtte, og for en trivelig arbeidshverdag i klubben.",
  "nif.initiate_description_two": "Du verifiserer deg ved å fylle inn informasjonen som trengs, og deretter bekrefte verifiseringen hos NIF. Du vil også bli bedt om å verifisere informasjon om dine barn, dersom det ikke er gjort allerede.",
  "nif.initiate_heading": "Vi synkroniserer med Norges Idrettsforbund!",
  "nif.members": "NIF medlemmer",
  "nif.membership": "Medlemskap",
  "nif.min_2_characters": "Minimum 2 bokstaver",
  "nif.mobile_number_error": "Ugyldig mobilnummer",
  "nif.nationality": "Nasjonalitet",
  "nif.next": "Neste",
  "nif.nif_gender_info": "NIF tillater kun bruk av biologisk kjønn i sine registre.",
  "nif.nif_ssn_info": "Denne informasjonen lagres aldri hos oss. NIF verifiserer personnummeret for norske statsborgerskap direkte med Folkeregisteret.",
  "nif.nif_verified": "NIF-verifisert",
  "nif.not_a_member": "Ikke medlem",
  "nif.not_verified": "Ikke verifisert",
  "nif.ok_i_understand": "Ok, jeg skjønner!",
  "nif.one_more_important_step": "Ett viktig steg igjen!",
  "nif.pending": "Verifiserer",
  "nif.phone": "Telefonnummer",
  "nif.post_code": "Postkode",
  "nif.processing_information": "Behandler verifisering",
  "nif.search_by_name": "Søk",
  "nif.select_who_would_be_verified": "Velg hvem som skal bli verifisert",
  "nif.ssn_length_validation_message": "Personnummer må være 11 tall",
  "nif.status_member": "Medlem",
  "nif.status_pending": "Ubekreftet",
  "nif.status_unknown": "Ukjent",
  "nif.total_groups": "I {{ count }} grupper",
  "nif.users": "Brukere",
  "nif.verification_submission_description": "Du skal motta en SMS eller epost fra Norges Idrettsforbund der du bekrefter verifiseringen i Hoopit. Dersom du ikke bekrefter dette innen 24 timer, vil du bli nødt til å gjennomføre hele prosessen på nytt. I mellomtiden kan du bruke Hoopit som vanlig!",
  "nif.verified": "Verifisert",
  "nif.verify": "Verifiser",
  "nif.verify_form_description": "Her er informasjonen vi har om profilen. Det er viktig at du sjekker at informasjonen stemmer, og du blir nødt til å legge inn det som mangler.",
  "nif.verify_nif_membership": "Verifiser NIF-medlemskap",
  "nif.verify_user": "Verifiser bruker",
  "no_content.back_to_homepage": "Tilbake til hjemmesiden",
  "no_content.browser_not_supported": "Nettleseren støttes ikke, vennligst bruk Microsoft Edge, Google Chrome, Mozilla Firefox, eller Safari",
  "no_content.no_claims_found": "Ingen krav funnet",
  "no_content.no_invoices_found": "Ingen fakturaer funnet",
  "no_content.no_invoices_found_group": "Ingen fakturaer funnet i {{ group }}",
  "no_content.no_not_paid_payments": "Ingen utestående betalingsforespørsler",
  "no_content.no_orders_found": "Ingen bestillinger funnet",
  "no_content.no_orders_found_group": "Ingen bestillinger funnet i {{ group }}",
  "no_content.no_payments_found": "Fant ingen betalinger",
  "no_content.no_payments_found_group": "Fant ingen betalinger i {{ group }}",
  "no_content.no_payouts_found_group": "Fant ingen utbetalinger i {{ group }}",
  "no_content.no_users_found": "Ingen brukere funnet",
  "no_content.no_users_found_group": "Ingen brukere funnet i {{ group }}",
  "no_content.ooops": "Ooops!",
  "no_content.page_doesnt_exist": "Beklager, denne siden finnes ikke",
  "no_content.page_doesnt_exist_description": "Det virker som du prøver å nå en side som er slettet eller aldri har eksistert.",
  "onboarding.activate_child_user": "Be en foresatt om å aktivere kontoen din i appen, via <strong>Barn</strong> i innstillingene.",
  "onboarding.add_another_guardian_to": "Legg til en annen foresatt i",
  "onboarding.add_child_in": "Legg til dine barn i",
  "onboarding.add_child_in_other_group": "Dersom du skal legge til flere barn i et annet lag i",
  "onboarding.add_child_to_join_group": "For å kunne kobles opp mot gruppen må du legge til et barn.",
  "onboarding.add_guardian_hint_part_1": "Du kan koble en annen foresatt til barnet ved å følge",
  "onboarding.add_guardian_hint_part_2": "eller sende dem registreringslenken.",
  "onboarding.add_new_child_in": "Legg til nytt barn i",
  "onboarding.add_user": "Er <strong>{{ name }}</strong> barnet ditt så trykk på <strong>Legg til</strong> for å koble deg som foresatt.",
  "onboarding.already_a_member": "Du er allerede registrert i denne gruppen.",
  "onboarding.already_registered": "Bruker med dette mobilnummeret er allerede registrert. \nBruk følgende lenke for å logge inn.",
  "onboarding.choose_existing_users": "Hvis du er usikker på hvilken bruker du skal velge.",
  "onboarding.confirm_user_info": "Ønsker du å legge til <strong> {{ name }} </strong> som en ny bruker? Dobbeltsjekk at navn og fødselsdato stemmer \uD83E\uDD13",
  "onboarding.contact_us": "Kontakt oss i chatten nede til høyre",
  "onboarding.create_new_user": "Opprett ny bruker for {{ firstName }} {{ lastName }}",
  "onboarding.current_members_number": "Medlemmer {{ membersNumber }} av {{ allowedMembersNumber }}",
  "onboarding.enter_auth_phone_number": "Vennligst tast inn ditt mobilnummer",
  "onboarding.enter_code": "Skriv inn kode",
  "onboarding.enter_code_description": "Skriv inn bekreftelseskoden som er sendt til din mobile enhet.",
  "onboarding.enter_full_name": "Vennligst oppgi ditt fulle navn uten forkortelser",
  "onboarding.forgot_password": "Glemt passord? Trykk her.",
  "onboarding.have_user": "Har du allerede en bruker?",
  "onboarding.join_group": "Bli med i {{ groupName }}",
  "onboarding.max_members_number_text": "Dessverre har denne gruppen allerede nådd maksimalgrensen for brukere ({{ allowedMembersNumber }})",
  "onboarding.member": "Utøver",
  "onboarding.multiple_users_found": "Det er finnes flere brukere for {{ name }}",
  "onboarding.no_children_in_group_in": "Du har ingen barn registrert i",
  "onboarding.not_correct_user": "Er det ikke {{ name }} du skal legge til?",
  "onboarding.one_user_found": "{{ name }} er allerede registrert i Hoopit",
  "onboarding.our_instructions": "disse instruksjonene",
  "onboarding.parent": "Foresatt",
  "onboarding.phone_number_exists": "Dette telefonnummeret er allerede registrert i vårt system. Prøv å logg inn, eller tilbakestill passordet ditt.",
  "onboarding.read_on_support": "Du kan også lese mer om aktivering på ",
  "onboarding.reason_for_this_info": "Er du en foresatt trenger vi din fødselsdato for sikker identifisering og muligheten for å identifisere deg senere om det vil bli nødvendig. Fødselsdatoen vil ikke være synlig for andre enn deg selv i Hoopit.",
  "onboarding.register_as_associate_member_description": "Støttemedlemmer er viktig for klubben og pengene går til en god sak. I tillegg telles støttemedlemmer i den økonomiske støtten vi får fra Norges Idrettsforbund.",
  "onboarding.register_as_associate_member_title": "Ønsker du å registrere deg som støttemedlem?",
  "onboarding.register_as_member": "Registrer som utøver",
  "onboarding.register_as_member_description": "Utøver skal være med på aktivitetene som arrangeres av denne gruppen.",
  "onboarding.register_as_member_title": "Vil du registrerer deg som utøver?",
  "onboarding.register_as_parent": "Registrer som foresatt",
  "onboarding.register_as_parent_info": "Dersom du skal registrere barnet ditt, må du først registere deg som foresatt.",
  "onboarding.register_in_other_group": "Dersom du skal legge deg til i et annet lag i",
  "onboarding.register_new_user": "Registrer ny bruker",
  "onboarding.select_role": "Hva er din rolle i {{ groupName }}?",
  "onboarding.skip_adding_child": "Du har valgt å bli registrert som en foresatt. \nHvis du ikke legger til noen barn, vil du ikke bli koblet opp mot gruppen.",
  "onboarding.to_registration": "Til Klubbregistrering",
  "onboarding.user_should_exists": "Hvis du vet eller tror at <strong> {{ name }} </strong> allerede er registrert kan du kontakte oss, høre med lagleder eller kontakte andre foresatte for <strong>{{ name }}</strong>.",
  "onboarding.welcome_to": "Velkommen til",
  "onboarding.why_we_need_birthday": "Hvorfor trenger vi fødselsdato?",
  "onboarding.your_children": "Dine registrerte barn",
  "onboarding.your_children_in": "Dine barn som er medlem i",
  "onboarding.your_children_in_waiting_list": "Ditt barn er i venteliste for",
  "orders.bought_out": "Frikjøp",
  "orders.confirmed": "Bekreftet",
  "orders.edit_dugnad_order_description_1_1": "{{ name }} må bestille minst {{ number }} enheter totalt.",
  "orders.edit_dugnad_order_description_1_2": "Medlemmet kan selv velge antallet av hvert tilgjengelig produkt frem til bestillingsfristen.",
  "orders.edit_dugnad_order_description_2_1": "{{ name }} kan bestille maksimalt {{ number }} enheter totalt.",
  "orders.edit_dugnad_order_description_2_2": "Medlemmet kan selv velge antallet av hvert tilgjengelig produkt frem til bestillingsfristen.",
  "orders.edit_dugnad_order_description_3_1": "{{ name }} må kjøpe minst {{ number }} enheter totalt.",
  "orders.edit_dugnad_order_description_3_2": "Medlemmet kan selv velge antallet av hvert tilgjengelig produkt.",
  "orders.edit_dugnad_order_description_4_1": "{{ name }} må kjøpe maks {{ number }} enheter totalt.",
  "orders.edit_dugnad_order_description_4_2": "Medlemmet kan selv velge antallet av hvert tilgjengelig produkt.",
  "orders.exempted": "Fritatt",
  "orders.items": "Enheter",
  "orders.not_confirmed": "Ikke bekreftet",
  "orders.number_of_items_for": "Antall enheter",
  "organization.add_admin": "Legg til leder",
  "organization.add_guardian": "Legg til foresatt",
  "organization.add_members": "Legg til medlemmer",
  "organization.add_members_to_another_group": "Legg til {{ number }} medlemmer i en annen gruppe?",
  "organization.add_member_to_another_group": "Legg til {{ name }} i en annen gruppe?",
  "organization.add_player": "Legg til utøver",
  "organization.add_to_another_group": "Legg til i en annen gruppe",
  "organization.admin_is_being_deleted": "{{ firstName }} {{ lastName }} blir slettet som leder, dette kan ta litt tid",
  "organization.admins_are_being_deleted": "{{ adminsCount }} ledere blir slettet, dette kan ta litt tid",
  "organization.admins_that_will_be_deleted": "Ledere som vil bli slettet:",
  "organization.all_posts": "Alle innlegg",
  "organization.attendance": "Oppmøte",
  "organization.attendance_statistics": "Oppmøtestatistikk",
  "organization.chat": "Chat",
  "organization.contact_info": "Kontaktinfo",
  "organization.delete_admin": "Slett leder",
  "organization.delete_admins": "Slett ledere",
  "organization.delete_admins_description": "Er du sikker på at du vil slette <b>{{ adminsCount }}</b> ledere fra <b>{{ group }}</b>?",
  "organization.delete_from_current_group": "Slett fra nåværende gruppe",
  "organization.delete_member": "Slett medlem",
  "organization.delete_member_description_1": "Er du sikker på at du vil slette <b>{{ firstName }} {{ lastName }}</b> fra <b>{{ group }}</b>?",
  "organization.delete_member_description_2": "De vil fortsatt være registrert i systemet og kan legges til igjen senere",
  "organization.delete_player": " Slett utøver",
  "organization.delete_players": "Slett utøvere",
  "organization.delete_players_description": "Er du sikker på at du vil slette <b>{{ playersCount }}</b> utøvere fra <b>{{ group }}</b>?",
  "organization.delete_post": "Vil du slette innlegget?",
  "organization.delete_post_confirmation": "Ved å fortsette vil innlegget, samt alle medfølgende kommentarer bli slettet.",
  "organization.filter": "Filter",
  "organization.filters": "Filtre",
  "organization.in_this_group": "i denne gruppen.",
  "organization.invite_members": "Inviter medlemmer",
  "organization.invite_members_to_another_group": "Inviter {{ number }} medlemmer til en annen gruppe?",
  "organization.invite_member_to_another_group": "Inviter {{ name }} medlemmer til en annen gruppe?",
  "organization.invite_to_another_group": "Inviter til en annen gruppe",
  "organization.invite_to_relevant_groups": "Legg til disse i nye grupper før du sletter de fra <b>{{ group }}</b>.",
  "organization.media_permission_hint": "\"Samtykke internt\" - samtykke til å dele innlegg internt i Hoopit;  \"Samtykke eksternt\" - samtykke til å dele både internt i Hoopit og offentlig.",
  "organization.member_add_success": "{{ name }} blir lagt til i {{ group }}, dette kan ta litt tid.",
  "organization.member_invite_success": "{{ name }} blir invitert til {{ group }}, dette kan ta litt tid",
  "organization.member_move_success": "{{ name }} blir flyttet til {{ group }}, dette kan ta litt tid",
  "organization.member_moving_success_message": "{{ userName }} blir flyttet til {{ groupName }}, dette kan ta litt tid.",
  "organization.members_add_success": "{{ number }} medlemmer blir lagt til i {{ group }}, dette kan ta litt tid.",
  "organization.members_deleting_success_message": "{{ membersCount }} medlemmer blir slettet fra {{ groupName }}, dette kan ta litt tid.",
  "organization.members_invite_success": "{{ number }} medlemmer blir invitert til {{ group }}, dette kan ta litt tid",
  "organization.members_moved": "Medlemmer som vil bli flyttet:",
  "organization.members_move_success": "{{ number }} medlemmer blir flyttet til {{ group }}, dette kan ta litt tid",
  "organization.members_moving_success_message": "{{ membersCount }} medlemmer blir flyttet til {{ groupName }}, dette kan ta litt tid.",
  "organization.members_to_add": "Medlemmer som legges til",
  "organization.members_to_invite": "Medlemmer som inviteres",
  "organization.members_to_move": "Medlemmer som flyttes",
  "organization.min_characters_message": "Minimum {{ minCharRequirement }} bokstaver",
  "organization.move_members": "Flytt medlemmer",
  "organization.move_members_to_another_group": "Vil du flytte {{ number }} medlemmer til en annen gruppe?",
  "organization.move_member_to_another_group": "Vil du flytte {{ name }} til en annen gruppe?",
  "organization.move_to_another_group": "Flytt til en annen gruppe",
  "organization.no_group_selected": "Ingen gruppe valgt",
  "organization.no_permission": "Ingen tillatelse",
  "organization.not_set": "Ikke angitt",
  "organization.no_users": "Ingen brukere funnet",
  "organization.oops_it_seems_that_you_have": "Oops! Ser ut som du har",
  "organization.permits_internally": "Deling internt",
  "organization.permits_publicly": "Deling eksternt",
  "organization.player_is_being_deleted": "{{ firstName }} {{ lastName }} blir slettet som utøver, dette kan ta litt tid",
  "organization.players_are_being_deleted": "{{ playersCount }} utøvere blir slettet, dette kan ta litt tid",
  "organization.players_that_will_be_deleted": "Utøvere som vil bli slettet:",
  "organization.posts": "Innlegg",
  "organization_registration.add_club_account": "Legg til klubbkonto",
  "organization_registration.add_club_account_description": "Vennligst legg til klubbkonto slik at dere kan håndtere betalinger.",
  "organization_registration.bank_account_added": "Klubbkonto er lagt til!",
  "organization_registration.begin_stripe_integration": "Stripe Connect integrasjon",
  "organization_registration.begin_stripe_integration_description": "Hoopit bruker Stripe til å håndtere betalinger på en sikker måte. Neste steg er å legge til klubbkonto slik at dere kan håndtere betalinger i idrettslaget.",
  "organization_registration.check": "Sjekk",
  "organization_registration.club_registration": "Klubbregistrering",
  "organization_registration.confirm_personal_information": "Bekreft personopplysninger",
  "organization_registration.go_to_stripe": "Gå til Stripe Registrering",
  "organization_registration.invalid_account_number": "Kontonummeret oppgitt er ikke gyldig",
  "organization_registration.log_in": "Logg inn",
  "organization_registration.next": "Neste",
  "organization_registration.payments_enabled": "Betalingsfunksjonalitet er aktivert!",
  "organization_registration.payments_enabled_message_one": "Verifisering for Stripe Connect for kontonummer <strong> **** ** *{{account_numbers_last_four}} </strong> er godkjent",
  "organization_registration.payments_enabled_message_two": "Neste steg er å sette opp særidretter og invitere lederne. Disse vil deretter få ansvar for å sette opp sine respektive avdelinger.",
  "organization_registration.personal_information_description_one": "For å opprette et idrettslag er det et krav om at personopplysningene du har oppgitt er dine egne.",
  "organization_registration.personal_information_description_two": "Du blir registrert som kontoinnehaver hos vår betalingsformidler",
  "organization_registration.privacy_policy": "personvernerklæringen",
  "organization_registration.proceed_to_stripe_to_verify_account": "Stripe krever litt informasjon før behandling av betalinger er tillatt",
  "organization_registration.register_description_part_one": "For å få i gang idrettslaget i Hoopit må du som styreleder først registrere en bruker.",
  "organization_registration.register_description_part_two": "Om du har en bruker fra før kan du logge inn, og opprette idrettslaget med denne.",
  "organization_registration.register_personal_information": "Registrer personinformasjon",
  "organization_registration.register_user_heading": "Fyll inn din brukerinformasjon som styreleder.",
  "organization_registration.register_user_information": "Registrer brukerinformasjon",
  "organization_registration.set_up_divisions": "Sett opp særidretter",
  "organization_registration.sign_in_with_existing_user": "Logg inn med din eksisterende bruker.",
  "organization_registration.skip_divisions_descriptions": "Det vil ikke lenger være mulig å legge til særidretter gjennom denne linken. Du kan gjøre dette i ettertid på admin.hoopit.io.",
  "organization_registration.skip_invitations": "Ikke sende ut invitasjoner",
  "organization_registration.step_count": "Steg {{number}} av {{total}}",
  "organization_registration.stripe_error_description": "Om du ser denne siden, har en av følgende skjedd: \n - All påkrevd informasjon er ikke blitt fylt inn hos Stripe. \n - Lenken er utløpt. \n - En teknisk feil oppsto. \n Vennligst forsøk igjen.",
  "organization_registration.terms_of_use": "vilkår for bruk",
  "organization_registration.verification_incomplete": "Verifiseringen er nesten ferdig",
  "organization_registration.verification_incomplete_decription": "Klikk på “neste” for å fullføre registreringen med å laste opp ID og bekrefte kontoen din",
  "organization_registration.verification_not_complete": "Verifisering er ikke fullført",
  "organization.search_by_name": "Navnesøk",
  "organization.select_group": "Velg en gruppe fra menyen til venstre.",
  "organization.select_group_for_addition": "Velg gruppen medlemmene skal legges til i.",
  "organization.select_group_for_invitation": "Vennligst velg hvilken gruppe medlemmene skal inviteres til.",
  "organization.select_group_for_move": "Vennligst velg hvilken gruppe medlemmene skal flyttes til.",
  "organization.select_group_warning": "Legg merke til at de ikke vil være oppført med draktnummer i den nye gruppen.",
  "organization.select_member_group": "Velg gruppen du vil flytte medlemmet til.<br/>Spilleren vil ikke ha et draktnummer i den nye gruppen.",
  "organization.select_members_group": "Velg gruppen du vil flytte medlemmene til.<br/>Spillere vil ikke ha et draktnummer i den nye gruppen.",
  "organization_selector.companies": "Bedrifter",
  "organization_selector.select_organization": "Velg organisasjon",
  "organization.settings": "Innstillinger",
  "organization.this_group_has_x_players": "Denne gruppen har {{ playersCount }} / {{ allowedPlayersCount }} utøvere",
  "organization.title": "Tittel",
  "organization.total_admin": "leder i {{ group_name }}",
  "organization.total_admins": "ledere i {{ group_name }}",
  "organization.total_guardian": "foresatt i {{ group_name }}",
  "organization.total_guardians": "foresatte i {{ group_name }}",
  "organization.total_player": "utøver i {{ group_name }}",
  "organization.total_players": "utøvere i {{ group_name }}",
  "organization.user_deleted_from_group": "{{ userName }} er slettet fra {{ groupName }}.",
  "organization.yes_move": "Ja, flytt",
  "organization.your_posts": "Dine innlegg",
  "password.expired": "Linken er utløpt, vennligst tilbakestill passord på nytt.",
  "password.forgot": "Glemt passord",
  "password.link_sent": "En forespørsel om å tilbakestille passordet ditt er nå sendt til din epost.",
  "password.new": "Gå til appen for å logge inn med ditt mobilnummer og nye passord.",
  "password.remembered": "Husket det",
  "password.reset": "Tilbakestill passord",
  "password.reset_request_info": "Skriv inn mobilnummeret under og få en tilbakestillingslink tilsendt til den registrerte eposten din.",
  "password.reset_request_sent": "Du vil om kort tid bli tilsendt en epost der du kan tilbakestille passordet ditt.",
  "password.reset_request_to_spam": "NB! Hvis du ikke får link innen et par minutter, så sjekk spam-mappa di. Det hender mailen havner der.",
  "password.update": "Oppdater passord",
  "password.update_success": "Passordet ditt er nå oppdatert!",
  "payment.": "Betaling",
  "payment.account": "Konto",
  "payment.all_payments": "Alle betalinger",
  "payment.automatic_reminder": "Automatisk",
  "payment.automatic_reminder_info": "Automatiske purringer er de Hoopit sender ut for deg. Disse sendes ut 2, 5 og 7 dager etter betalingsfristen har passert. Vi sender også ut en ny purring hver uke etter betalingsfristen har passert.",
  "payment.back_to_payment_details": "Tilbake til betalingsoversikt",
  "payment.count": "Antall",
  "payment.custom_price": "Egendefinert pris",
  "payment.decision_confirmation_description": "Betalingen vil bli oppdatert for alle grupper",
  "payment.decision_confirmation_title": "Vil du endre hele treningsavgiften?",
  "payment.declined": "Avslått",
  "payment.delete_modal_description": "{{ paymentName }} og alle relaterte betalingsforespørsler vil bli slettet. Dette kan ikke reverseres.",
  "payment.delete_modal_description_2": "Merk at denne betalingen brukes som en påkrevd betaling, og ved å slette den, vil nye spillere kunne bli med i disse gruppene uten å betale",
  "payment.delete_modal_title": "Slett betaling?",
  "payment_details.ongoing_payment": "Pågående betaling",
  "payment.due_date": "Betalingsfrist",
  "payment.edit_user_payment_for": "Endre betaling for",
  "payment.exempted": "Fritatt",
  "payment.exempted_payments": "Fritatt betalinger",
  "payment.here": "her",
  "payment.last_reminder": "Siste purring",
  "payment.last_reminder_date": "Siste purringsdato",
  "payment.left_user": "Utlogget bruker",
  "payment.manage_recipients": "Velg mottakere",
  "payment.manual_reminder": "Manuell",
  "payment.name": "Navn",
  "payment.new_economy": "nye økonomisidene",
  "payment.new_page_hint": "Du kan endre summen for denne betalingsforespørselen i",
  "payment.new_reminder": "Ny purring",
  "payment.new_reminder_info": "Den opprinnelige betalingsmeldingen følger uansett med.",
  "payment.new_reminder_into_to_all": "Du kan legge til en melding til de som ikke har betalt.",
  "payment.new_reminder_into_to_one": "Du kan legge til en melding til",
  "payment.new_reminder_to": "Ny purring til",
  "payment.no_payments_sent": "Ingen betalinger sendt ut enda",
  "payment.not_charged": "Utestående",
  "payment.not_paid": "Ikke betalt",
  "payment.ongoing_payments": "Aktive betalinger",
  "payment.on_hold": "Fryst",
  "payment.optional": "Valgfri",
  "payment.overdue": "Forfalt",
  "payment.paid": "Betalt",
  "payment.paid_manually": "Betalt manuelt",
  "payment.past_payments": "Tidligere betalinger",
  "payment.payment_created": "Betaling opprettet",
  "payment.payment_due_date": "Betalingsfrist",
  "payment.payment_publish_date": "Publiseringsdato for betaling",
  "payment.payment_updated": "Betaling oppdatert",
  "payment.payment_update_success": "Betaling for {{ name }} har blitt oppdatert",
  "payment.payment_was_successfully_created": "Betaling opprettet",
  "payment.payment_was_successfully_deleted": "{{ paymentName }} blir slettet. Dette kan ta litt tid.",
  "payment.payment_was_successfully_updated": "Betalingen ble oppdatert",
  "payment.project": "Prosjekt",
  "payment.publish": "Publiser",
  "payment.publish_date": "Publiseringsdato",
  "payment.publish_payment_modal_description": "Sett en ny publiseringsdato for {{ paymentName }}.",
  "payment.publish_payment_modal_description_2": "Merk at denne betalingen brukes som en påkrevd betaling, og ved å publisere den på nytt vil nye spillere få 15 minutter til å betale for å bli med i disse gruppene",
  "payment.publish_payment_modal_title": "Publiser betalingen?",
  "payment.put_on_hold": "Frys betalingen",
  "payment.put_on_hold_modal_description": "Status på {{ paymentName }} vil endres til “fryst” og brukere kan ikke betale i appen. Du kan publisere betalingen igjen når som helst.",
  "payment.put_on_hold_modal_description_2": "Merk at denne betalingen brukes som en påkrevd betaling, og ved å sette den på vent, vil ikke nye spillere kunne bli med i disse gruppene",
  "payment.put_on_hold_modal_title": "Vil du fryse betalingen?",
  "payment.quantity": "Antall",
  "payment.read_more_about_payments": "Les mer om betalinger i Hoopit",
  "payment.reminder_history": "Purringshistorikk",
  "payment.reminder_history_description": "Hoopit sender automatisk purringer 2, 5 og 7 dager etter forfall og deretter én purring i uka. Purringer sendes som e-post og push-notifikasjon i appen. Du kan også sende en purring manuelt.",
  "payment.reminder_history_for": "Purringshistorikk for",
  "payment.reminder_notification_info": "Purringer sendes som e-post og push-notifikasjon til alle som har appen.",
  "payment_report_list.no_payment_reports": "Ingen betalingsrapporter",
  "payment_report_list.payment_report": "Betalingsrapporter",
  "payment_report_list.payout": "Utbetaling",
  "payment_reports.account_number": "Kontonummer",
  "payment_reports.account_number_tokenized": "\nKontonummer: **** ** *",
  "payment_reports.arrival_date": "Forventes på konto",
  "payment_reports.bank_account_payments_description": "Utbetalinger til din bankkonto er merket «SAXO PAYMENTS A/S». Dette firmaet håndterer alle utbetalinger i systemet.",
  "payment_reports.date": "Dato",
  "payment_reports.error_occured": "Det har skjedd en feil i systemet, kan ikke hente bilagene for perioden",
  "payment_reports.group": "Gruppe",
  "payment_reports.hide_all_transactions": "Skjul alle transaksjoner",
  "payment_reports.name": "Navn",
  "payment_reports.net_to_account": "Netto til konto",
  "payment_reports.no_arrival_date": "Ikke utbetalt enda",
  "payment_reports.no_payment": "Ingen utbetaling for periode",
  "payment_reports.outstanding": "Utestående",
  "payment_reports.paid": "Betalt",
  "payment_reports.payment": "Betaling",
  "payment_reports.payment_description": "Utbetalinger til din bankkonto er merket «SAXO PAYMENTS A/S». \nDette firmaet håndterer alle utbetalinger i systemet.",
  "payment_reports.payment_report": "Betalingsrapport",
  "payment_reports.pays": "Betaler",
  "payment_reports.period": "Periode",
  "payment_reports.sum": "Sum",
  "payment_reports.sum_for": "Sum for ",
  "payment_reports.total_amount": "Total sum",
  "payment_reports.total_fee": "Total transaksjonsgebyr",
  "payment_reports.total_paid": "Sum utbetalt",
  "payment_reports.transaction_fee": "Transaksjons-gebyr",
  "payment_reports.transactions_for": "Transaksjoner for ",
  "payment_reports.transferred_to": "Overført til {{ account_name }}",
  "payment_reports.transferred_to_main_account": "Overført til Hovedkonto",
  "payment_reports.view_all_transactions": "Vis alle transaksjoner",
  "payment.send_reminder": "Send ny purring",
  "payment.send_reminders": "Send purringer",
  "event_details.send_reminders": "Send purringer",
  "payment.set_custom_price": "Velg egendefinert pris",
  "payment.sum": "Sum",
  "payment_table.outstanding": "Utestående",
  "payment_table.paid": "Betalt",
  "payment.tf_is_being_created": "Betalingen blir opprettet. Dette kan ta litt tid.",
  "payment.to_see_transaction_fee": "for å se presis transaksjonsavgift og kontrollere om den skal være inkludert i prisen.",
  "payment.total": "Totalt",
  "payment.total_payments": "Betalinger sendt",
  "payment.total_sum": "Totalsum",
  "payment.type": "Type",
  "payment.upcoming_payments": "Kommende betalinger",
  "payment.use": "Bruk de",
  "payment.user_payment_price_categories_description": "Dette er den tilgjengelige priskategorien for {{ firstName }} {{ lastName }}. Du kan også angi et egendefinert beløp i stedet.",
  "payment.user_payment_price_categories_description_2": "Dette er de tilgjengelige priskategoriene for {{ firstName }} {{ lastName }}. Du kan også angi et egendefinert beløp i stedet.",
  "payouts.arrival_date": "Utbetalingsdato",
  "payouts.by_arrival_date": "Etter utbetalingsdato",
  "payouts.download": "Last ned rapport (PDF)",
  "payouts.failed": "Feilet",
  "payouts.in_progress": "Pågående",
  "payouts.insufficient_funds": "Manglende deking ",
  "payouts.insufficient_funds_information": "Vi vil starte utbetalingen så snart nettobeholdningen når 500 kroner, eller når det har gått en måned siden siste innbetaling",
  "payouts.in_transfer": "Overfører",
  "payouts.in_transfer_information": "Utbetaling er allerde i gang i Stripe.",
  "payouts.open": "Åpen",
  "payouts.paid_out": "Utbetalt",
  "payouts.payouts_label_hint": "Hver utbetaling inneholder alle betalinger og avgifter for den valgte tidsperioden. Du kan laste ned rapporter for fullførte utbetalinger.",
  "payouts.ready_for_transfer": "Klar for overføring",
  "payouts.ready_for_transfer_information": "Vi vil starte transaksjonen i Stripe så fort som mulig.",
  "payouts.status": "Status",
  "payouts.total_net_to_account": "Totalt utbetalt: {{ netAmount }}",
  "post_create.add_attachment": "Legg til vedlegg",
  "post_create.added_attachments": "Vedlegg lagt til",
  "post_create.added_photos": "Bilder lagt til",
  "post_create.add_images": "Legg til dine bilder her",
  "post_create.add_photos": "Legg til bilde",
  "post_create.add_post": "Skriv innlegget ditt her",
  "post_create.add_post_title": "Skriv en tittel for innlegget",
  "post_create.attachments": "Legg til vedlegg",
  "post_create.change": "Endre",
  "post_create.choose_different_group": "Velg en annen gruppe?",
  "post_create.delete_photo": "Vil du slette bildet?",
  "post_create.disable_comments": "Deaktiver og skjul kommentarer",
  "post_create.edit": "Rediger",
  "post_create.everyone": "Alle",
  "post_create.file_size_validation_message": "Filstørrelsen kan ikke være større enn {{ maxFileSize }}MB",
  "post_create.file_type_validation_message": "{{ type }}-filer er ikke tillatt. Tillatte filtyper: {{ allowedTypes }}",
  "post_create.for": "for ",
  "post_create.format_not_supported": "dette filformatet støttes ikke",
  "post_create.in": "i",
  "post_create.leaders_guardians": "Ledere og foresatte",
  "post_create.loading": "Laster...",
  "post_create.max_number_validation_message": "Maks antall vedlegg i et innlegg er {{ maxNumber }}",
  "post_create.new": "Nytt",
  "post_create.no_one_will_be_able_to_comment_on_this_post.": "Ingen kan kommentere på denne meldingen.",
  "post_create.not_enough_credits_hint": "Ikke nok kreditter. Ta kontakt med ledere i organisasjonen for å bruke denne funksjonen.",
  "post_create.notification_methods_description": "Som standard får brukere varsel i appen, men her kan du velge ekstra måter å sørge for at alle ser dette innlegget på.",
  "post_create.notification_methods_title": "Vil du legge til ytterligere varsler til medlemmer medlemmer? ",
  "post_create.notify_members_via_sms": "Varsle via SMS",
  "post_create.notify_members_via_sms_description": "Vi vil sende et SMS varsel til alle brukere som skal se innlegget.",
  "post_create.notify_via_email": "Varsle via e-post",
  "post_create.notify_via_email_description": "Vi sender en e-post til alle brukere som kan se innlegget. Vær oppmerksom på at selv om du ikke velger dette alternativet, vil brukere som ikke har mottar et varsler i appen fortsatt motta en e-post.",
  "post_create.photo": "Bilde",
  "post_create.post": "innlegg",
  "post_create.post_confirmation": "Hvem vil du skal se innlegget?",
  "post_create.post_images_limit": "Det er en grense på 8 bilder i hvert innlegg",
  "post_create.post_publish_question": "I hvilke grupper skal dette innlegget publiseres?",
  "post_create.post_save_confirmation_description": "Vil du varsle mottakerne om endringene i dette innlegget?",
  "post_create.post_save_confirmation_title": "Lagre og varsle medlemmer om endringer?",
  "post_create.select_specific": "Vil du publisere i spesifikke grupper?",
  "post_create.send_message_question": "Hvor vil du sende beskjeden?",
  "post_create.share_message": "Del en beskjed med gruppen, avdelingen eller klubben.",
  "post_create.this_group": "Bare denne gruppen",
  "post_create.this_group_subgroups": "Denne gruppen og alle undergrupper",
  "post_create.title": "Tittel",
  "post_create.upload_failed_message": "Opplasting feilet",
  "post_create.visible_to": "Synlig for",
  "post_detail.comment": "Kommentar",
  "post_detail.comments": "Kommentar",
  "post_detail.comments_are_disabled": "Kommentarer er avslått for dette innlegget av forfatteren.",
  "post_detail.delete_comment": "Vil du slette kommentaren?",
  "post_detail.delete_post": "Slett innlegg",
  "post_detail.edit_post": "Rediger innlegg",
  "post_detail.everyone_seen_message": "Alle har sett innlegget!",
  "post_detail.no_one_seen_message": "Ingen har sett innlegget ennå!",
  "post_detail.not_seen": "Ikke sett",
  "post_detail.post_image": "Bilde i innlegg",
  "post_detail.post_views_not_seen_message": "{{knownCount}} personer fra dine grupper har enda ikke sett innlegget, samt {{unknownCount}} fra andre grupper.",
  "post_detail.post_views_seen_message": "{{knownCount}} personer fra dine grupper har sett innlegget, samt {{unknownCount}} fra andre grupper.",
  "post_detail.seen": "Sett",
  "post_detail.seen_by_hint": "Bare brukere av Hoopit applikasjonen (app, web app eller admin dashbordet)",
  "post_detail.seen_status": "Sett av",
  "post_detail.subgroups": "undergrupper",
  "post_detail.visible_to_leaders": "Synlig for ledere",
  "post_detail.visible_to_leaders_guardians": "Synlig for ledere og foresatte",
  "post_detail.write_comments": "Skriv dine kommentarer her",
  "post_file.attachment_name": "Navn på vedlegg",
  "post_file.delete_attachment": "Slette dette vedlegget?",
  "post_file.file_with_similar_name": "Fil med likt navn",
  "post_file.file_with_similar_name_description": "Er du sikker på at dette er riktig fil? Du har allerede lastet opp en fil med dette navnet. Du kan gi filen et nytt navn etterpå.",
  "post_file.name_max_length_validation_message": "Navn på vedlegg kan ikke være lengre enn 251 tegn",
  "post_file.rename_attachment": "Endre navn",
  "posts.by_groups": "Etter grupper",
  "posts.create_post_text": "Vær den første! Opprett et innlegg ved å trykke på “Nytt innlegg” knappen",
  "posts.delete_post": "Vil du slette innlegget?",
  "posts.delete_post_confirmation": "Ved å fortsette vil innlegget, samt alle medfølgende kommentarer bli slettet.",
  "posts.filter": "Filter",
  "posts.filters": "Filtre",
  "posts.new_post": "Nytt innlegg",
  "posts.no_groups": "Ingen grupper funnet",
  "posts.no_posts": "Ingen innlegg lagt til",
  "posts.no_results": "Ingen resultater funnet",
  "posts.notified_by_sms": "Varslet på SMS",
  "posts.other_filters_text": "Prøv andre filtre",
  "posts.search_by_post_title": "Søk etter tittel",
  "posts.title_min_length_validation_message": "Tittelen må inneholde minst to tegn",
  "profile_picture.delete": "Slett",
  "profile_picture.delete_profile_picture": "Slett profilbilde",
  "profile_picture.delete_profile_picture_description": "Er du sikker på at du vil fjerne profilbildet for denne brukeren?",
  "profile_picture.edit": "Rediger",
  "profile_picture.profile_picture_title": "Profilbilde",
  "profile_picture.upload": "Last opp",
  "registered_members_list.club": "klubb-",
  "registered_members_list.connected_account": "Bankkonto",
  "registered_members_list.division": "Divisjon",
  "registered_members_list.export_excel": "Eksporter excel for hele klubben",
  "registered_members_list.group": "Gruppe",
  "registered_members_list.member_list": "medlemsliste-",
  "registered_members_list.members_registered": "Utøvere registrert",
  "registered_members_list.parents_registered": "Foresatte registrert",
  "registered_members_list.register": "Register",
  "registered_members_list.registered_invited": "Registrert/invitert",
  "registered_members_list.team_leader": "Lagleder",
  "registered_members_list.teams_overview_message": "Her ser du en oversikt over alle lagene i idrettslaget, hvor mange som har blitt invitert og hvor mange som har registret seg.",
  "registration_links.about_hoopit": "Om Hoopit",
  "registration_links.after_signup_message": "Når du er ferdig med registreringen laster du bare ned Hoopit appen fra App Store eller Google Play og logger deg inn. Der finner du alle aktivitetene dine \uD83D\uDE03",
  "registration_links.contact_hoopit": "Har du spørsmål eller trenger hjelp kan du også kontakte Hoopit i chatten nede til høyre.",
  "registration_links.get_started_hoopit": "Slik kommer du i gang med Hoopit",
  "registration_links.join_groups_welcome_message": "Her er alle gruppene i",
  "registration_links.join_groups_welcome_message_2": "du kan bli medlem av! For å bli  med i flere grupper må du tilbake til denne siden.",
  "registration_links.log_in": "Logg inn",
  "registration_links.log_out": "Logg ut",
  "registration_links.no_groups_to_register": "Det er ingen grupper du kan registrere deg i foreløpig!",
  "registration_links.registration_links_message": "Her vil du finne registreringslinker til alle grupper i særidrettene i",
  "registration_links.registration_links_message_2": "slik at du som foresatt eller utøver kan registrere deg. Gruppene dukker opp her fortløpende når de opprettes av ledelsen.",
  "registration_links.support": "Support",
  "registration_links.teams_groups_registration_description": "Nedenfor ser du alle gruppene i foreningen. Klikk på linken for din gruppe så kommer du til siden hvor du registrerer deg selv og dine barn. Har du flere barn i forskjellige grupper går du tilbake til denne siden og klikker på linken til riktig gruppe og registrerer barnet. Om du skal registrere flere barn i forskjellige grupper kan du logge direkte inn i disse gruppene etter å ha registrert deg den første gangen.",
  "sms.cost": "Kostnad",
  "sms.credit": "kreditt",
  "sms.credits": "kreditter",
  "sms.current_balance": "Nåværende saldo",
  "sms.economy_manager_role_is_required": "Rollen “Økonomiansvarlig” er påkrevd",
  "sms.not_enough_credits": "Ikke nok kreditter",
  "sms.not_enough_credits_description": "Det ser ut som de siste kredittene ble brukt opp for et øyeblikk siden. Ta kontakt med en leder på klubbnivå for påfyll av kreditter",
  "sms.not_enough_credits_hint": "Ikke nok kreditter. Ta kontakt med ledere i organisasjonen for å bruke denne funksjonen.",
  "sms.notify_via_sms": "Send via SMS",
  "sms.notify_via_sms_description": "Vi vil sende en SMS for å minne brukeren om denne betalingen.",
  "sms.price_description": "En standard SMS koster 1 kreditt.",
  "sms.publish_without_sms_notification": "Publiser uten SMS varsel",
  "sms.request_more": "Forespør flere",
  "sms.sender_name": "Avsendernavn",
  "sms.sender_name_description": "Dette vil være synlig for mottakere.",
  "sms.sender_name_description_2": "Vær oppmerksom på at første tegnet må være en bokstav. Resten kan være bokstaver, tall eller mellomrom.",
  "sms.sender_name_hint": "2-11 bokstaver og tall, hvor den første må være en bokstav",
  "sms.sender_name_warning": "Velg deres foretrukne avsendernavn",
  "sms.sender_name_warning_description": "Du kan bruke deres klubb sitt navn. På denne måten vil medlemmer motta SMS-varsler fra klubben.",
  "sms.sms_configuration_description": "Ledere i klubb og avdeling har mulighet til å sende en SMS-notifikasjon i tillegg til push notifikasjoner og e-poster. Vi jobber kontinuerlig med å legge til mulighet for SMS i flere av systemets funksjoner. Si gjerne i fra til support om hvor du ønsker å bruke SMS-notifikasjoner.",
  "sms.sms_package_purchases": "SMS abonnement",
  "sms.sms_package_purchases_description": "Kjøp SMS abonnement og pakker her for rask og effektiv kommunikasjon med medlemmene dine.",
  "sms.subscription_active": "Abonnement aktivt",
  "sms.subscription_unavailable": "Abonnement utilgjengelig",
  "stripe_onboarding.additional_info_required": "Stripe krever tilleggsinformasjon",
  "stripe_onboarding.additional_update_required": "Stripe krever noe tilleggsinformasjon så det er viktig at du oppdaterer.",
  "stripe_onboarding.contact_organization_message": "Stripe trenger noe mer informasjon. Ta kontakt med en representant i {{organization_name}} slik at du får inn all informasjonen du trenger",
  "stripe_onboarding.information_required": "Må fylles ut",
  "stripe_onboarding.update_information": "Oppdater informasjon",
  "stripe_onboarding.update_stripe_information": "Oppdater informasjonen Stripe trenger",
  "stripe_onboarding.verification_pending": "Verifisering hos Stripe er i gang",
  "table.account": "Konto",
  "table.action": "Hendelse",
  "table.age": "Alder",
  "table.age_group": "Aldersgruppe",
  "table.arrival_date": "Utbetalingsdato",
  "table.birth_date": "Fødselsdato",
  "table.claim_name": "Navn",
  "table.claim_status": "Status",
  "table.comments": "Kommentar",
  "table.confirmation_due_date": "Bestillingsfrist",
  "table.confirmation_status": "Bekreftelsesstatus",
  "table.created_by": "Opprettet av",
  "table.created_for": "Opprettet for",
  "table.customer_supplier": "Kunde/Leverandør",
  "table.date": "Dato",
  "table.date_of_birth": "Fødselsdato",
  "table.description": "Beskrivelse",
  "table.division": "Divisjon",
  "table.due_date": "Betalingsfrist",
  "table.dugnad_name": "Dugnadsnavn",
  "table.email": "E-post",
  "table.end": "Slutt",
  "table.end_date": "Sluttdato",
  "table.event_name": "Aktivitetsnavn",
  "table.expected_net_amount": "Forventet nettobeløp",
  "table.gender": "Kjønn",
  "table.generation_date": "Fakturadato",
  "table.gross_amount": "Bruttobeløp",
  "table.group": "Gruppe",
  "table.groups": "Grupper",
  "table.group_type": "Gruppetype",
  "table.has_certificate": "Levert",
  "table.hoopit_account": "Hoopit konto",
  "table.information": "Notat",
  "table.invoice_number": "Fakturanummer",
  "table.jersey_number": "Draktnummer",
  "table.joined": "Innmeldt",
  "table.joined_at": "Innmeldt",
  "table.last_confirmation": "Forrige forsøk på bekreftelse",
  "table.last_edited_by": "Sist endret av",
  "table.last_invitation": "Forrige invitasjon til Hoopit",
  "table.last_invoice": "Siste faktura",
  "table.last_nif_confirmation_reminder": "Siste påminnelse om registrering i NIF",
  "table.last_reminder": "Siste purring",
  "table.left": "Utmeldt",
  "table.location": "Bane",
  "table.luxsave_keyword": "LuxSave-nøkkelord",
  "table.media_permissions": "Bilde og video tillatelse",
  "table.member": "Medlem",
  "table.name": "Navn",
  "table.nationality": "Statsborgerskap",
  "table.net_amount": "Netto beløp",
  "table.net_to_account": "Netto til konto",
  "table.nif_confirmation_status": "Status i NIF",
  "table.nif_id": "NIF person Id",
  "table.nif_membership": "NIF medlemskap",
  "table.nif_status": "",
  "table.nif_status_details": "",
  "table.no_dugnads_found": "Ingen dugnader funnet",
  "table.no_dugnads_found_group": "Ingen dugnader funnet i {{ group }}",
  "table.no_members_yet": "Ingen medlemmer enda",
  "table.no_results": "Ingen resultater",
  "table.no_results_group": "Ingen resultater i {{ group }}",
  "table.notes": "Notat",
  "table.not_paid": "Ikke betalt",
  "table.number_of_items": "# enheter",
  "table.optional": "Valgfri",
  "table.order_progress": "Fremgang bestilling",
  "table.order_status": "Bestillingsstatus",
  "table.outstanding": "Utestående",
  "table.outstanding_payment": "Utestående betaling",
  "table.paid": "Betalt",
  "table.paid_membership_fee": "Betalt medlemskontingent",
  "table.paid_status": "Betalt",
  "table.paid_training_fee": "Betalt treningsavgift",
  "table.pay_from": "Betal fra",
  "table.payment_date": "Betalingsdato",
  "table.payment_due_date": "Betalingsfrist",
  "table.payment_name": "Navn på betaling",
  "table.payment_progress": "Fremgang betaling",
  "table.payment_status": "Betalingsstatus",
  "table.payout_status": "Status utbetaling",
  "table.pay_to": "Betal til",
  "table.period": "Periode",
  "table.period_end": "Periodeslutt",
  "table.period_start": "Periodestart",
  "table.person_id": "Person Id",
  "table.phone_number": "Mobilnummer",
  "table.price": "Pris",
  "table.price_category_name": "Priskategori",
  "table.processed_by": "Behandlet av",
  "table.processed_date": "Dato behandlet",
  "table.progress": "Fremgang",
  "table.publish_date": "Publiseringsdato",
  "table.push_notification": "Pushvarsel",
  "table.quantity": "Antall",
  "table.receiver": "Mottaker",
  "table.refunds": "Tilbakebetalinger",
  "table.reminder": "Purring",
  "table.reminder_type": "Påminnelsestype",
  "table.request_date": "Forespørselsdato",
  "table.request_status": "Forespørselsstatus",
  "table.requires_certificate": "Krever attest",
  "table.role": "Rolle",
  "table.sms": "SMS",
  "table.something_went_wrong": "Noe gikk galt",
  "table.start": "Start",
  "table.status": "Status",
  "table.sub_group": "Undergruppe",
  "table.sum": "Sum",
  "table.survey_progress": "Fremgang spørreundersøkelse",
  "table.tag_name": "Etikett-navn",
  "table.tasks": "Oppgaver",
  "table.title": "Tittel",
  "table.total_sum": "Totalsum",
  "table.total_to_reimburse": "Å tilbakebetale",
  "table.transaction_fee": "Transaksjonsavgift",
  "table.type": "Type",
  "table.updated_by": "Verifisert av",
  "table.venue_section": "Område",
  "table.verified": "Verifisert",
  "table.verified_by": "Verifisert av",
  "table.verified_by_nif": "Verifisert av NIF",
  "table.voucher": "Kravdokument",
  "table.voucher_number": "Bilagsnummer",
  "table.year": "År",
  "team_account.balance_at_end": "Saldo ved periodeslutt",
  "team_account.balance_at_start": "Saldo ved periodestart",
  "team_account.change": "Endring\n",
  "team_account.change_hint": "Endringen beregnes ved å trekke utgående transaksjoner fra innkommende transaksjoner for den valgte perioden.",
  "team_account.current_balance": "Nåværende saldo",
  "team_account.group_economy": "Gruppeøkonomi",
  "team_account.incoming": "Innbetalt",
  "team_account.invalid_group_type_description": "For å se saldoen, velg et <b>lag</b> som er integrert med <b>Catacloud</b>.",
  "team_account.no_permission_description": "Kun <b>økonomiansvarlig</b> av denne gruppen og de over har tilgang til denne siden.",
  "team_account.no_transactions_found": "Ingen transaksjoner funnet",
  "team_account.outgoing": "Utbetalt",
  "team_account.page_title": "Lagskasse",
  "team_account.preview_description": "Dette er en forhåndsvisning av funksjonen \"Lagskasse\". Vi importerer regnskapsdata fra Catacloud, og viser deg lagets tilgjengelige midler og transaksjoner, direkte i Hoopit!",
  "team_account.search_by_anything": "Søk etter hva som helst",
  "team_account.set_specific_period": "Velg periode",
  "terms.accept": "Godta",
  "terms.accept_to_continue": "For å fortsette, må du godta våre",
  "terms.and": "og",
  "terms.data_policy": "Personvernerklæring",
  "terms.privacy_policy_validation_message": "Må du godta våre Personvernerklæring",
  "terms.terms_of_service": "Vilkår for bruk",
  "terms.terms_validation_message": "Må du godta våre Vilkår for bruk",
  "toaster.404": "Vi fant ikke det du leter etter :(",
  "toaster.account_set": "Kontoen ble satt til",
  "toaster.activation_email_sent": "Aktiveringsmail er blitt sendt til bruker",
  "toaster.active": "aktiv",
  "toaster.admin_added": "Leder lagt til i gruppen",
  "toaster.already_registered": "er allerede registrert som foresatt for",
  "toaster.branch_membership_cancelled": "Grenmedlemskapet for {{ name }} har blitt kansellert, og brukeren er fjernet fra alle relaterte grupper i Hoopit.",
  "toaster.cannot_add_fix_id": "kan ikke legge til fiksID ",
  "toaster.cannot_remove_title": "Du har ikke tilgang til å fjerne tittelen i denne gruppa",
  "toaster.changes_were_saved": "Endringene ble lagret!",
  "toaster.club_membership_cancelled": "Klubbmedlemskapet for {{ name }} har blitt kansellert, og brukeren er fjernet fra alle relaterte grupper i Hoopit.",
  "toaster.compress_failed": "Kunne ikke komprimere bildet",
  "toaster.copied": "Kopiert!",
  "toaster.emails_dont_match": "",
  "toaster.event_reopened": "“{{event}}” ble gjenåpnet",
  "toaster.fetch_invited_failed": "Kunne ikke hente inviterte brukere",
  "toaster.fix_id_saved": "FiksID lagret",
  "toaster.format_not_supported": "Filformatet støttes ikke",
  "toaster.group_updated": "Gruppeinformasjon oppdatert",
  "toaster.has_title": "har allerede tittelen",
  "toaster.inactive": "inaktiv",
  "toaster.invalid_password": "Ugyldig passord",
  "toaster.is_provided": "er nå foresatt for",
  "toaster.location_added": "Bane lagt til",
  "toaster.location_deleted": "Bane slettet",
  "toaster.location_updated": "Bane oppdatert",
  "toaster.new_activation_email_sent": "Ny aktiveringsmail sendt til",
  "toaster.no_access_to_perform_action": "Du har ikke tilgang til å utføre denne handlingen.",
  "toaster.no_longer_admin": "{{ user }} er ikke lenger leder i {{ group }}",
  "toaster.no_longer_member": "{{ user }} er ikke lenger medlem av {{ group }}",
  "toaster.no_longer_player": "{{ user }} er ikke lenger utøver i {{ group }}",
  "toaster.not_valid_fix_id": "Ikke gylding fiksID",
  "toaster.player_added": "Utøver er lagt til i gruppen",
  "toaster.post_deleted": "Innlegget er slettet",
  "toaster.post_made": "Innlegget er publisert",
  "toaster.post_saved": "Innlegget er lagret",
  "toaster.post_updated": "Innlegget ble oppdatert.",
  "toaster.post_updated_notified": "Innlegget ble oppdatert med varsel til mottakerne.",
  "toaster.remind_unanswered_success_bulk": "",
  "toaster.remind_unanswered_success_single": "",
  "toaster.requested_nif_connection_bulk": "",
  "toaster.requested_nif_connection_single": "",
  "toaster.select_admin_title": "Velg admintittel",
  "toaster.sent_nif_confirmation_request": "",
  "toaster.something_went_wrong": "Noe gikk galt",
  "toaster.something_wrong": "Noe gikk galt",
  "toaster.something_wrong_compress_failed": "Noe gikk galt, kunne ikke komprimere bildet",
  "toaster.unable_to_send_nif_request": "",
  "toaster.user_saved": "Brukeren er lagret",
  "toaster.venues_retrieve_failure": "Klarte ikke å hente Baneflater",
  "toast.title_added_success": "{{ name }} er nå {{ title }} i {{ group }}",
  "toast.title_removed_success": "{{ name }} er ikke lengre {{ title }} i {{ group }}",
  "user_activation_status": "",
  "user_activation_status.active": "Aktiv",
  "user_activation_status.awaits_membership": "Venter på medlemskap",
  "user_activation_status.child_user": "Barnebruker",
  "user_activation_status.no_receives_email_updates": "Mottar ikke oppdateringer. Vennligst angi en e-post.",
  "user_activation_status.not_accepted_group_invitation": "Ikke akseptert gruppeinvitasjon",
  "user_activation_status.not_completed_registration": "Registreringen er ikke fullført",
  "user_activation_status.parents_notification": "Foresatte mottar oppdateringer, og kontrollerer barnets handlinger.",
  "user_activation_status.receives_app_updates": "Mottar oppdateringer i appen",
  "user_activation_status.receives_email_updates": "Mottar oppdateringer gjennom e-post",
  "user_activation_status.supervised": "Administrert av foresatte",
  "user_attributes.address": "Adresse",
  "user_attributes.age": "år",
  "user_attributes.birth_date": "Fødselsdato",
  "user_attributes.birth_day": "Dag",
  "user_attributes.birth_month": "Måned",
  "user_attributes.birth_year": "År",
  "user_attributes.birth_year_label": "Fødselsår",
  "user_attributes.city": "Sted",
  "user_attributes.email": "E-post",
  "user_attributes.first_name": "Fornavn",
  "user_attributes.gender": "Kjønn",
  "user_attributes.jersey_number": "Draktnummer",
  "user_attributes.last_name": "Etternavn",
  "user_attributes.last_online": "Sist innlogget",
  "user_attributes.name": "Navn",
  "user_attributes.nationality": "Statsborgerskap",
  "user_attributes.no_permission": "Ingen tillatelse",
  "user_attributes.no_permission_label": "Jeg samtykker ikke til deling av bilder og video",
  "user_attributes.number": "Nummer",
  "user_attributes.paid_membership_fee": "Betalt medlemsavgift",
  "user_attributes.paid_training_fee": "Betalt treningsavgift",
  "user_attributes.password": "Passord",
  "user_attributes.permission_internal_label": "Jeg samtykker til deling av bilder og video i Hoopit",
  "user_attributes.permission_internal_text": "Jeg samtykker til deling internt (i Hoopit)",
  "user_attributes.permission_public_label": "Jeg samtykker til deling av bilder og video offentlig",
  "user_attributes.permission_public_text": "Jeg samtykker til deling internt og offentlig (utenfor Hoopit)",
  "user_attributes.permissions_description": "Endringer vil kun påvirke fremtidige innlegg, eldre innlegg vil ikke påvirkes.",
  "user_attributes.permissions_text": "Vennligst angi tillatelser for deling av bilder og videoer av deg.",
  "user_attributes.permissions_text_2": "Vennligst angi tillatelser for deling av bilder og videoer av {{ name }}.",
  "user_attributes.phone_code": "Kode",
  "user_attributes.phone_number": "Mobilnummer",
  "user_attributes.photo_permission": "Tillatelse til å dele bilder",
  "user_attributes.postal_code": "Postnummer",
  "user_attributes.receives_notifications_via_email": "Mottar varslinger gjennom e-post",
  "user_attributes.social_security": "Personnummer",
  "user_attributes.street_address": "Gateadresse",
  "user_form_validation.duplicate_user": "Det ser ut til at du allerede har blitt registrert!",
  "user_form_validation.email": "Vennligst oppgi en gyldig epost adresse",
  "user_form_validation.error": "OBS!",
  "user_form_validation.name": "Fornavn er alle navn utenom det siste etternavnet.",
  "user_form_validation.password": "Passordet må være minst 6 tegn",
  "user_form_validation.phone_number": "Vennligst oppgi et gyldig mobilnummer",
  "user_profile.activate": "Aktiver",
  "user_profile.activation_message": "Når du har aktivert brukeren, sender vi dem en epost slik at de får opprettet et passord.<br/>De logger inn med mobilnummeret herifra.",
  "user_profile.add": "Legg til",
  "user_profile.add_existing_guardian": "Legg til eksisterende foresatt",
  "user_profile.add_existing_user": "Legg til eksisterende bruker",
  "user_profile.add_guardian": "Legg til foresatt",
  "user_profile.add_guardian_label": "Legg til foresatt med navn",
  "user_profile.additional_information": "Tilleggsinformasjon",
  "user_profile.address_not_provided": "Adresse ikke oppgitt",
  "user_profile.authorization_code_label": "Legg til foresatt med aktiveringskode",
  "user_profile.by_email": "med epost",
  "user_profile.children": "Barn",
  "user_profile.click_here": "klikke her",
  "user_profile.dob_not_provided": "Fødselsdato ikke oppgitt",
  "user_profile.edit_jersey_number": "Endre draktnummer",
  "user_profile.edit_profile": "Rediger Profil",
  "user_profile.email_not_provided": "Epost ikke oppgitt",
  "user_profile.enter_password_message": "Vennligst skriv inn passord for verifisering",
  "user_profile.fetching_code": "Henter kode...",
  "user_profile.gender_not_provided": "Kjønn ikke oppgitt",
  "user_profile.groups": "Grupper",
  "user_profile.guardian_in": "Foresatt i",
  "user_profile.guardian_of": "som foresatt for",
  "user_profile.guardians_code_message": "Foresatte kan legge denne koden inn i appens innstillinger.<br/>Da vil de settes som foresatte for {{ first_name }}.",
  "user_profile.in": "i",
  "user_profile.info": "Info",
  "user_profile.inquire_add_message": "Vil du legge til",
  "user_profile.invitation": "Invitasjon",
  "user_profile.invitation_sent": "Invitasjon sendt",
  "user_profile.main_information": "Hovedinformasjon",
  "user_profile.manage_roles_description": "Velg hvilke roller <b>{{ user }}</b> skal ha i <b>{{ group }}</b>.",
  "user_profile.memberships": "Medlemskap",
  "user_profile.memberships_admin_description": "Medlemmet har administrative tilganger til denne gruppen og alle undergrupper",
  "user_profile.memberships_empty_state_description": "Det ser ut til at {{userName}} ikke er medlem av noen grupper du har tilgang til.",
  "user_profile.memberships_empty_state_title": "Ingen medlemskap",
  "user_profile.memberships_manage_roles": "Administrer roller",
  "user_profile.memberships_player_description": "Medlemmet deltar aktivt i gruppeaktiviteter og betaler avgifter.",
  "user_profile.memberships_roles_article_button": "denne artikkelen",
  "user_profile.memberships_roles_counter": "+{{ x }} mer",
  "user_profile.memberships_roles_description": "Roller lar ledere administrere grupper mer effektivt. f.eks. «Økonomiansvarlig» gir lederen tilgang til betalinger. Du kan lære mer om Hoopit-roller i",
  "user_profile.mobile_number_not_provided": "Mobilnummer ikke oppgitt",
  "user_profile.no_guardians": "Ingen foresatte",
  "user_profile.no_guardians_description": "Det ser ut som ingen er koblet som foresatt for {{ name }}",
  "user_profile.no_results": "Ingen resultater for",
  "user_profile.no_results_found": "Ingen resultater funnet",
  "user_profile.no_user_by_email": "Vi fant ingen brukere med denne e-posten",
  "user_profile.no_user_by_name": "Vi fant ingen brukere med dette navnet",
  "user_profile.number_in_use_error": "Det finnes allerede en bruker som har dette  mobilnummeret. Vennligst prøv et annet nummer, eller slett denne brukeren og invitere med riktig nummer fra Grupper > Brukere.",
  "user_profile.or": "eller",
  "user_profile.payments_tab_description": "Her er en liste over alle utestående betalingsforespørsler for denne brukeren. For å se alle betalingsforespørsler kan du",
  "user_profile.payments_tab_description_1": "Her er en liste over alle utestående betalingsforespørsler for denne brukeren.",
  "user_profile.payments_tab_description_2": "For å se alle betalingsforespørsler kan du",
  "user_profile.permissions": "Permissions",
  "user_profile.phone_number_usage": "Valg av mobilnummer",
  "user_profile.phone_number_usage_description": "For at {{ name }} skal kunne fullføre registreringen og godta invitasjonen må de bruke mobilnummeret oppgitt her.",
  "user_profile.practicing_in": "Utøver i",
  "user_profile.profile": "Profil",
  "user_profile.relations": "Relasjoner",
  "user_profile.remind_via_email": "Påminn via epost",
  "user_profile.remove": "Fjern",
  "user_profile.remove_as_admin": "Fjern som leder",
  "user_profile.remove_as_player": "Fjern som utøver",
  "user_profile.remove_from_group_description": "Vil du fjerne <b>{{ user }}</b> fra <b>{{ group }}</b>?",
  "user_profile.remove_from_group_title": "Fjern fra gruppen?",
  "user_profile.remove_from_group.website_access_lost_description": "Dette er din eneste rolle som administrator i {{ organization }}. Hvis du fjerner den, vil du miste tilgangen til denne nettsiden.",
  "user_profile.remove_from_group.website_access_lost_title": "Du vil miste tilgang til denne nettsiden.",
  "user_profile.remove_title": "Fjern tittel",
  "user_profile.remove_title_description": "Er du sikker på du vil fjerne tittelen {{ title }} i {{ groupName }} for {{ userName }}?",
  "user_profile.resend_invitation_button": "Send invitasjonen på nytt",
  "user_profile.resend_invitation_by_sms_description": "{{ name }} vil motta invitasjonen på SMS til  <b>{{ number }}</b>, sendt av {{ sender }}. Hvis dette nummeret er feil, vennligst slett denne brukeren i \"Grupper\" > \"Brukere\" og inviter på nytt med riktig nummer.",
  "user_profile.resend_invitation_by_sms_title": "Inviter per SMS",
  "user_profile.resend_invitation_description": "Send en invitasjonslenke til {{ name }}, og minner om at for å bli med i gruppen og bli aktivt medlem av {{ organization }}, må de fullføre registreringen. ",
  "user_profile.resend_invitation_description_2": "Hvis du oppgir e-posten til {{ name }}, sender vi den også dit for enkel tilgang.",
  "user_profile.resend_invitation_email": "Send invitasjonen på nytt",
  "user_profile.resend_invitation_email_description": "Du kan sende invitasjonen på nytt til en bruker som ikke er registrert i Hoopit enda.",
  "user_profile.resend_invitation_reminder": "Forrige invitasjon ble sendt {{ datetime }}.",
  "user_profile.roles": "Roller",
  "user_profile.search_by_code_or_country": "Søk etter landskode eller land",
  "user_profile.search_by_email": "Søk etter e-post",
  "user_profile.search_by_name": "Navnesøk",
  "user_profile.send_new_activation_email": "Send ny aktiveringsmail",
  "user_profile.title": "Tittel",
  "user_profile.titles": "Titler",
  "user_profile.try_another_email": "Prøv en annen e-postadresse eller søk etter navn",
  "user_profile.try_another_name": "Prøv et annet navn eller søk etter e-post",
  "user_profile.unpaid_payments": "Ubetalte betalinger",
  "user_search_form.all_names": "Alle navn utenom det siste etternavnet",
  "user_search_form.back": "Tilbake",
  "user_search_form.born": "Født",
  "user_search_form.choose_title": "Velg en tittel",
  "user_search_form.next": "Neste",
  "user_search_form.phone_number_validation_message": "Vennligst oppgi et gyldig telefonnummer",
  "user_types.active_members": "Aktive medlemmer",
  "user_types.admin": "Leder",
  "user_types.admins": "Ledere",
  "user_types.associate_member": "Støttemedlem",
  "user_types.associate_members": "Støttemedlemmer",
  "user_types.external_user": "",
  "user_types.guardian": "Foresatt",
  "user_types.guardians": "Foresatte",
  "user_types.member": "Medlem",
  "user_types.members": "Medlemmer",
  "user_types.other": "Annet",
  "user_types.others": "Andre",
  "user_types.player": "Utøver",
  "user_types.player_and_admin": "Utøver og Leder",
  "user_types.players": "Utøvere",
  "waiting_list.1_user_in": "1 medlem i",
  "waiting_list.add_single_description": "<b>{{ name }}</b> vil bli lagt til i <b>{{ group }}</b>som utøver. Å legge til disse medlemmene vil øke utøverbegrensningen til ({{ limit }} utøvere).",
  "waiting_list.add_single_success": "{{ name }} blir lagt til i {{ name }}, dette kan ta litt tid.",
  "waiting_list.add_single_title": "Legg til i gruppen?",
  "waiting_list.add_to_group": "Legg til i gruppe",
  "waiting_list.add_warning_title": "Utøverbegrensningen vil bli overskredet",
  "waiting_list.add_x_description": "{{ count }} medlemmer vil bli lagt til i <b>{{ group }}</b> som utøvere. Å legge til disse medlemmene vil øke utøverbegrensningen til ({{ limit }} utøvere).",
  "waiting_list.add_x_success": "{{ count }} medlemmer blir lagt til i {{ group }}, dette kan ta litt tid.",
  "waiting_list.add_x_title": "Legg til {{ count }} medlemmer til gruppen?",
  "waiting_list.add_x_warning_description": "Aktivere dette valget vil deaktivere \"Medlemsforespørsler\" og flytte {{ count }} brukere som venter på godkjennelse til denne ventelisten.",
  "waiting_list.create": "Opprett venteliste",
  "waiting_list.create_description": "For å aktivere venteliste for <b>{{ group }}</b> må du sette en grense for antall utøvere og skru på ventelisten.",
  "waiting_list.create_warning_description": "Brukere som venter på godkjennelse vil bli flyttet hit.",
  "waiting_list.delete_description": "{{ count }} medlemmer vil bli fjernet fra ventelisten til <b>{{ group }}</b>. De vil fremdeles være registrert i systemet og kan bli medlem av ventelisten på nytt.",
  "waiting_list.delete_from_waiting_list": "Fjern fra ventelisten.",
  "waiting_list.delete_single_description": "<b>{{ name }}</b> vil bli fjernet fra ventelisten til <b>{{ group }}</b>. Medlemmet vil fremdeles være registrert i systemet og kan bli medlem av ventelisten på nytt.",
  "waiting_list.delete_single_success": "{{ userName }} blir fjernet fra ventelisten, dette kan ta litt tid.",
  "waiting_list.delete_single_title": "Fjern fra ventelisten?",
  "waiting_list.delete_x_success": "{{count}} medlemmer blir fjernet fra ventelisten, dette kan ta litt tid.",
  "waiting_list.delete_x_title": "Fjern {{count}} medlemmer fra ventelisten?",
  "waiting_list.disabled": "Ingen venteliste",
  "waiting_list.enable_description": "Når utøvergrensen er nådd vil alle nye brukere som melder seg inn bli satt på venteliste",
  "waiting_list.hint": "Når antallet utøvere i gruppen når satt grense vil nye utøvere bli lagt til i ventelisten. Når det blir deres tur, blir de automatisk lagt til i gruppen og fjernet fra ventelisten.",
  "waiting_list.limit_members_description": "Nåværende medlemmer i gruppen vil ikke bli berørt",
  "waiting_list.n_users_in": "{{ number }} medlemmer i",
  "waiting_list.send_mandatory_payment_title": "Send påkrevd betaling",
  "welcome_message.get_started": "Kom i gang ved å laste ned appen vår fra App Store eller Google Play.",
  "welcome_message.go_to_dashboard": "Gå til dashbordet",
  "welcome_message.hoopit": "Velkommen til Hoopit!",
  "welcome_message.hoopit_main_adress": "www.hoopit.io",
  "welcome_message.instruction_for_mobile": "Du er invitert som leder og kan administrere både fra mobil app og web. \n Ønsker du å bruke web passer det best fra PC og du kan logge inn fra adressen ",
  "welcome_message.leader": "Dersom du er en leder, så kan du administrere laget ditt i webdashboardet.",
  "welcome_message.stay_updated_and_logg_in": "Hold deg oppdatert med hva som skjer i klubben og laget. Last ned appen og logg inn med mobilnummer og passord. ",
  "welcome_message.use_webapp": "Du kan også bruke webapp om du ikke ønser å laste ned appen til mobilen din.",
  "welcome_message.you_are_in_waiting_line": "Du er nå på venteliste for å bli med i {{ groupName }}. Vi vil varsle deg ved oppdateringer."
}