import { Component, Input } from '@angular/core'
import moment from 'moment'

import { UserProfileComponent } from '../user-profile.component'
import { Gender } from '../../../../core/enums/user/gender'
import { DateFormat } from '../../../../core/enums/global/date-format'
import { PhoneNumberInput } from '../../phone-number/interfaces/phone-number-input'

@Component({
  selector: 'app-edit-profile',
  templateUrl: 'edit-profile.component.html',
  styleUrl: 'edit-profile.component.scss'
})
export class EditProfileComponent {
  @Input({ required: true }) hostComponent: UserProfileComponent

  today: string = moment().format(DateFormat.YYYYMMDD)

  get Gender() {
    return Gender
  }

  handlePhoneNumberUpdate({ phoneCode, phoneNumber, isValid }: PhoneNumberInput): void {
    this.hostComponent.userProfileForm.controls.phoneCountryCode.patchValue(phoneCode)
    this.hostComponent.userProfileForm.controls.phoneMobile.patchValue(phoneNumber)
    this.hostComponent.userProfileForm.controls.phoneMobile.setErrors(isValid ? null : { invalidPhoneNumber: true })
  }
}
